import { defineStore } from 'pinia';
import router from '../router';
import HomeIcon from "../assets/icons/menu/home.svg";
import commitmentsIcon from "../assets/icons/menu/Date.svg";
import yourHealthIcon from "../assets/icons/menu/health.svg";
import favoritesIcon from "../assets/icons/menu/fav.svg";
import walletIcon from "../assets/icons/menu/money.svg";
import dependentsIcon from "../assets/icons/menu/profile.svg";
import universityIcon from "../assets/icons/university.svg";

function obterPrimeiroPath(caminhoCompleto) {
  const partes = caminhoCompleto.split('/');
  return `/${partes[1]}`;
};

export const useMenu = defineStore('menu', {
    state: () => {
        return {
            pathName: window.location.pathname,
            firstPath: obterPrimeiroPath(window.location.pathname),
            items: [
                {
                  label: "Início",
                  icon: HomeIcon,
                  active: HomeIcon,
                  to: "/",
                },
                {
                  label: "Compromissos",
                  icon: commitmentsIcon,
                  to: "/commitments",
                  items: [
                    {
                      label: "Futuros",
                      to: "/commitments/futures",
                    },
                    {
                      label: "Passados",
                      to: "/commitments/previous",
                    },
                  ]
                },
                {
                  label: "Minha saúde",
                  icon: yourHealthIcon,
                  to: "",
                },
                {
                  label: "Favoritos",
                  icon: favoritesIcon,
                  to: "/favorites",
                },
                {
                  label: "Carteira",
                  icon: walletIcon,
                  to: "/wallet"
                },

                {
                  label: "Conexões",
                  icon: dependentsIcon,
                  to: "/connections",
                  items: [
                    {
                      label: "Dependentes",
                      to: "/connections/dependents",
                    },
                    {
                      label: "Vinculados",
                      to: "/connections/linked",
                    },
                    {
                      label: "Permissões",
                      to: "/connections/permissions",
                    },
                  ]
                },

                {
                  label: "University",
                  icon: universityIcon,
                  to: ""
                }
              ]
        }
    },

    getters: {
    },
    actions: {
      changeRoute(path) {
        const itemFirstPath = this.items.find(el => el.to === obterPrimeiroPath(path))
        const itemPath = this.items.find(el => el.to === path)
        const itemFirstPathLenght = itemFirstPath?.items?.length ?? 0 
        const accessSubMenu = itemFirstPath && !itemPath

        if ((!accessSubMenu && itemFirstPathLenght === 0) || accessSubMenu ) {
          router.push(path);
          this.pathName = path
          this.firstPath = obterPrimeiroPath(path)
        }
      },

      logout(path) {
        this.pathName = "/"
        router.push("/account/login");
      }
    }
});