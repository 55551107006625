import { VerifyToken } from "./actions";

// SCREEN 1
const AccountRegister = async (to, from, next) => {
    window.localStorage.clear();
    next();
}

// SCREEN 2
const AccountPhoneConfirmation = async (to, from, next) => {
    const verifyToken = await VerifyToken();

    if(verifyToken.success){
        const data = JSON.parse(verifyToken.data);

        if('screen' in data){
            const { screen } = data;

            if(screen === 2){
                next();
            }else if ( screen === 3 ){
                next({ name: 'Account.Infos' });
            }else if (screen === 4) {
                next({ name: 'Account.Address' });
            }else if (screen === 5){
                next({ name: 'Account.UploadDocument' });
            }
        } else if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next({ name: 'Account.Login' })
            }else{
                next({ name: 'Main'});
            }
        }
    }else{
        next({ name: 'Account' })
    }
}

// SCREEN 3
const AccountInfos = async (to, from, next) => {
    const verifyToken = await VerifyToken();

    if(verifyToken.success){
        const data = JSON.parse(verifyToken.data);

        if('screen' in data){
            const { screen } = data;

            if(screen === 2){
                next({ name: 'Account.PhoneConfirmation' });
            }else if ( screen === 3 ){
                next();
            }else if (screen === 4) {
                next({ name: 'Account.Address' });
            }else if (screen === 5){
                next({ name: 'Account.UploadDocument' });
            }
        } else if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next({ name: 'Account.Login' })
            }else{
                next({ name: 'Main'});
            }
        }
        
    }else{
        next({ name: 'Account' })
    }
}

// SCREEN 4
const AccountAddress = async (to, from, next) => {
    const verifyToken = await VerifyToken();

    if(verifyToken.success){
        const data = JSON.parse(verifyToken.data);

        if('screen' in data){
            const { screen } = data;

            if(screen === 2){
                next({ name: 'Account.PhoneConfirmation' });
            }else if ( screen === 3 ){
                next({ name: 'Account.Infos' });
            }else if (screen === 4) {
                next();
            }else if (screen === 5){
                next({ name: 'Account.UploadDocument' });
            }
        } else if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next({ name: 'Account.Login' })
            }else{
                next({ name: 'Main'});
            }
        }
    }else{
        next({ name: 'Account' })
    }
}

// SCREEN 5
const AccountUpload = async (to, from, next) => {
    const verifyToken = await VerifyToken();
    
    if(verifyToken.success){
        const data = JSON.parse(verifyToken.data);

        if('screen' in data){
            const { screen } = data;

            if(screen === 2){
                next({ name: 'Account.PhoneConfirmation' });
            }else if ( screen === 3 ){
                next({ name: 'Account.Infos' });
            }else if (screen === 4) {
                next({ name: 'Account.Address' });
            }else if (screen === 5){
                next();
            }
        } else if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next({ name: 'Account.Login' })
            }else{
                next({ name: 'Main'});
            }
        }

    }else{
        next({ name: 'Account' })
    }
}

export default {
    AccountPhoneConfirmation,
    AccountInfos,
    AccountAddress,
    AccountUpload,
    AccountRegister,
}