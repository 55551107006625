/**
 * @fileoverview gRPC-Web generated client stub for patientAccountApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.1
// source: patientAccount.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.patientAccountApi = require('./patientAccount_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.patientAccountApi.PatientAccountProtoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.RegisterRequest,
 *   !proto.patientAccountApi.RegisterResponse>}
 */
const methodDescriptor_PatientAccountProtoService_Register = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/Register',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.RegisterRequest,
  proto.patientAccountApi.RegisterResponse,
  /**
   * @param {!proto.patientAccountApi.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.RegisterResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.RegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.RegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/Register',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_Register,
      callback);
};


/**
 * @param {!proto.patientAccountApi.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.RegisterResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/Register',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.LoginRequest,
 *   !proto.patientAccountApi.LoginResponse>}
 */
const methodDescriptor_PatientAccountProtoService_Login = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/Login',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.LoginRequest,
  proto.patientAccountApi.LoginResponse,
  /**
   * @param {!proto.patientAccountApi.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/Login',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_Login,
      callback);
};


/**
 * @param {!proto.patientAccountApi.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/Login',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.EmptyRequest,
 *   !proto.patientAccountApi.VerifyTokenResponse>}
 */
const methodDescriptor_PatientAccountProtoService_VerifyToken = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/VerifyToken',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.EmptyRequest,
  proto.patientAccountApi.VerifyTokenResponse,
  /**
   * @param {!proto.patientAccountApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.VerifyTokenResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.VerifyTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.VerifyTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.verifyToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/VerifyToken',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_VerifyToken,
      callback);
};


/**
 * @param {!proto.patientAccountApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.VerifyTokenResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.verifyToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/VerifyToken',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_VerifyToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.VerifyContinueRegisterRequest,
 *   !proto.patientAccountApi.VerifyContinueRegisterResponse>}
 */
const methodDescriptor_PatientAccountProtoService_VerifyContinueRegister = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/VerifyContinueRegister',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.VerifyContinueRegisterRequest,
  proto.patientAccountApi.VerifyContinueRegisterResponse,
  /**
   * @param {!proto.patientAccountApi.VerifyContinueRegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.VerifyContinueRegisterResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.VerifyContinueRegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.VerifyContinueRegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.VerifyContinueRegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.verifyContinueRegister =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/VerifyContinueRegister',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_VerifyContinueRegister,
      callback);
};


/**
 * @param {!proto.patientAccountApi.VerifyContinueRegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.VerifyContinueRegisterResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.verifyContinueRegister =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/VerifyContinueRegister',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_VerifyContinueRegister);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.SaveInfoAfterUserCloseBrowserPageOneRequest,
 *   !proto.patientAccountApi.SaveInfoAfterUserCloseBrowserPageOneResponse>}
 */
const methodDescriptor_PatientAccountProtoService_SaveInfoAfterUserCloseBrowserPageOne = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/SaveInfoAfterUserCloseBrowserPageOne',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.SaveInfoAfterUserCloseBrowserPageOneRequest,
  proto.patientAccountApi.SaveInfoAfterUserCloseBrowserPageOneResponse,
  /**
   * @param {!proto.patientAccountApi.SaveInfoAfterUserCloseBrowserPageOneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.SaveInfoAfterUserCloseBrowserPageOneResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.SaveInfoAfterUserCloseBrowserPageOneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.SaveInfoAfterUserCloseBrowserPageOneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.SaveInfoAfterUserCloseBrowserPageOneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.saveInfoAfterUserCloseBrowserPageOne =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SaveInfoAfterUserCloseBrowserPageOne',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SaveInfoAfterUserCloseBrowserPageOne,
      callback);
};


/**
 * @param {!proto.patientAccountApi.SaveInfoAfterUserCloseBrowserPageOneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.SaveInfoAfterUserCloseBrowserPageOneResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.saveInfoAfterUserCloseBrowserPageOne =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SaveInfoAfterUserCloseBrowserPageOne',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SaveInfoAfterUserCloseBrowserPageOne);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.VerifyEmailRequest,
 *   !proto.patientAccountApi.VerifyEmailResponse>}
 */
const methodDescriptor_PatientAccountProtoService_VerifyEmail = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/VerifyEmail',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.VerifyEmailRequest,
  proto.patientAccountApi.VerifyEmailResponse,
  /**
   * @param {!proto.patientAccountApi.VerifyEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.VerifyEmailResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.VerifyEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.VerifyEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.VerifyEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.verifyEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_VerifyEmail,
      callback);
};


/**
 * @param {!proto.patientAccountApi.VerifyEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.VerifyEmailResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.verifyEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_VerifyEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.SendPhoneConfirmationRequest,
 *   !proto.patientAccountApi.SendPhoneConfirmationResponse>}
 */
const methodDescriptor_PatientAccountProtoService_SendPhoneConfirmation = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/SendPhoneConfirmation',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.SendPhoneConfirmationRequest,
  proto.patientAccountApi.SendPhoneConfirmationResponse,
  /**
   * @param {!proto.patientAccountApi.SendPhoneConfirmationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.SendPhoneConfirmationResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.SendPhoneConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.SendPhoneConfirmationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.SendPhoneConfirmationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.sendPhoneConfirmation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SendPhoneConfirmation',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SendPhoneConfirmation,
      callback);
};


/**
 * @param {!proto.patientAccountApi.SendPhoneConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.SendPhoneConfirmationResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.sendPhoneConfirmation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SendPhoneConfirmation',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SendPhoneConfirmation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.VerifyPhoneRequest,
 *   !proto.patientAccountApi.VerifyPhoneResponse>}
 */
const methodDescriptor_PatientAccountProtoService_VerifyPhone = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/VerifyPhone',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.VerifyPhoneRequest,
  proto.patientAccountApi.VerifyPhoneResponse,
  /**
   * @param {!proto.patientAccountApi.VerifyPhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.VerifyPhoneResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.VerifyPhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.VerifyPhoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.VerifyPhoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.verifyPhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/VerifyPhone',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_VerifyPhone,
      callback);
};


/**
 * @param {!proto.patientAccountApi.VerifyPhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.VerifyPhoneResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.verifyPhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/VerifyPhone',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_VerifyPhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.UpdatePhoneRequest,
 *   !proto.patientAccountApi.UpdatePhoneResponse>}
 */
const methodDescriptor_PatientAccountProtoService_UpdatePhone = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/UpdatePhone',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.UpdatePhoneRequest,
  proto.patientAccountApi.UpdatePhoneResponse,
  /**
   * @param {!proto.patientAccountApi.UpdatePhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.UpdatePhoneResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.UpdatePhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.UpdatePhoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.UpdatePhoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.updatePhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/UpdatePhone',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_UpdatePhone,
      callback);
};


/**
 * @param {!proto.patientAccountApi.UpdatePhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.UpdatePhoneResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.updatePhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/UpdatePhone',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_UpdatePhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.SendEmailAfterUserCloseBrowserRequest,
 *   !proto.patientAccountApi.SendEmailAfterUserCloseBrowserResponse>}
 */
const methodDescriptor_PatientAccountProtoService_SendEmailAfterUserCloseBrowser = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/SendEmailAfterUserCloseBrowser',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.SendEmailAfterUserCloseBrowserRequest,
  proto.patientAccountApi.SendEmailAfterUserCloseBrowserResponse,
  /**
   * @param {!proto.patientAccountApi.SendEmailAfterUserCloseBrowserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.SendEmailAfterUserCloseBrowserResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.SendEmailAfterUserCloseBrowserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.SendEmailAfterUserCloseBrowserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.SendEmailAfterUserCloseBrowserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.sendEmailAfterUserCloseBrowser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SendEmailAfterUserCloseBrowser',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SendEmailAfterUserCloseBrowser,
      callback);
};


/**
 * @param {!proto.patientAccountApi.SendEmailAfterUserCloseBrowserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.SendEmailAfterUserCloseBrowserResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.sendEmailAfterUserCloseBrowser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SendEmailAfterUserCloseBrowser',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SendEmailAfterUserCloseBrowser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.SendPasswordRecoveryCodeToEmailRequest,
 *   !proto.patientAccountApi.SendPasswordRecoveryCodeToEmailResponse>}
 */
const methodDescriptor_PatientAccountProtoService_SendPasswordRecoveryCodeToEmail = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/SendPasswordRecoveryCodeToEmail',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.SendPasswordRecoveryCodeToEmailRequest,
  proto.patientAccountApi.SendPasswordRecoveryCodeToEmailResponse,
  /**
   * @param {!proto.patientAccountApi.SendPasswordRecoveryCodeToEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.SendPasswordRecoveryCodeToEmailResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.SendPasswordRecoveryCodeToEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.SendPasswordRecoveryCodeToEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.SendPasswordRecoveryCodeToEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.sendPasswordRecoveryCodeToEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SendPasswordRecoveryCodeToEmail',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SendPasswordRecoveryCodeToEmail,
      callback);
};


/**
 * @param {!proto.patientAccountApi.SendPasswordRecoveryCodeToEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.SendPasswordRecoveryCodeToEmailResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.sendPasswordRecoveryCodeToEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SendPasswordRecoveryCodeToEmail',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SendPasswordRecoveryCodeToEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.FindUserAccountRequest,
 *   !proto.patientAccountApi.FindUserAccountResponse>}
 */
const methodDescriptor_PatientAccountProtoService_FindUserAccount = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/FindUserAccount',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.FindUserAccountRequest,
  proto.patientAccountApi.FindUserAccountResponse,
  /**
   * @param {!proto.patientAccountApi.FindUserAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.FindUserAccountResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.FindUserAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.FindUserAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.FindUserAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.findUserAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/FindUserAccount',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_FindUserAccount,
      callback);
};


/**
 * @param {!proto.patientAccountApi.FindUserAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.FindUserAccountResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.findUserAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/FindUserAccount',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_FindUserAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.SendPasswordRecoveryCodeToUserRequest,
 *   !proto.patientAccountApi.SendPasswordRecoveryCodeToUserResponse>}
 */
const methodDescriptor_PatientAccountProtoService_SendPasswordRecoveryCodeToUser = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/SendPasswordRecoveryCodeToUser',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.SendPasswordRecoveryCodeToUserRequest,
  proto.patientAccountApi.SendPasswordRecoveryCodeToUserResponse,
  /**
   * @param {!proto.patientAccountApi.SendPasswordRecoveryCodeToUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.SendPasswordRecoveryCodeToUserResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.SendPasswordRecoveryCodeToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.SendPasswordRecoveryCodeToUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.SendPasswordRecoveryCodeToUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.sendPasswordRecoveryCodeToUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SendPasswordRecoveryCodeToUser',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SendPasswordRecoveryCodeToUser,
      callback);
};


/**
 * @param {!proto.patientAccountApi.SendPasswordRecoveryCodeToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.SendPasswordRecoveryCodeToUserResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.sendPasswordRecoveryCodeToUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SendPasswordRecoveryCodeToUser',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SendPasswordRecoveryCodeToUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.VerifyPasswordRecoveryCodeRequest,
 *   !proto.patientAccountApi.VerifyPasswordRecoveryCodeResponse>}
 */
const methodDescriptor_PatientAccountProtoService_VerifyPasswordRecoveryCode = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/VerifyPasswordRecoveryCode',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.VerifyPasswordRecoveryCodeRequest,
  proto.patientAccountApi.VerifyPasswordRecoveryCodeResponse,
  /**
   * @param {!proto.patientAccountApi.VerifyPasswordRecoveryCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.VerifyPasswordRecoveryCodeResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.VerifyPasswordRecoveryCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.VerifyPasswordRecoveryCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.VerifyPasswordRecoveryCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.verifyPasswordRecoveryCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/VerifyPasswordRecoveryCode',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_VerifyPasswordRecoveryCode,
      callback);
};


/**
 * @param {!proto.patientAccountApi.VerifyPasswordRecoveryCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.VerifyPasswordRecoveryCodeResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.verifyPasswordRecoveryCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/VerifyPasswordRecoveryCode',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_VerifyPasswordRecoveryCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.UpdatePasswordRequest,
 *   !proto.patientAccountApi.UpdatePasswordResponse>}
 */
const methodDescriptor_PatientAccountProtoService_UpdatePassword = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/UpdatePassword',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.UpdatePasswordRequest,
  proto.patientAccountApi.UpdatePasswordResponse,
  /**
   * @param {!proto.patientAccountApi.UpdatePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.UpdatePasswordResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.UpdatePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.UpdatePasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.UpdatePasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.updatePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/UpdatePassword',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_UpdatePassword,
      callback);
};


/**
 * @param {!proto.patientAccountApi.UpdatePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.UpdatePasswordResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.updatePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/UpdatePassword',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_UpdatePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.GetInfoPatientRequest,
 *   !proto.patientAccountApi.GetInfoPatientResponse>}
 */
const methodDescriptor_PatientAccountProtoService_GetInfoPatient = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/GetInfoPatient',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.GetInfoPatientRequest,
  proto.patientAccountApi.GetInfoPatientResponse,
  /**
   * @param {!proto.patientAccountApi.GetInfoPatientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.GetInfoPatientResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.GetInfoPatientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.GetInfoPatientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.GetInfoPatientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.getInfoPatient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/GetInfoPatient',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_GetInfoPatient,
      callback);
};


/**
 * @param {!proto.patientAccountApi.GetInfoPatientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.GetInfoPatientResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.getInfoPatient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/GetInfoPatient',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_GetInfoPatient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.UpdateEmailRequest,
 *   !proto.patientAccountApi.UpdateEmailResponse>}
 */
const methodDescriptor_PatientAccountProtoService_UpdateEmail = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/UpdateEmail',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.UpdateEmailRequest,
  proto.patientAccountApi.UpdateEmailResponse,
  /**
   * @param {!proto.patientAccountApi.UpdateEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.UpdateEmailResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.UpdateEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.UpdateEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.UpdateEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.updateEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/UpdateEmail',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_UpdateEmail,
      callback);
};


/**
 * @param {!proto.patientAccountApi.UpdateEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.UpdateEmailResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.updateEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/UpdateEmail',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_UpdateEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.ConfirmExchangeEmailRequest,
 *   !proto.patientAccountApi.ConfirmExchangeEmailResponse>}
 */
const methodDescriptor_PatientAccountProtoService_ConfirmExchangeEmail = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/ConfirmExchangeEmail',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.ConfirmExchangeEmailRequest,
  proto.patientAccountApi.ConfirmExchangeEmailResponse,
  /**
   * @param {!proto.patientAccountApi.ConfirmExchangeEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.ConfirmExchangeEmailResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.ConfirmExchangeEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.ConfirmExchangeEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.ConfirmExchangeEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.confirmExchangeEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/ConfirmExchangeEmail',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_ConfirmExchangeEmail,
      callback);
};


/**
 * @param {!proto.patientAccountApi.ConfirmExchangeEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.ConfirmExchangeEmailResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.confirmExchangeEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/ConfirmExchangeEmail',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_ConfirmExchangeEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.SendSMSConfirmEmailRequest,
 *   !proto.patientAccountApi.SendSMSConfirmEmailResponse>}
 */
const methodDescriptor_PatientAccountProtoService_SendSMSConfirmEmail = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/SendSMSConfirmEmail',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.SendSMSConfirmEmailRequest,
  proto.patientAccountApi.SendSMSConfirmEmailResponse,
  /**
   * @param {!proto.patientAccountApi.SendSMSConfirmEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.SendSMSConfirmEmailResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.SendSMSConfirmEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.SendSMSConfirmEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.SendSMSConfirmEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.sendSMSConfirmEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SendSMSConfirmEmail',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SendSMSConfirmEmail,
      callback);
};


/**
 * @param {!proto.patientAccountApi.SendSMSConfirmEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.SendSMSConfirmEmailResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.sendSMSConfirmEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SendSMSConfirmEmail',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SendSMSConfirmEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientAccountApi.SendEmailConfirmationRequest,
 *   !proto.patientAccountApi.SendEmailConfirmationResponse>}
 */
const methodDescriptor_PatientAccountProtoService_SendEmailConfirmation = new grpc.web.MethodDescriptor(
  '/patientAccountApi.PatientAccountProtoService/SendEmailConfirmation',
  grpc.web.MethodType.UNARY,
  proto.patientAccountApi.SendEmailConfirmationRequest,
  proto.patientAccountApi.SendEmailConfirmationResponse,
  /**
   * @param {!proto.patientAccountApi.SendEmailConfirmationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientAccountApi.SendEmailConfirmationResponse.deserializeBinary
);


/**
 * @param {!proto.patientAccountApi.SendEmailConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientAccountApi.SendEmailConfirmationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientAccountApi.SendEmailConfirmationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientAccountApi.PatientAccountProtoServiceClient.prototype.sendEmailConfirmation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SendEmailConfirmation',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SendEmailConfirmation,
      callback);
};


/**
 * @param {!proto.patientAccountApi.SendEmailConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientAccountApi.SendEmailConfirmationResponse>}
 *     Promise that resolves to the response
 */
proto.patientAccountApi.PatientAccountProtoServicePromiseClient.prototype.sendEmailConfirmation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientAccountApi.PatientAccountProtoService/SendEmailConfirmation',
      request,
      metadata || {},
      methodDescriptor_PatientAccountProtoService_SendEmailConfirmation);
};


module.exports = proto.patientAccountApi;

