import {
  // UpdateInfoRequest,
  // UpdateInfoResponse,
  EmptyRequest,
} from '../grpc/generated/patientProfile_pb';
import { PatientProfileProtoServicePromiseClient } from "../grpc/generated/patientProfile_grpc_web_pb";

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new PatientProfileProtoServicePromiseClient(apiUrl);

const token = window.localStorage.getItem("token");
const metadata = { Authorization: "Bearer " + token };

async function getInfo() {

  const request = new EmptyRequest();
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };

  const response = await svc.getInfo(request, metadata, {});
  return response.toObject();
}

async function updateInfo(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.updateInfo(request, metadata, {});
  return response.toObject();
}

async function listDocuments() {
  const request = new EmptyRequest();

  const response = await svc.listDocuments(request, metadata, {});
  return response.toObject();
}

async function addLocation(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.addLocation(request, metadata, {});

  return response.toObject();
}

export default {
  getInfo,
  updateInfo,
  listDocuments,
  addLocation,
};
