/**
 * @fileoverview gRPC-Web generated client stub for professionalProfileApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.1
// source: professionalProfile.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.professionalProfileApi = require('./professionalProfile_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.EmptyRequest,
 *   !proto.professionalProfileApi.GetInfoResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_GetInfo = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/GetInfo',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.EmptyRequest,
  proto.professionalProfileApi.GetInfoResponse,
  /**
   * @param {!proto.professionalProfileApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.GetInfoResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.GetInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.GetInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.getInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/GetInfo',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_GetInfo,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.GetInfoResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.getInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/GetInfo',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_GetInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdateInfoRequest,
 *   !proto.professionalProfileApi.UpdateInfoResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdateInfo = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdateInfo',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdateInfoRequest,
  proto.professionalProfileApi.UpdateInfoResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdateInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdateInfoResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdateInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdateInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdateInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updateInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateInfo',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateInfo,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdateInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdateInfoResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updateInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateInfo',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListProfessionalsRequest,
 *   !proto.professionalProfileApi.ListProfessionalsResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListProfessionals = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListProfessionals',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListProfessionalsRequest,
  proto.professionalProfileApi.ListProfessionalsResponse,
  /**
   * @param {!proto.professionalProfileApi.ListProfessionalsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListProfessionalsResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListProfessionalsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListProfessionalsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListProfessionalsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listProfessionals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListProfessionals',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListProfessionals,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListProfessionalsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListProfessionalsResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listProfessionals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListProfessionals',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListProfessionals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddCouncilRequest,
 *   !proto.professionalProfileApi.AddCouncilResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddCouncil = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddCouncil',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddCouncilRequest,
  proto.professionalProfileApi.AddCouncilResponse,
  /**
   * @param {!proto.professionalProfileApi.AddCouncilRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddCouncilResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddCouncilRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddCouncilResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddCouncilResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addCouncil =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddCouncil',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddCouncil,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddCouncilRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddCouncilResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addCouncil =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddCouncil',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddCouncil);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.DeleteCouncilRequest,
 *   !proto.professionalProfileApi.DeleteCouncilResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_DeleteCouncil = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/DeleteCouncil',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.DeleteCouncilRequest,
  proto.professionalProfileApi.DeleteCouncilResponse,
  /**
   * @param {!proto.professionalProfileApi.DeleteCouncilRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.DeleteCouncilResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.DeleteCouncilRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.DeleteCouncilResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.DeleteCouncilResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.deleteCouncil =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteCouncil',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteCouncil,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.DeleteCouncilRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.DeleteCouncilResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.deleteCouncil =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteCouncil',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteCouncil);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddSpecialtyRequest,
 *   !proto.professionalProfileApi.AddSpecialtyResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddSpecialty = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddSpecialty',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddSpecialtyRequest,
  proto.professionalProfileApi.AddSpecialtyResponse,
  /**
   * @param {!proto.professionalProfileApi.AddSpecialtyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddSpecialtyResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddSpecialtyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddSpecialtyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddSpecialtyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addSpecialty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddSpecialty',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddSpecialty,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddSpecialtyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddSpecialtyResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addSpecialty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddSpecialty',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddSpecialty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.DeleteSpecialtyRequest,
 *   !proto.professionalProfileApi.DeleteSpecialtyResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_DeleteSpecialty = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/DeleteSpecialty',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.DeleteSpecialtyRequest,
  proto.professionalProfileApi.DeleteSpecialtyResponse,
  /**
   * @param {!proto.professionalProfileApi.DeleteSpecialtyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.DeleteSpecialtyResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.DeleteSpecialtyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.DeleteSpecialtyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.DeleteSpecialtyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.deleteSpecialty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteSpecialty',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteSpecialty,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.DeleteSpecialtyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.DeleteSpecialtyResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.deleteSpecialty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteSpecialty',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteSpecialty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListCouncilRequest,
 *   !proto.professionalProfileApi.ListCouncilResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListCouncil = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListCouncil',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListCouncilRequest,
  proto.professionalProfileApi.ListCouncilResponse,
  /**
   * @param {!proto.professionalProfileApi.ListCouncilRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListCouncilResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListCouncilRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListCouncilResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListCouncilResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listCouncil =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListCouncil',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListCouncil,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListCouncilRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListCouncilResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listCouncil =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListCouncil',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListCouncil);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdateNameRequest,
 *   !proto.professionalProfileApi.UpdateNameResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdateName = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdateName',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdateNameRequest,
  proto.professionalProfileApi.UpdateNameResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdateNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdateNameResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdateNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdateNameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdateNameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updateName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateName',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateName,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdateNameRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdateNameResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updateName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateName',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListActiveRegistriesRequest,
 *   !proto.professionalProfileApi.ListActiveRegistriesResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListActiveRegistries = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListActiveRegistries',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListActiveRegistriesRequest,
  proto.professionalProfileApi.ListActiveRegistriesResponse,
  /**
   * @param {!proto.professionalProfileApi.ListActiveRegistriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListActiveRegistriesResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListActiveRegistriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListActiveRegistriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListActiveRegistriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listActiveRegistries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListActiveRegistries',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListActiveRegistries,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListActiveRegistriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListActiveRegistriesResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listActiveRegistries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListActiveRegistries',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListActiveRegistries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddServiceRequest,
 *   !proto.professionalProfileApi.AddServiceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddService = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddService',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddServiceRequest,
  proto.professionalProfileApi.AddServiceResponse,
  /**
   * @param {!proto.professionalProfileApi.AddServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddServiceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddService',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddService,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddServiceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddService',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListServiceRequest,
 *   !proto.professionalProfileApi.ListServiceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListService = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListService',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListServiceRequest,
  proto.professionalProfileApi.ListServiceResponse,
  /**
   * @param {!proto.professionalProfileApi.ListServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListServiceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListService',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListService,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListServiceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListService',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.SearchListServiceRequest,
 *   !proto.professionalProfileApi.SearchListServiceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_SearchListService = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/SearchListService',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.SearchListServiceRequest,
  proto.professionalProfileApi.SearchListServiceResponse,
  /**
   * @param {!proto.professionalProfileApi.SearchListServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.SearchListServiceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.SearchListServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.SearchListServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.SearchListServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.searchListService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SearchListService',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SearchListService,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.SearchListServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.SearchListServiceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.searchListService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SearchListService',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SearchListService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListServiceIdRequest,
 *   !proto.professionalProfileApi.ListServiceIdResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListServiceId = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListServiceId',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListServiceIdRequest,
  proto.professionalProfileApi.ListServiceIdResponse,
  /**
   * @param {!proto.professionalProfileApi.ListServiceIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListServiceIdResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListServiceIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListServiceIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListServiceIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listServiceId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListServiceId',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListServiceId,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListServiceIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListServiceIdResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listServiceId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListServiceId',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListServiceId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdateServiceRequest,
 *   !proto.professionalProfileApi.UpdateServiceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdateService = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdateService',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdateServiceRequest,
  proto.professionalProfileApi.UpdateServiceResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdateServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdateServiceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdateServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdateServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdateServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updateService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateService',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateService,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdateServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdateServiceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updateService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateService',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdateStatusServiceRequest,
 *   !proto.professionalProfileApi.UpdateStatusServiceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdateStatusService = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdateStatusService',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdateStatusServiceRequest,
  proto.professionalProfileApi.UpdateStatusServiceResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdateStatusServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdateStatusServiceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdateStatusServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdateStatusServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdateStatusServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updateStatusService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateStatusService',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateStatusService,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdateStatusServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdateStatusServiceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updateStatusService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateStatusService',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateStatusService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.EmptyRequest,
 *   !proto.professionalProfileApi.DisplayMessageResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_DisplayMessage = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/DisplayMessage',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.EmptyRequest,
  proto.professionalProfileApi.DisplayMessageResponse,
  /**
   * @param {!proto.professionalProfileApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.DisplayMessageResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.DisplayMessageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.DisplayMessageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.displayMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DisplayMessage',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DisplayMessage,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.DisplayMessageResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.displayMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DisplayMessage',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DisplayMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListServiceIdLocationRequest,
 *   !proto.professionalProfileApi.ListServiceIdLocationResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListServiceIdLocation = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListServiceIdLocation',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListServiceIdLocationRequest,
  proto.professionalProfileApi.ListServiceIdLocationResponse,
  /**
   * @param {!proto.professionalProfileApi.ListServiceIdLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListServiceIdLocationResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListServiceIdLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListServiceIdLocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListServiceIdLocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listServiceIdLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListServiceIdLocation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListServiceIdLocation,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListServiceIdLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListServiceIdLocationResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listServiceIdLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListServiceIdLocation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListServiceIdLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddLocationRequest,
 *   !proto.professionalProfileApi.AddLocationResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddLocation = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddLocation',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddLocationRequest,
  proto.professionalProfileApi.AddLocationResponse,
  /**
   * @param {!proto.professionalProfileApi.AddLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddLocationResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddLocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddLocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddLocation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddLocation,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddLocationResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddLocation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.VerifyUfActiveRequest,
 *   !proto.professionalProfileApi.VerifyUfActiveResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_VerifyUfActive = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/VerifyUfActive',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.VerifyUfActiveRequest,
  proto.professionalProfileApi.VerifyUfActiveResponse,
  /**
   * @param {!proto.professionalProfileApi.VerifyUfActiveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.VerifyUfActiveResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.VerifyUfActiveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.VerifyUfActiveResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.VerifyUfActiveResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.verifyUfActive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/VerifyUfActive',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_VerifyUfActive,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.VerifyUfActiveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.VerifyUfActiveResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.verifyUfActive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/VerifyUfActive',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_VerifyUfActive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListLastsServicesRequest,
 *   !proto.professionalProfileApi.ListLastsServicesResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListLastsServices = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListLastsServices',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListLastsServicesRequest,
  proto.professionalProfileApi.ListLastsServicesResponse,
  /**
   * @param {!proto.professionalProfileApi.ListLastsServicesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListLastsServicesResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListLastsServicesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListLastsServicesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListLastsServicesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listLastsServices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListLastsServices',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListLastsServices,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListLastsServicesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListLastsServicesResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listLastsServices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListLastsServices',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListLastsServices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.SearchActiveServiceRequest,
 *   !proto.professionalProfileApi.SearchActiveServiceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_SearchActiveService = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/SearchActiveService',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.SearchActiveServiceRequest,
  proto.professionalProfileApi.SearchActiveServiceResponse,
  /**
   * @param {!proto.professionalProfileApi.SearchActiveServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.SearchActiveServiceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.SearchActiveServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.SearchActiveServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.SearchActiveServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.searchActiveService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SearchActiveService',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SearchActiveService,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.SearchActiveServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.SearchActiveServiceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.searchActiveService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SearchActiveService',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SearchActiveService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddServiceTypeRequest,
 *   !proto.professionalProfileApi.AddServiceTypeResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddServiceType = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddServiceType',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddServiceTypeRequest,
  proto.professionalProfileApi.AddServiceTypeResponse,
  /**
   * @param {!proto.professionalProfileApi.AddServiceTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddServiceTypeResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddServiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddServiceTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddServiceTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addServiceType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddServiceType',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddServiceType,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddServiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddServiceTypeResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addServiceType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddServiceType',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddServiceType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListPlacesRequest,
 *   !proto.professionalProfileApi.ListPlacesResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListPlaces = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListPlaces',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListPlacesRequest,
  proto.professionalProfileApi.ListPlacesResponse,
  /**
   * @param {!proto.professionalProfileApi.ListPlacesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListPlacesResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListPlacesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListPlacesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListPlacesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listPlaces =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListPlaces',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListPlaces,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListPlacesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListPlacesResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listPlaces =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListPlaces',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListPlaces);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdatePlaceRequest,
 *   !proto.professionalProfileApi.UpdatePlaceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdatePlace = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdatePlace',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdatePlaceRequest,
  proto.professionalProfileApi.UpdatePlaceResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdatePlaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdatePlaceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdatePlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdatePlaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdatePlaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updatePlace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdatePlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdatePlace,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdatePlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdatePlaceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updatePlace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdatePlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdatePlace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdateStatusPlaceRequest,
 *   !proto.professionalProfileApi.UpdateStatusPlaceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdateStatusPlace = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdateStatusPlace',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdateStatusPlaceRequest,
  proto.professionalProfileApi.UpdateStatusPlaceResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdateStatusPlaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdateStatusPlaceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdateStatusPlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdateStatusPlaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdateStatusPlaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updateStatusPlace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateStatusPlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateStatusPlace,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdateStatusPlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdateStatusPlaceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updateStatusPlace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateStatusPlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateStatusPlace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.DeletePlaceRequest,
 *   !proto.professionalProfileApi.DeletePlaceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_DeletePlace = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/DeletePlace',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.DeletePlaceRequest,
  proto.professionalProfileApi.DeletePlaceResponse,
  /**
   * @param {!proto.professionalProfileApi.DeletePlaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.DeletePlaceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.DeletePlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.DeletePlaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.DeletePlaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.deletePlace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeletePlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeletePlace,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.DeletePlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.DeletePlaceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.deletePlace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeletePlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeletePlace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListServicesPlaceRequest,
 *   !proto.professionalProfileApi.ListServicesPlaceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListServicesPlace = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListServicesPlace',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListServicesPlaceRequest,
  proto.professionalProfileApi.ListServicesPlaceResponse,
  /**
   * @param {!proto.professionalProfileApi.ListServicesPlaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListServicesPlaceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListServicesPlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListServicesPlaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListServicesPlaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listServicesPlace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListServicesPlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListServicesPlace,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListServicesPlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListServicesPlaceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listServicesPlace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListServicesPlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListServicesPlace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.SearchListPlaceRequest,
 *   !proto.professionalProfileApi.SearchListPlaceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_SearchListPlace = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/SearchListPlace',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.SearchListPlaceRequest,
  proto.professionalProfileApi.SearchListPlaceResponse,
  /**
   * @param {!proto.professionalProfileApi.SearchListPlaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.SearchListPlaceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.SearchListPlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.SearchListPlaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.SearchListPlaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.searchListPlace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SearchListPlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SearchListPlace,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.SearchListPlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.SearchListPlaceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.searchListPlace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SearchListPlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SearchListPlace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListServiceIdPlaceRequest,
 *   !proto.professionalProfileApi.ListServiceIdPlaceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListServiceIdPlace = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListServiceIdPlace',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListServiceIdPlaceRequest,
  proto.professionalProfileApi.ListServiceIdPlaceResponse,
  /**
   * @param {!proto.professionalProfileApi.ListServiceIdPlaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListServiceIdPlaceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListServiceIdPlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListServiceIdPlaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListServiceIdPlaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listServiceIdPlace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListServiceIdPlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListServiceIdPlace,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListServiceIdPlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListServiceIdPlaceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listServiceIdPlace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListServiceIdPlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListServiceIdPlace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdateServiceTypeRequest,
 *   !proto.professionalProfileApi.UpdateServiceTypeResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdateServiceType = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdateServiceType',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdateServiceTypeRequest,
  proto.professionalProfileApi.UpdateServiceTypeResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdateServiceTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdateServiceTypeResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdateServiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdateServiceTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdateServiceTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updateServiceType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateServiceType',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateServiceType,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdateServiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdateServiceTypeResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updateServiceType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateServiceType',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateServiceType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ChangeStatusToInactiveRequest,
 *   !proto.professionalProfileApi.ChangeStatusToInactiveResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ChangeStatusToInactive = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ChangeStatusToInactive',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ChangeStatusToInactiveRequest,
  proto.professionalProfileApi.ChangeStatusToInactiveResponse,
  /**
   * @param {!proto.professionalProfileApi.ChangeStatusToInactiveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ChangeStatusToInactiveResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ChangeStatusToInactiveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ChangeStatusToInactiveResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ChangeStatusToInactiveResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.changeStatusToInactive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ChangeStatusToInactive',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ChangeStatusToInactive,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ChangeStatusToInactiveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ChangeStatusToInactiveResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.changeStatusToInactive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ChangeStatusToInactive',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ChangeStatusToInactive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.VerifyChangeStatusToActiveRequest,
 *   !proto.professionalProfileApi.VerifyChangeStatusToActiveResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_VerifyChangeStatusToActive = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/VerifyChangeStatusToActive',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.VerifyChangeStatusToActiveRequest,
  proto.professionalProfileApi.VerifyChangeStatusToActiveResponse,
  /**
   * @param {!proto.professionalProfileApi.VerifyChangeStatusToActiveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.VerifyChangeStatusToActiveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.VerifyChangeStatusToActiveResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.VerifyChangeStatusToActiveResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.verifyChangeStatusToActive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/VerifyChangeStatusToActive',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_VerifyChangeStatusToActive,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.VerifyChangeStatusToActiveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.VerifyChangeStatusToActiveResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.verifyChangeStatusToActive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/VerifyChangeStatusToActive',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_VerifyChangeStatusToActive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.EmptyRequest,
 *   !proto.professionalProfileApi.ListColorsResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListColors = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListColors',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.EmptyRequest,
  proto.professionalProfileApi.ListColorsResponse,
  /**
   * @param {!proto.professionalProfileApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListColorsResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListColorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListColorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listColors =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListColors',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListColors,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListColorsResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listColors =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListColors',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListColors);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddBlockedAvailableScheduleRequest,
 *   !proto.professionalProfileApi.AddBlockedAvailableScheduleResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddBlockedAvailableSchedule = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddBlockedAvailableSchedule',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddBlockedAvailableScheduleRequest,
  proto.professionalProfileApi.AddBlockedAvailableScheduleResponse,
  /**
   * @param {!proto.professionalProfileApi.AddBlockedAvailableScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddBlockedAvailableScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddBlockedAvailableScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddBlockedAvailableScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addBlockedAvailableSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddBlockedAvailableSchedule',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddBlockedAvailableSchedule,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddBlockedAvailableScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddBlockedAvailableScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addBlockedAvailableSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddBlockedAvailableSchedule',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddBlockedAvailableSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.EmptyRequest,
 *   !proto.professionalProfileApi.ListPlacesActivesResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListPlacesActives = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListPlacesActives',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.EmptyRequest,
  proto.professionalProfileApi.ListPlacesActivesResponse,
  /**
   * @param {!proto.professionalProfileApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListPlacesActivesResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListPlacesActivesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListPlacesActivesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listPlacesActives =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListPlacesActives',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListPlacesActives,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListPlacesActivesResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listPlacesActives =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListPlacesActives',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListPlacesActives);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListServicesToPlaceRequest,
 *   !proto.professionalProfileApi.ListServicesToPlaceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListServicesToPlace = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListServicesToPlace',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListServicesToPlaceRequest,
  proto.professionalProfileApi.ListServicesToPlaceResponse,
  /**
   * @param {!proto.professionalProfileApi.ListServicesToPlaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListServicesToPlaceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListServicesToPlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListServicesToPlaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListServicesToPlaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listServicesToPlace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListServicesToPlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListServicesToPlace,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListServicesToPlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListServicesToPlaceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listServicesToPlace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListServicesToPlace',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListServicesToPlace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.VerifyMinTimeRequest,
 *   !proto.professionalProfileApi.VerifyMinTimeResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_VerifyMinTime = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/VerifyMinTime',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.VerifyMinTimeRequest,
  proto.professionalProfileApi.VerifyMinTimeResponse,
  /**
   * @param {!proto.professionalProfileApi.VerifyMinTimeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.VerifyMinTimeResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.VerifyMinTimeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.VerifyMinTimeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.VerifyMinTimeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.verifyMinTime =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/VerifyMinTime',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_VerifyMinTime,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.VerifyMinTimeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.VerifyMinTimeResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.verifyMinTime =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/VerifyMinTime',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_VerifyMinTime);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.CreateAvailableScheduleRequest,
 *   !proto.professionalProfileApi.CreateAvailableScheduleResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_CreateAvailableSchedule = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/CreateAvailableSchedule',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.CreateAvailableScheduleRequest,
  proto.professionalProfileApi.CreateAvailableScheduleResponse,
  /**
   * @param {!proto.professionalProfileApi.CreateAvailableScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.CreateAvailableScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.CreateAvailableScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.CreateAvailableScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.CreateAvailableScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.createAvailableSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/CreateAvailableSchedule',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_CreateAvailableSchedule,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.CreateAvailableScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.CreateAvailableScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.createAvailableSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/CreateAvailableSchedule',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_CreateAvailableSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListAvailableScheduleRequest,
 *   !proto.professionalProfileApi.ListAvailableScheduleResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListAvailableSchedule = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListAvailableSchedule',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListAvailableScheduleRequest,
  proto.professionalProfileApi.ListAvailableScheduleResponse,
  /**
   * @param {!proto.professionalProfileApi.ListAvailableScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListAvailableScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListAvailableScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListAvailableScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListAvailableScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listAvailableSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListAvailableSchedule',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListAvailableSchedule,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListAvailableScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListAvailableScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listAvailableSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListAvailableSchedule',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListAvailableSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListBlockedAvailableScheduleRequest,
 *   !proto.professionalProfileApi.ListBlockedAvailableScheduleResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListBlockedAvailableSchedule = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListBlockedAvailableSchedule',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListBlockedAvailableScheduleRequest,
  proto.professionalProfileApi.ListBlockedAvailableScheduleResponse,
  /**
   * @param {!proto.professionalProfileApi.ListBlockedAvailableScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListBlockedAvailableScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListBlockedAvailableScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListBlockedAvailableScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listBlockedAvailableSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListBlockedAvailableSchedule',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListBlockedAvailableSchedule,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListBlockedAvailableScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListBlockedAvailableScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listBlockedAvailableSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListBlockedAvailableSchedule',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListBlockedAvailableSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListAvailableScheduleHistoryRequest,
 *   !proto.professionalProfileApi.ListAvailableScheduleHistoryResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListAvailableScheduleHistory = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListAvailableScheduleHistory',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListAvailableScheduleHistoryRequest,
  proto.professionalProfileApi.ListAvailableScheduleHistoryResponse,
  /**
   * @param {!proto.professionalProfileApi.ListAvailableScheduleHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListAvailableScheduleHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListAvailableScheduleHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListAvailableScheduleHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listAvailableScheduleHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListAvailableScheduleHistory',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListAvailableScheduleHistory,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListAvailableScheduleHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListAvailableScheduleHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listAvailableScheduleHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListAvailableScheduleHistory',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListAvailableScheduleHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest,
 *   !proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_DeleteAvailableScheduleHistory = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/DeleteAvailableScheduleHistory',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest,
  proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse,
  /**
   * @param {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.deleteAvailableScheduleHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteAvailableScheduleHistory',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteAvailableScheduleHistory,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.deleteAvailableScheduleHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteAvailableScheduleHistory',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteAvailableScheduleHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListAvailableScheduleIdRequest,
 *   !proto.professionalProfileApi.ListAvailableScheduleIdResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListAvailableScheduleId = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListAvailableScheduleId',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListAvailableScheduleIdRequest,
  proto.professionalProfileApi.ListAvailableScheduleIdResponse,
  /**
   * @param {!proto.professionalProfileApi.ListAvailableScheduleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListAvailableScheduleIdResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListAvailableScheduleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListAvailableScheduleIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListAvailableScheduleIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listAvailableScheduleId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListAvailableScheduleId',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListAvailableScheduleId,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListAvailableScheduleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListAvailableScheduleIdResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listAvailableScheduleId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListAvailableScheduleId',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListAvailableScheduleId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.VerifyHolidayRequest,
 *   !proto.professionalProfileApi.VerifyHolidayResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_VerifyHoliday = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/VerifyHoliday',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.VerifyHolidayRequest,
  proto.professionalProfileApi.VerifyHolidayResponse,
  /**
   * @param {!proto.professionalProfileApi.VerifyHolidayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.VerifyHolidayResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.VerifyHolidayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.VerifyHolidayResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.VerifyHolidayResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.verifyHoliday =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/VerifyHoliday',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_VerifyHoliday,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.VerifyHolidayRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.VerifyHolidayResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.verifyHoliday =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/VerifyHoliday',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_VerifyHoliday);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddAwardRequest,
 *   !proto.professionalProfileApi.AddAwardResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddAward = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddAward',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddAwardRequest,
  proto.professionalProfileApi.AddAwardResponse,
  /**
   * @param {!proto.professionalProfileApi.AddAwardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddAwardResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddAwardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddAwardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddAwardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addAward =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddAward',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddAward,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddAwardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddAwardResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addAward =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddAward',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddAward);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdateAwardRequest,
 *   !proto.professionalProfileApi.UpdateAwardResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdateAward = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdateAward',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdateAwardRequest,
  proto.professionalProfileApi.UpdateAwardResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdateAwardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdateAwardResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdateAwardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdateAwardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdateAwardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updateAward =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateAward',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateAward,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdateAwardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdateAwardResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updateAward =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateAward',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateAward);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.DeleteAwardRequest,
 *   !proto.professionalProfileApi.DeleteAwardResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_DeleteAward = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/DeleteAward',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.DeleteAwardRequest,
  proto.professionalProfileApi.DeleteAwardResponse,
  /**
   * @param {!proto.professionalProfileApi.DeleteAwardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.DeleteAwardResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.DeleteAwardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.DeleteAwardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.DeleteAwardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.deleteAward =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteAward',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteAward,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.DeleteAwardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.DeleteAwardResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.deleteAward =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteAward',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteAward);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddIdiomRequest,
 *   !proto.professionalProfileApi.AddIdiomResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddIdiom = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddIdiom',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddIdiomRequest,
  proto.professionalProfileApi.AddIdiomResponse,
  /**
   * @param {!proto.professionalProfileApi.AddIdiomRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddIdiomResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddIdiomRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddIdiomResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddIdiomResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addIdiom =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddIdiom',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddIdiom,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddIdiomRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddIdiomResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addIdiom =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddIdiom',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddIdiom);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.DeleteIdiomRequest,
 *   !proto.professionalProfileApi.DeleteIdiomResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_DeleteIdiom = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/DeleteIdiom',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.DeleteIdiomRequest,
  proto.professionalProfileApi.DeleteIdiomResponse,
  /**
   * @param {!proto.professionalProfileApi.DeleteIdiomRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.DeleteIdiomResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.DeleteIdiomRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.DeleteIdiomResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.DeleteIdiomResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.deleteIdiom =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteIdiom',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteIdiom,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.DeleteIdiomRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.DeleteIdiomResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.deleteIdiom =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteIdiom',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteIdiom);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddExperienceRequest,
 *   !proto.professionalProfileApi.AddExperienceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddExperience = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddExperience',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddExperienceRequest,
  proto.professionalProfileApi.AddExperienceResponse,
  /**
   * @param {!proto.professionalProfileApi.AddExperienceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddExperienceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddExperienceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddExperienceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddExperienceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addExperience =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddExperience',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddExperience,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddExperienceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddExperienceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addExperience =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddExperience',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddExperience);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdateExperienceRequest,
 *   !proto.professionalProfileApi.UpdateExperienceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdateExperience = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdateExperience',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdateExperienceRequest,
  proto.professionalProfileApi.UpdateExperienceResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdateExperienceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdateExperienceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdateExperienceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdateExperienceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdateExperienceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updateExperience =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateExperience',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateExperience,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdateExperienceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdateExperienceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updateExperience =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateExperience',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateExperience);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.DeleteExperienceRequest,
 *   !proto.professionalProfileApi.DeleteExperienceResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_DeleteExperience = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/DeleteExperience',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.DeleteExperienceRequest,
  proto.professionalProfileApi.DeleteExperienceResponse,
  /**
   * @param {!proto.professionalProfileApi.DeleteExperienceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.DeleteExperienceResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.DeleteExperienceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.DeleteExperienceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.DeleteExperienceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.deleteExperience =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteExperience',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteExperience,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.DeleteExperienceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.DeleteExperienceResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.deleteExperience =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteExperience',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteExperience);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddPublicationRequest,
 *   !proto.professionalProfileApi.AddPublicationResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddPublication = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddPublication',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddPublicationRequest,
  proto.professionalProfileApi.AddPublicationResponse,
  /**
   * @param {!proto.professionalProfileApi.AddPublicationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddPublicationResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddPublicationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddPublicationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddPublicationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addPublication =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddPublication',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddPublication,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddPublicationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddPublicationResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addPublication =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddPublication',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddPublication);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdatePublicationRequest,
 *   !proto.professionalProfileApi.UpdatePublicationResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdatePublication = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdatePublication',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdatePublicationRequest,
  proto.professionalProfileApi.UpdatePublicationResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdatePublicationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdatePublicationResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdatePublicationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdatePublicationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdatePublicationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updatePublication =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdatePublication',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdatePublication,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdatePublicationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdatePublicationResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updatePublication =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdatePublication',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdatePublication);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.DeletePublicationRequest,
 *   !proto.professionalProfileApi.DeletePublicationResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_DeletePublication = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/DeletePublication',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.DeletePublicationRequest,
  proto.professionalProfileApi.DeletePublicationResponse,
  /**
   * @param {!proto.professionalProfileApi.DeletePublicationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.DeletePublicationResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.DeletePublicationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.DeletePublicationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.DeletePublicationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.deletePublication =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeletePublication',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeletePublication,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.DeletePublicationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.DeletePublicationResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.deletePublication =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeletePublication',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeletePublication);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddDiseaseRequest,
 *   !proto.professionalProfileApi.AddDiseaseResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddDisease = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddDisease',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddDiseaseRequest,
  proto.professionalProfileApi.AddDiseaseResponse,
  /**
   * @param {!proto.professionalProfileApi.AddDiseaseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddDiseaseResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddDiseaseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddDiseaseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddDiseaseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addDisease =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddDisease',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddDisease,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddDiseaseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddDiseaseResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addDisease =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddDisease',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddDisease);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.DeleteDiseaseRequest,
 *   !proto.professionalProfileApi.DeleteDiseaseResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_DeleteDisease = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/DeleteDisease',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.DeleteDiseaseRequest,
  proto.professionalProfileApi.DeleteDiseaseResponse,
  /**
   * @param {!proto.professionalProfileApi.DeleteDiseaseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.DeleteDiseaseResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.DeleteDiseaseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.DeleteDiseaseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.DeleteDiseaseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.deleteDisease =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteDisease',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteDisease,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.DeleteDiseaseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.DeleteDiseaseResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.deleteDisease =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteDisease',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteDisease);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddFormationRequest,
 *   !proto.professionalProfileApi.AddFormationResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddFormation = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddFormation',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddFormationRequest,
  proto.professionalProfileApi.AddFormationResponse,
  /**
   * @param {!proto.professionalProfileApi.AddFormationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddFormationResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddFormationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddFormationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddFormationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addFormation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddFormation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddFormation,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddFormationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddFormationResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addFormation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddFormation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddFormation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdateFormationRequest,
 *   !proto.professionalProfileApi.UpdateFormationResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdateFormation = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdateFormation',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdateFormationRequest,
  proto.professionalProfileApi.UpdateFormationResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdateFormationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdateFormationResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdateFormationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdateFormationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdateFormationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updateFormation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateFormation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateFormation,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdateFormationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdateFormationResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updateFormation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateFormation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateFormation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.DeleteFormationRequest,
 *   !proto.professionalProfileApi.DeleteFormationResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_DeleteFormation = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/DeleteFormation',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.DeleteFormationRequest,
  proto.professionalProfileApi.DeleteFormationResponse,
  /**
   * @param {!proto.professionalProfileApi.DeleteFormationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.DeleteFormationResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.DeleteFormationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.DeleteFormationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.DeleteFormationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.deleteFormation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteFormation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteFormation,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.DeleteFormationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.DeleteFormationResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.deleteFormation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeleteFormation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeleteFormation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdateVisiblesRequest,
 *   !proto.professionalProfileApi.UpdateVisiblesResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdateVisibles = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdateVisibles',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdateVisiblesRequest,
  proto.professionalProfileApi.UpdateVisiblesResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdateVisiblesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdateVisiblesResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdateVisiblesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdateVisiblesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdateVisiblesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updateVisibles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateVisibles',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateVisibles,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdateVisiblesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdateVisiblesResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updateVisibles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateVisibles',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateVisibles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.EmptyRequest,
 *   !proto.professionalProfileApi.ListProfessionalProfileResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListProfessionalProfile = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListProfessionalProfile',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.EmptyRequest,
  proto.professionalProfileApi.ListProfessionalProfileResponse,
  /**
   * @param {!proto.professionalProfileApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListProfessionalProfileResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListProfessionalProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListProfessionalProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listProfessionalProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListProfessionalProfile',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListProfessionalProfile,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListProfessionalProfileResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listProfessionalProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListProfessionalProfile',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListProfessionalProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.UpdateBiographyRequest,
 *   !proto.professionalProfileApi.UpdateBiographyResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_UpdateBiography = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/UpdateBiography',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.UpdateBiographyRequest,
  proto.professionalProfileApi.UpdateBiographyResponse,
  /**
   * @param {!proto.professionalProfileApi.UpdateBiographyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.UpdateBiographyResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.UpdateBiographyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.UpdateBiographyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.UpdateBiographyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.updateBiography =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateBiography',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateBiography,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.UpdateBiographyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.UpdateBiographyResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.updateBiography =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/UpdateBiography',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_UpdateBiography);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.EmptyRequest,
 *   !proto.professionalProfileApi.SeeAsPatientResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_SeeAsPatient = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/SeeAsPatient',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.EmptyRequest,
  proto.professionalProfileApi.SeeAsPatientResponse,
  /**
   * @param {!proto.professionalProfileApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.SeeAsPatientResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.SeeAsPatientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.SeeAsPatientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.seeAsPatient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SeeAsPatient',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SeeAsPatient,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.SeeAsPatientResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.seeAsPatient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SeeAsPatient',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SeeAsPatient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest,
 *   !proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListProfessionalsMedicalRecords = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListProfessionalsMedicalRecords',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest,
  proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse,
  /**
   * @param {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listProfessionalsMedicalRecords =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListProfessionalsMedicalRecords',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListProfessionalsMedicalRecords,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listProfessionalsMedicalRecords =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListProfessionalsMedicalRecords',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListProfessionalsMedicalRecords);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.SearchProfessionalsRequest,
 *   !proto.professionalProfileApi.SearchProfessionalsResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_SearchProfessionals = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/SearchProfessionals',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.SearchProfessionalsRequest,
  proto.professionalProfileApi.SearchProfessionalsResponse,
  /**
   * @param {!proto.professionalProfileApi.SearchProfessionalsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.SearchProfessionalsResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.SearchProfessionalsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.SearchProfessionalsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.SearchProfessionalsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.searchProfessionals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SearchProfessionals',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SearchProfessionals,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.SearchProfessionalsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.SearchProfessionalsResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.searchProfessionals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SearchProfessionals',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SearchProfessionals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.AddPartnershipRequest,
 *   !proto.professionalProfileApi.AddPartnershipResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_AddPartnership = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/AddPartnership',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.AddPartnershipRequest,
  proto.professionalProfileApi.AddPartnershipResponse,
  /**
   * @param {!proto.professionalProfileApi.AddPartnershipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.AddPartnershipResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.AddPartnershipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.AddPartnershipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.AddPartnershipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.addPartnership =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddPartnership',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddPartnership,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.AddPartnershipRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.AddPartnershipResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.addPartnership =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/AddPartnership',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_AddPartnership);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.DeletePartnershipRequest,
 *   !proto.professionalProfileApi.DeletePartnershipResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_DeletePartnership = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/DeletePartnership',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.DeletePartnershipRequest,
  proto.professionalProfileApi.DeletePartnershipResponse,
  /**
   * @param {!proto.professionalProfileApi.DeletePartnershipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.DeletePartnershipResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.DeletePartnershipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.DeletePartnershipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.DeletePartnershipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.deletePartnership =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeletePartnership',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeletePartnership,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.DeletePartnershipRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.DeletePartnershipResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.deletePartnership =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/DeletePartnership',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_DeletePartnership);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.EmptyRequest,
 *   !proto.professionalProfileApi.ListPartnershipsResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListPartnerships = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListPartnerships',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.EmptyRequest,
  proto.professionalProfileApi.ListPartnershipsResponse,
  /**
   * @param {!proto.professionalProfileApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListPartnershipsResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListPartnershipsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListPartnershipsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listPartnerships =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListPartnerships',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListPartnerships,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListPartnershipsResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listPartnerships =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListPartnerships',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListPartnerships);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.SearchListPartnershipsRequest,
 *   !proto.professionalProfileApi.SearchListPartnershipsResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_SearchListPartnerships = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/SearchListPartnerships',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.SearchListPartnershipsRequest,
  proto.professionalProfileApi.SearchListPartnershipsResponse,
  /**
   * @param {!proto.professionalProfileApi.SearchListPartnershipsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.SearchListPartnershipsResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.SearchListPartnershipsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.SearchListPartnershipsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.SearchListPartnershipsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.searchListPartnerships =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SearchListPartnerships',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SearchListPartnerships,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.SearchListPartnershipsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.SearchListPartnershipsResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.searchListPartnerships =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SearchListPartnerships',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SearchListPartnerships);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.GetPartnerRequest,
 *   !proto.professionalProfileApi.GetPartnerResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_GetPartner = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/GetPartner',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.GetPartnerRequest,
  proto.professionalProfileApi.GetPartnerResponse,
  /**
   * @param {!proto.professionalProfileApi.GetPartnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.GetPartnerResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.GetPartnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.GetPartnerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.GetPartnerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.getPartner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/GetPartner',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_GetPartner,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.GetPartnerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.GetPartnerResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.getPartner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/GetPartner',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_GetPartner);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.GetPartnershipsRequest,
 *   !proto.professionalProfileApi.GetPartnershipsResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_GetPartnerships = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/GetPartnerships',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.GetPartnershipsRequest,
  proto.professionalProfileApi.GetPartnershipsResponse,
  /**
   * @param {!proto.professionalProfileApi.GetPartnershipsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.GetPartnershipsResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.GetPartnershipsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.GetPartnershipsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.GetPartnershipsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.getPartnerships =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/GetPartnerships',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_GetPartnerships,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.GetPartnershipsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.GetPartnershipsResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.getPartnerships =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/GetPartnerships',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_GetPartnerships);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.SearchAppointmentRequest,
 *   !proto.professionalProfileApi.SearchAppointmentResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_SearchAppointment = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/SearchAppointment',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.SearchAppointmentRequest,
  proto.professionalProfileApi.SearchAppointmentResponse,
  /**
   * @param {!proto.professionalProfileApi.SearchAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.SearchAppointmentResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.SearchAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.SearchAppointmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.SearchAppointmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.searchAppointment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SearchAppointment',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SearchAppointment,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.SearchAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.SearchAppointmentResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.searchAppointment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/SearchAppointment',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_SearchAppointment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.ListProfessionalAvailableRequest,
 *   !proto.professionalProfileApi.ListProfessionalAvailableResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_ListProfessionalAvailable = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/ListProfessionalAvailable',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.ListProfessionalAvailableRequest,
  proto.professionalProfileApi.ListProfessionalAvailableResponse,
  /**
   * @param {!proto.professionalProfileApi.ListProfessionalAvailableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.ListProfessionalAvailableResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.ListProfessionalAvailableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.ListProfessionalAvailableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.ListProfessionalAvailableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.listProfessionalAvailable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListProfessionalAvailable',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListProfessionalAvailable,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.ListProfessionalAvailableRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.ListProfessionalAvailableResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.listProfessionalAvailable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/ListProfessionalAvailable',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_ListProfessionalAvailable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalProfileApi.GetServiceTypeInformationsRequest,
 *   !proto.professionalProfileApi.GetServiceTypeInformationsResponse>}
 */
const methodDescriptor_ProfessionalProfileProtoService_GetServiceTypeInformations = new grpc.web.MethodDescriptor(
  '/professionalProfileApi.ProfessionalProfileProtoService/GetServiceTypeInformations',
  grpc.web.MethodType.UNARY,
  proto.professionalProfileApi.GetServiceTypeInformationsRequest,
  proto.professionalProfileApi.GetServiceTypeInformationsResponse,
  /**
   * @param {!proto.professionalProfileApi.GetServiceTypeInformationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalProfileApi.GetServiceTypeInformationsResponse.deserializeBinary
);


/**
 * @param {!proto.professionalProfileApi.GetServiceTypeInformationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalProfileApi.GetServiceTypeInformationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalProfileApi.GetServiceTypeInformationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalProfileApi.ProfessionalProfileProtoServiceClient.prototype.getServiceTypeInformations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/GetServiceTypeInformations',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_GetServiceTypeInformations,
      callback);
};


/**
 * @param {!proto.professionalProfileApi.GetServiceTypeInformationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalProfileApi.GetServiceTypeInformationsResponse>}
 *     Promise that resolves to the response
 */
proto.professionalProfileApi.ProfessionalProfileProtoServicePromiseClient.prototype.getServiceTypeInformations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalProfileApi.ProfessionalProfileProtoService/GetServiceTypeInformations',
      request,
      metadata || {},
      methodDescriptor_ProfessionalProfileProtoService_GetServiceTypeInformations);
};


module.exports = proto.professionalProfileApi;

