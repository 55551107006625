<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'PublicLayout',
};
</script>

<style>
</style>
