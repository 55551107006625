<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M17.8415 7.69994C17.8415 7.69994 17.8415 7.69994 17.8415 7.65828C17.2543 6.05513 16.1889 4.67101 14.7894 3.69316C13.3899 2.71531 11.7238 2.19092 10.0165 2.19092C8.30921 2.19092 6.64312 2.71531 5.2436 3.69316C3.84408 4.67101 2.77867 6.05513 2.1915 7.65828C2.1915 7.65828 2.1915 7.65828 2.1915 7.69994C1.53577 9.50902 1.53577 11.4909 2.1915 13.2999C2.1915 13.2999 2.1915 13.2999 2.1915 13.3416C2.77867 14.9448 3.84408 16.3289 5.2436 17.3067C6.64312 18.2846 8.30921 18.809 10.0165 18.809C11.7238 18.809 13.3899 18.2846 14.7894 17.3067C16.1889 16.3289 17.2543 14.9448 17.8415 13.3416C17.8415 13.3416 17.8415 13.3416 17.8415 13.2999C18.4972 11.4909 18.4972 9.50902 17.8415 7.69994ZM3.54984 12.1666C3.26086 11.0743 3.26086 9.9256 3.54984 8.83327H5.09984C4.96653 9.94039 4.96653 11.0595 5.09984 12.1666H3.54984ZM4.23317 13.8333H5.39984C5.59544 14.5765 5.87501 15.295 6.23317 15.9749C5.41592 15.4182 4.73275 14.6867 4.23317 13.8333ZM5.39984 7.16661H4.23317C4.72556 6.31585 5.4 5.5845 6.20817 5.02494C5.85863 5.70598 5.58746 6.42446 5.39984 7.16661ZM9.1665 16.9166C8.14297 16.1655 7.42413 15.071 7.1415 13.8333H9.1665V16.9166ZM9.1665 12.1666H6.78317C6.62766 11.0609 6.62766 9.93894 6.78317 8.83327H9.1665V12.1666ZM9.1665 7.16661H7.1415C7.42413 5.92891 8.14297 4.83437 9.1665 4.08328V7.16661ZM15.7665 7.16661H14.5998C14.4042 6.42341 14.1247 5.70489 13.7665 5.02494C14.5838 5.58167 15.2669 6.31323 15.7665 7.16661ZM10.8332 4.08328C11.8567 4.83437 12.5755 5.92891 12.8582 7.16661H10.8332V4.08328ZM10.8332 16.9166V13.8333H12.8582C12.5755 15.071 11.8567 16.1655 10.8332 16.9166ZM13.2165 12.1666H10.8332V8.83327H13.2165C13.372 9.93894 13.372 11.0609 13.2165 12.1666ZM13.7915 15.9749C14.1497 15.295 14.4292 14.5765 14.6248 13.8333H15.7915C15.2919 14.6867 14.6088 15.4182 13.7915 15.9749ZM16.4498 12.1666H14.8998C14.9676 11.6137 15.001 11.057 14.9998 10.4999C15.0008 9.94286 14.9674 9.38625 14.8998 8.83327H16.4498C16.7388 9.9256 16.7388 11.0743 16.4498 12.1666Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>