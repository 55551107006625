import { VerifyToken } from "./actions";

// SCREEN 1 NO VALIDATION
// const AccountLogin = async (to, from, next) => {
//     next();
// }
const AccountLogin = async (to, from, next) => {
    const verifyToken = await VerifyToken();

    if(verifyToken.success){
        const data = JSON.parse(verifyToken.data);
        
        if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next();
            }else{
                next({ name: 'Main' })
            }
        }else{
            next();
        }
    }else{
        next();
    }
}


const AccountMain = async (to, from, next) => {
    const verifyToken = await VerifyToken();

    if(verifyToken.success){
        const data = JSON.parse(verifyToken.data);
        
        if('screen' in data){
            const { screen } = data;

            if(screen === 2){
                next({ name: 'Account.Login' });
            }else if ( screen === 3 ){
                next({ name: 'Account.Login' });
            }else if (screen === 4) {
                next({ name: 'Account.Login' });
            }else if (screen === 5){
                next({ name: 'Account.Login' });
            }
        } else if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next({ name: 'Account.Login' });
            }else{
                next();
            }
        }
    }else{
        next({ name: 'Account.Login' });
    }
}

const AccountEmailConfirm = async (to, from, next) => {
    const verifyToken = await VerifyToken();

    if(verifyToken.success){
        const data = JSON.parse(verifyToken.data);

        if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next();
                
            }else{
                next({ name: 'Account.Login' });
            }
        }else{
            next({ name: 'Account.Login' });
        }
        
    }else{
        next({ name: 'Account.Login' });
    }
}



export default {
    AccountLogin,
    AccountMain,
    AccountEmailConfirm,
}