<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M19.9011 18.5992L16.5003 15.2259C17.8204 13.5799 18.4597 11.4907 18.2867 9.38789C18.1137 7.28506 17.1416 5.32842 15.5703 3.92029C13.999 2.51217 11.9479 1.75959 9.83879 1.8173C7.72965 1.87501 5.72277 2.73863 4.23083 4.23058C2.73888 5.72253 1.87526 7.7294 1.81754 9.83855C1.75983 11.9477 2.51241 13.9988 3.92054 15.5701C5.32866 17.1414 7.28531 18.1135 9.38814 18.2864C11.491 18.4594 13.5802 17.8201 15.2261 16.5L18.5994 19.8734C18.6847 19.9593 18.786 20.0275 18.8977 20.074C19.0095 20.1206 19.1293 20.1445 19.2503 20.1445C19.3713 20.1445 19.4911 20.1206 19.6028 20.074C19.7145 20.0275 19.8159 19.9593 19.9011 19.8734C20.0663 19.7024 20.1587 19.474 20.1587 19.2363C20.1587 18.9986 20.0663 18.7701 19.9011 18.5992ZM10.0836 16.5C8.81452 16.5 7.57392 16.1237 6.5187 15.4186C5.46349 14.7136 4.64105 13.7114 4.15538 12.5389C3.66972 11.3664 3.54265 10.0763 3.79024 8.83154C4.03783 7.58683 4.64896 6.44349 5.54634 5.5461C6.44373 4.64871 7.58707 4.03758 8.83178 3.79C10.0765 3.54241 11.3667 3.66948 12.5392 4.15514C13.7117 4.6408 14.7138 5.46324 15.4189 6.51846C16.1239 7.57367 16.5003 8.81427 16.5003 10.0834C16.5003 11.7852 15.8242 13.4173 14.6209 14.6206C13.4175 15.824 11.7854 16.5 10.0836 16.5Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>