import {
  // UpdateInfoRequest,
  // UpdateInfoResponse,
  // AddServiceRequest,
  // AddServiceResponse,
  // DeleteServiceRequest,
  // DeleteServiceResponse,
  EmptyRequest,
  ListCouncilRequest,
  // ListServicesResponse,
  // AddLocationRequest,
  // AddLocationResponse,
  // DeleteLocationRequest,
  // DeleteLocationResponse,
  // ListLocations,
} from '../grpc/generated/professionalProfile_pb';
import { ProfessionalProfileProtoServicePromiseClient } from "../grpc/generated/professionalProfile_grpc_web_pb";

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new ProfessionalProfileProtoServicePromiseClient(apiUrl);

const token = window.localStorage.getItem("token");
const metadata = { Authorization: "Bearer " + token };

async function getInfo() {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };  
  // console.log(metadata);
  const request = new EmptyRequest();
  const response = await svc.getInfo(request, metadata, {});
  return response.toObject();
}

async function updateInfo(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };

  const response = await svc.updateInfo(request, metadata, {});
  // console.log(response)

  return response.toObject();
}

async function updateName(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.updateName(request, metadata, {});
  return response.toObject();
}

async function updateStatus(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.updateStatusService(request, metadata, {});
  return response.toObject();
}

async function searchListPlace(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.searchListPlace(request, metadata, {});
  return response.toObject();
}

async function searchListService(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token};
  const response = await svc.searchListService(request, metadata, {});
  return response.toObject();
}

async function listActiveRegistries() {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };  
  const request = new EmptyRequest();
  const response = await svc.listActiveRegistries(request, metadata, {});
  return response.toObject();
}

async function listServices(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  // const request = new EmptyRequest();
  const response = await svc.listService(request, metadata, {});

  return response.toObject();
}

async function listServiceId(request) {
  const token = window.localStorage.getItem("token")
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.listServiceId(request, metadata, {});
  return response.toObject();
}

async function addService(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };

  const response = await svc.addService(request, metadata, {});

  return response.toObject();
}

async function updateService(request) {
  const token = window.localStorage.getItem("token")
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.updateService(request, metadata, {});
  return response.toObject();
}

async function deleteService(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.deleteService(request, metadata, {});

  return response.toObject();
}

async function addLocation(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.addLocation(request, metadata, {});
  return response.toObject();
}

async function updateLocation(request) {
  const token = window.localStorage.getItem("token")
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.updatePlace(request, metadata, {});
  return response.toObject();
}

async function updateStatusPlace(request) {
  const token = window.localStorage.getItem("token")
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.updateStatusPlace(request, metadata, {});
  return response.toObject();
}

async function deleteLocation(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.deletePlace(request, metadata, {});

  return response.toObject();
}

async function listPlaces(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  // const request = new EmptyRequest();
  console.log(request)
  const response = await svc.listPlaces(request, metadata, {});
  return response.toObject();
}

async function listServicesPlace(request) {
  const token = window.localStorage.getItem("token")
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.listServicesPlace(request, metadata, {});
  return response.toObject();
}

async function addServiceType(request) {
  const token = window.localStorage.getItem("token")
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.addServiceType(request, metadata, {});
  return response.toObject();
}

async function verifyUfActive(request) {
  const token = window.localStorage.getItem("token")
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.verifyUfActive(request, metadata, {});
  return response.toObject();
}

async function listLastService() {
  const token = window.localStorage.getItem("token")
  const metadata = { Authorization: "Bearer " + token };
  const request = new EmptyRequest();
  const response = await svc.listLastsServices(request, metadata, {});
  return response.toObject();
}

async function searchActiveService(request) {
  const response = await svc.searchActiveService(request, metadata, {});
  return response.toObject();
}

async function listAvailabilitiesByLocation(request) {
  const response = await svc.listAvailabilitiesByLocation(request, metadata, {});
  return response.toObject();
}

async function addAvailability(request) {
  const response = await svc.addAvailability(request, metadata, {});
  return response.toObject();
}

async function deleteAvailability(request) {
  const response = await svc.deleteAvailability(request, metadata, {});
  return response.toObject();
}

async function listAvailableServices() {
  const request = new EmptyRequest();
  const response = await svc.listAvailableServices(request, metadata, {});
  return response.toObject();
}

async function listAvailableLocations() {
  const request = new EmptyRequest();
  const response = await svc.listAvailableLocations(request, metadata, {});
  return response.toObject();
}

async function listAvailableProfessionals() {
  const request = new EmptyRequest();
  const response = await svc.listAvailableProfessionals(request, metadata, {});
  return response.toObject();
}

async function listDocuments() {
  const request = new EmptyRequest();
  const response = await svc.listDocuments(request, metadata, {});
  return response.toObject();
}

async function addCouncil(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };

  const response = await svc.addCouncil(request, metadata, {});
  return response.toObject();
}

async function listCouncil() {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };  
  const request = new ListCouncilRequest()
  const response = await svc.listCouncil(request, metadata, {});
  return response.toObject();
}

async function editCouncil(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };

  const response = await svc.editCouncil(request, metadata, {});
  return response.toObject();
}

async function deleteCouncil(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };

  const response = await svc.deleteCouncil(request, metadata, {});
  return response.toObject();
}

async function addSpeciality(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };

  const response = await svc.addSpecialty(request, metadata, {});
  return response.toObject();

}

async function editSpeciality(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };

  const response = await svc.editSpecialty(request, metadata, {});
  return response.toObject();
}

async function deleteSpeciality(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };

  const response = await svc.deleteSpecialty(request, metadata, {});
  return response.toObject();

}

async function addActionActuation(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };

  const response = await svc.addActionActuation(request, metadata, {});
  return response.toObject();

}

async function editActuation(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };

  const response = await svc.editActionActuation(request, metadata, {});
  return response.toObject();
}

async function deleteActionActuation(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };

  const response = await svc.deleteActionActuation(request, metadata, {});
  return response.toObject();

}

//Schedule
async function addBlockedAvailableSchedule(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.addBlockedAvailableSchedule(request, metadata, {});
  return response.toObject();

}

async function listPlacesActives() {
  const request = new EmptyRequest();
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.listPlacesActives(request, metadata, {});
  return response.toObject();
}

async function listServicesToPlace(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.listServicesToPlace(request, metadata, {});
  return response.toObject();
}

async function listDays() {
  const request = new EmptyRequest();
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token};
  const response = await svc.listDays(request, metadata, {});
  return response.toObject();
}

async function verifyMinTime(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.verifyMinTime(request, metadata, {});
  return response.toObject();
}

async function createAvailableSchedule(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.createAvailableSchedule(request, metadata, {});
  return response.toObject();
}

async function listAvailableSchedule(request) {
  const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.listAvailableSchedule(request, metadata, {});
  return response.toObject();
}

async function searchAppointment(request) {
  const response = await svc.searchAppointment(request, metadata, {});
  return response.toObject();
}

async function listProfessionalAvailable(request) {
  const response = await svc.listProfessionalAvailable(request, metadata, {});
  return response.toObject();
}

export default {
  getInfo,
  updateInfo,
  updateName,
  updateStatus,
  addService,
  updateService,
  deleteService,
  listServices,
  listServiceId,
  addLocation,
  updateStatusPlace,
  updateLocation,
  deleteLocation,
  searchListService,
  searchListPlace,
  listPlaces,
  listServicesPlace,
  addServiceType,
  listAvailabilitiesByLocation,  
  addAvailability,
  deleteAvailability,
  listAvailableServices, listAvailableProfessionals, listAvailableLocations,
  listDocuments,
  addCouncil, editCouncil,
  listCouncil, deleteCouncil,
  addSpeciality, editSpeciality, deleteSpeciality,
  addActionActuation, editActuation, deleteActionActuation,
  listActiveRegistries, verifyUfActive, listLastService,
  searchActiveService,
  addBlockedAvailableSchedule,
  listPlacesActives,
  listServicesToPlace,
  listDays,
  verifyMinTime,
  createAvailableSchedule,
  listAvailableSchedule,
  searchAppointment,
  listProfessionalAvailable,
};
