<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M12.4513 18.8375C14.1045 16.7686 17.875 11.7541 17.875 8.9375C17.875 5.52148 15.1035 2.75 11.6875 2.75C8.27148 2.75 5.5 5.52148 5.5 8.9375C5.5 11.7541 9.27051 16.7686 10.9237 18.8375C11.3201 19.3306 12.0549 19.3306 12.4513 18.8375ZM11.6875 11C10.5499 11 9.625 10.0751 9.625 8.9375C9.625 7.7999 10.5499 6.875 11.6875 6.875C12.8251 6.875 13.75 7.7999 13.75 8.9375C13.75 10.0751 12.8251 11 11.6875 11Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>