<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M18.167 8.83317C17.8337 7.83317 16.8337 7.1665 15.8337 7.1665C14.417 7.1665 13.3337 8.24984 13.3337 9.6665C13.3337 10.7498 14.0003 11.6665 15.0003 11.9998V13.4165C15.0003 15.4998 13.3337 17.1665 11.2503 17.1665C9.16699 17.1665 7.50033 15.4998 7.50033 13.4165V12.9165C9.91699 12.4998 11.667 10.4165 11.667 7.99984V2.99984C11.667 2.49984 11.3337 2.1665 10.8337 2.1665H9.16699C8.66699 2.1665 8.33366 2.49984 8.33366 2.99984C8.33366 3.49984 8.66699 3.83317 9.16699 3.83317H10.0003V7.99984C10.0003 9.83317 8.50033 11.3332 6.66699 11.3332C4.83366 11.3332 3.33366 9.83317 3.33366 7.99984V3.83317H4.16699C4.66699 3.83317 5.00033 3.49984 5.00033 2.99984C5.00033 2.49984 4.66699 2.1665 4.16699 2.1665H2.50033C2.00033 2.1665 1.66699 2.49984 1.66699 2.99984V7.99984C1.66699 10.4165 3.41699 12.4998 5.83366 12.9165V13.4165C5.83366 16.4165 8.25033 18.8332 11.2503 18.8332C14.2503 18.8332 16.667 16.4165 16.667 13.4165V11.9998C18.0003 11.5832 18.667 10.1665 18.167 8.83317Z" fill="#6FCF97"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>