<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path d="M17.657 4.6668C16.7684 3.78739 15.5948 3.25453 14.3479 3.16437C13.1009 3.07422 11.8628 3.4327 10.857 4.17514C9.79671 3.3865 8.47699 3.0289 7.1636 3.17434C5.85021 3.31977 4.6407 3.95745 3.77865 4.95896C2.91659 5.96046 2.46602 7.2514 2.51767 8.57182C2.56932 9.89223 3.11935 11.144 4.057 12.0751L9.057 17.1168C9.52575 17.585 10.1612 17.8479 10.8237 17.8479C11.4862 17.8479 12.1216 17.585 12.5903 17.1168L17.5903 12.0751C18.0828 11.594 18.4753 11.0203 18.7453 10.387C19.0153 9.75369 19.1575 9.07327 19.1637 8.38483C19.1699 7.69639 19.04 7.01352 18.7814 6.37545C18.5228 5.73739 18.1407 5.15672 17.657 4.6668ZM16.482 10.8835L11.482 15.8835C11.4045 15.9616 11.3124 16.0236 11.2108 16.0659C11.1093 16.1082 11.0003 16.13 10.8903 16.13C10.7803 16.13 10.6714 16.1082 10.5699 16.0659C10.4683 16.0236 10.3761 15.9616 10.2987 15.8835L5.29867 10.8835C4.64513 10.2154 4.27918 9.31803 4.27918 8.38347C4.27918 7.44892 4.64513 6.55152 5.29867 5.88347C5.96463 5.22596 6.86281 4.85728 7.79867 4.85728C8.73452 4.85728 9.6327 5.22596 10.2987 5.88347C10.3761 5.96158 10.4683 6.02357 10.5699 6.06588C10.6714 6.10819 10.7803 6.12997 10.8903 6.12997C11.0003 6.12997 11.1093 6.10819 11.2108 6.06588C11.3124 6.02357 11.4045 5.96158 11.482 5.88347C12.166 5.33921 13.0263 5.06574 13.899 5.11522C14.7717 5.16469 15.5957 5.53365 16.2137 6.15172C16.8318 6.7698 17.2008 7.59375 17.2503 8.46644C17.2997 9.33913 17.0263 10.1995 16.482 10.8835Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>