// source: patientProfile.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.patientProfileApi.AddLocationRequest', null, global);
goog.exportSymbol('proto.patientProfileApi.AddLocationResponse', null, global);
goog.exportSymbol('proto.patientProfileApi.EmptyRequest', null, global);
goog.exportSymbol('proto.patientProfileApi.GetInfoResponse', null, global);
goog.exportSymbol('proto.patientProfileApi.GetPatientInfoRequest', null, global);
goog.exportSymbol('proto.patientProfileApi.GetPatientInfoResponse', null, global);
goog.exportSymbol('proto.patientProfileApi.ListDocumentsResponse', null, global);
goog.exportSymbol('proto.patientProfileApi.ListPatientHealthRequest', null, global);
goog.exportSymbol('proto.patientProfileApi.ListPatientHealthResponse', null, global);
goog.exportSymbol('proto.patientProfileApi.ListPatientsRequest', null, global);
goog.exportSymbol('proto.patientProfileApi.ListPatientsResponse', null, global);
goog.exportSymbol('proto.patientProfileApi.Patient', null, global);
goog.exportSymbol('proto.patientProfileApi.PatientHealth', null, global);
goog.exportSymbol('proto.patientProfileApi.PatientInfoProto', null, global);
goog.exportSymbol('proto.patientProfileApi.SearchPatientsRequest', null, global);
goog.exportSymbol('proto.patientProfileApi.SearchPatientsResponse', null, global);
goog.exportSymbol('proto.patientProfileApi.UpdateInfoRequest', null, global);
goog.exportSymbol('proto.patientProfileApi.UpdateInfoResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.GetPatientInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.GetPatientInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.GetPatientInfoRequest.displayName = 'proto.patientProfileApi.GetPatientInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.GetPatientInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.GetPatientInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.GetPatientInfoResponse.displayName = 'proto.patientProfileApi.GetPatientInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.PatientInfoProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.PatientInfoProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.PatientInfoProto.displayName = 'proto.patientProfileApi.PatientInfoProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.EmptyRequest.displayName = 'proto.patientProfileApi.EmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.GetInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.GetInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.GetInfoResponse.displayName = 'proto.patientProfileApi.GetInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.UpdateInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.UpdateInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.UpdateInfoRequest.displayName = 'proto.patientProfileApi.UpdateInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.UpdateInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.UpdateInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.UpdateInfoResponse.displayName = 'proto.patientProfileApi.UpdateInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.AddLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.AddLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.AddLocationRequest.displayName = 'proto.patientProfileApi.AddLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.AddLocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.AddLocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.AddLocationResponse.displayName = 'proto.patientProfileApi.AddLocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.SearchPatientsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.SearchPatientsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.SearchPatientsRequest.displayName = 'proto.patientProfileApi.SearchPatientsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.SearchPatientsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.SearchPatientsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.SearchPatientsResponse.displayName = 'proto.patientProfileApi.SearchPatientsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.ListDocumentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.ListDocumentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.ListDocumentsResponse.displayName = 'proto.patientProfileApi.ListDocumentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.Patient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.Patient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.Patient.displayName = 'proto.patientProfileApi.Patient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.ListPatientsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.ListPatientsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.ListPatientsRequest.displayName = 'proto.patientProfileApi.ListPatientsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.ListPatientsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.patientProfileApi.ListPatientsResponse.repeatedFields_, null);
};
goog.inherits(proto.patientProfileApi.ListPatientsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.ListPatientsResponse.displayName = 'proto.patientProfileApi.ListPatientsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.ListPatientHealthRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.ListPatientHealthRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.ListPatientHealthRequest.displayName = 'proto.patientProfileApi.ListPatientHealthRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.PatientHealth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.PatientHealth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.PatientHealth.displayName = 'proto.patientProfileApi.PatientHealth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.patientProfileApi.ListPatientHealthResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.patientProfileApi.ListPatientHealthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.patientProfileApi.ListPatientHealthResponse.displayName = 'proto.patientProfileApi.ListPatientHealthResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.GetPatientInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.GetPatientInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.GetPatientInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.GetPatientInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    patientId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.GetPatientInfoRequest}
 */
proto.patientProfileApi.GetPatientInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.GetPatientInfoRequest;
  return proto.patientProfileApi.GetPatientInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.GetPatientInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.GetPatientInfoRequest}
 */
proto.patientProfileApi.GetPatientInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.GetPatientInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.GetPatientInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.GetPatientInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.GetPatientInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string patient_id = 1;
 * @return {string}
 */
proto.patientProfileApi.GetPatientInfoRequest.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.GetPatientInfoRequest} returns this
 */
proto.patientProfileApi.GetPatientInfoRequest.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.GetPatientInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.GetPatientInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.GetPatientInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.GetPatientInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patient: (f = msg.getPatient()) && proto.patientProfileApi.PatientInfoProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.GetPatientInfoResponse}
 */
proto.patientProfileApi.GetPatientInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.GetPatientInfoResponse;
  return proto.patientProfileApi.GetPatientInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.GetPatientInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.GetPatientInfoResponse}
 */
proto.patientProfileApi.GetPatientInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.patientProfileApi.PatientInfoProto;
      reader.readMessage(value,proto.patientProfileApi.PatientInfoProto.deserializeBinaryFromReader);
      msg.setPatient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.GetPatientInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.GetPatientInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.GetPatientInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.GetPatientInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatient();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.patientProfileApi.PatientInfoProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.patientProfileApi.GetPatientInfoResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.patientProfileApi.GetPatientInfoResponse} returns this
 */
proto.patientProfileApi.GetPatientInfoResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.patientProfileApi.GetPatientInfoResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.GetPatientInfoResponse} returns this
 */
proto.patientProfileApi.GetPatientInfoResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.patientProfileApi.GetPatientInfoResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.GetPatientInfoResponse} returns this
 */
proto.patientProfileApi.GetPatientInfoResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PatientInfoProto patient = 4;
 * @return {?proto.patientProfileApi.PatientInfoProto}
 */
proto.patientProfileApi.GetPatientInfoResponse.prototype.getPatient = function() {
  return /** @type{?proto.patientProfileApi.PatientInfoProto} */ (
    jspb.Message.getWrapperField(this, proto.patientProfileApi.PatientInfoProto, 4));
};


/**
 * @param {?proto.patientProfileApi.PatientInfoProto|undefined} value
 * @return {!proto.patientProfileApi.GetPatientInfoResponse} returns this
*/
proto.patientProfileApi.GetPatientInfoResponse.prototype.setPatient = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.patientProfileApi.GetPatientInfoResponse} returns this
 */
proto.patientProfileApi.GetPatientInfoResponse.prototype.clearPatient = function() {
  return this.setPatient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.patientProfileApi.GetPatientInfoResponse.prototype.hasPatient = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.PatientInfoProto.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.PatientInfoProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.PatientInfoProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.PatientInfoProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    patientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    socialSecurityNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.PatientInfoProto}
 */
proto.patientProfileApi.PatientInfoProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.PatientInfoProto;
  return proto.patientProfileApi.PatientInfoProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.PatientInfoProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.PatientInfoProto}
 */
proto.patientProfileApi.PatientInfoProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocialSecurityNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.PatientInfoProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.PatientInfoProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.PatientInfoProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.PatientInfoProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSocialSecurityNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string patient_id = 1;
 * @return {string}
 */
proto.patientProfileApi.PatientInfoProto.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientInfoProto} returns this
 */
proto.patientProfileApi.PatientInfoProto.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.patientProfileApi.PatientInfoProto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientInfoProto} returns this
 */
proto.patientProfileApi.PatientInfoProto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string social_security_number = 3;
 * @return {string}
 */
proto.patientProfileApi.PatientInfoProto.prototype.getSocialSecurityNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientInfoProto} returns this
 */
proto.patientProfileApi.PatientInfoProto.prototype.setSocialSecurityNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address = 4;
 * @return {string}
 */
proto.patientProfileApi.PatientInfoProto.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientInfoProto} returns this
 */
proto.patientProfileApi.PatientInfoProto.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.patientProfileApi.PatientInfoProto.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientInfoProto} returns this
 */
proto.patientProfileApi.PatientInfoProto.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.patientProfileApi.PatientInfoProto.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientInfoProto} returns this
 */
proto.patientProfileApi.PatientInfoProto.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.EmptyRequest}
 */
proto.patientProfileApi.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.EmptyRequest;
  return proto.patientProfileApi.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.EmptyRequest}
 */
proto.patientProfileApi.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.GetInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.GetInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.GetInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.GetInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.GetInfoResponse}
 */
proto.patientProfileApi.GetInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.GetInfoResponse;
  return proto.patientProfileApi.GetInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.GetInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.GetInfoResponse}
 */
proto.patientProfileApi.GetInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.GetInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.GetInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.GetInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.GetInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.patientProfileApi.GetInfoResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.patientProfileApi.GetInfoResponse} returns this
 */
proto.patientProfileApi.GetInfoResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.patientProfileApi.GetInfoResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.GetInfoResponse} returns this
 */
proto.patientProfileApi.GetInfoResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.patientProfileApi.GetInfoResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.GetInfoResponse} returns this
 */
proto.patientProfileApi.GetInfoResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.UpdateInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.UpdateInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.UpdateInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.UpdateInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    socialsecuritynumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identitynumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    birthdate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mothername: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fathername: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.UpdateInfoRequest}
 */
proto.patientProfileApi.UpdateInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.UpdateInfoRequest;
  return proto.patientProfileApi.UpdateInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.UpdateInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.UpdateInfoRequest}
 */
proto.patientProfileApi.UpdateInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocialsecuritynumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitynumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMothername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFathername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.UpdateInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.UpdateInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.UpdateInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.UpdateInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSocialsecuritynumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentitynumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBirthdate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMothername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFathername();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string socialSecurityNumber = 1;
 * @return {string}
 */
proto.patientProfileApi.UpdateInfoRequest.prototype.getSocialsecuritynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.UpdateInfoRequest} returns this
 */
proto.patientProfileApi.UpdateInfoRequest.prototype.setSocialsecuritynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string identityNumber = 2;
 * @return {string}
 */
proto.patientProfileApi.UpdateInfoRequest.prototype.getIdentitynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.UpdateInfoRequest} returns this
 */
proto.patientProfileApi.UpdateInfoRequest.prototype.setIdentitynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string birthDate = 3;
 * @return {string}
 */
proto.patientProfileApi.UpdateInfoRequest.prototype.getBirthdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.UpdateInfoRequest} returns this
 */
proto.patientProfileApi.UpdateInfoRequest.prototype.setBirthdate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string motherName = 4;
 * @return {string}
 */
proto.patientProfileApi.UpdateInfoRequest.prototype.getMothername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.UpdateInfoRequest} returns this
 */
proto.patientProfileApi.UpdateInfoRequest.prototype.setMothername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string fatherName = 5;
 * @return {string}
 */
proto.patientProfileApi.UpdateInfoRequest.prototype.getFathername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.UpdateInfoRequest} returns this
 */
proto.patientProfileApi.UpdateInfoRequest.prototype.setFathername = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.UpdateInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.UpdateInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.UpdateInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.UpdateInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.UpdateInfoResponse}
 */
proto.patientProfileApi.UpdateInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.UpdateInfoResponse;
  return proto.patientProfileApi.UpdateInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.UpdateInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.UpdateInfoResponse}
 */
proto.patientProfileApi.UpdateInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.UpdateInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.UpdateInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.UpdateInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.UpdateInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.patientProfileApi.UpdateInfoResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.patientProfileApi.UpdateInfoResponse} returns this
 */
proto.patientProfileApi.UpdateInfoResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.patientProfileApi.UpdateInfoResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.UpdateInfoResponse} returns this
 */
proto.patientProfileApi.UpdateInfoResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.patientProfileApi.UpdateInfoResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.UpdateInfoResponse} returns this
 */
proto.patientProfileApi.UpdateInfoResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.AddLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.AddLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.AddLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.AddLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    postalcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: jspb.Message.getFieldWithDefault(msg, 4, ""),
    number: jspb.Message.getFieldWithDefault(msg, 5, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 6, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 7, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.AddLocationRequest}
 */
proto.patientProfileApi.AddLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.AddLocationRequest;
  return proto.patientProfileApi.AddLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.AddLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.AddLocationRequest}
 */
proto.patientProfileApi.AddLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.AddLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.AddLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.AddLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.AddLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
};


/**
 * optional string postalCode = 1;
 * @return {string}
 */
proto.patientProfileApi.AddLocationRequest.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.AddLocationRequest} returns this
 */
proto.patientProfileApi.AddLocationRequest.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.patientProfileApi.AddLocationRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.AddLocationRequest} returns this
 */
proto.patientProfileApi.AddLocationRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.patientProfileApi.AddLocationRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.AddLocationRequest} returns this
 */
proto.patientProfileApi.AddLocationRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address = 4;
 * @return {string}
 */
proto.patientProfileApi.AddLocationRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.AddLocationRequest} returns this
 */
proto.patientProfileApi.AddLocationRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string number = 5;
 * @return {string}
 */
proto.patientProfileApi.AddLocationRequest.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.AddLocationRequest} returns this
 */
proto.patientProfileApi.AddLocationRequest.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string neighborhood = 6;
 * @return {string}
 */
proto.patientProfileApi.AddLocationRequest.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.AddLocationRequest} returns this
 */
proto.patientProfileApi.AddLocationRequest.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string complement = 7;
 * @return {string}
 */
proto.patientProfileApi.AddLocationRequest.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.AddLocationRequest} returns this
 */
proto.patientProfileApi.AddLocationRequest.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double latitude = 8;
 * @return {number}
 */
proto.patientProfileApi.AddLocationRequest.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.patientProfileApi.AddLocationRequest} returns this
 */
proto.patientProfileApi.AddLocationRequest.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double longitude = 9;
 * @return {number}
 */
proto.patientProfileApi.AddLocationRequest.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.patientProfileApi.AddLocationRequest} returns this
 */
proto.patientProfileApi.AddLocationRequest.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.AddLocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.AddLocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.AddLocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.AddLocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.AddLocationResponse}
 */
proto.patientProfileApi.AddLocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.AddLocationResponse;
  return proto.patientProfileApi.AddLocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.AddLocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.AddLocationResponse}
 */
proto.patientProfileApi.AddLocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.AddLocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.AddLocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.AddLocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.AddLocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.patientProfileApi.AddLocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.patientProfileApi.AddLocationResponse} returns this
 */
proto.patientProfileApi.AddLocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.patientProfileApi.AddLocationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.AddLocationResponse} returns this
 */
proto.patientProfileApi.AddLocationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.patientProfileApi.AddLocationResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.AddLocationResponse} returns this
 */
proto.patientProfileApi.AddLocationResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.SearchPatientsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.SearchPatientsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.SearchPatientsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.SearchPatientsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.SearchPatientsRequest}
 */
proto.patientProfileApi.SearchPatientsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.SearchPatientsRequest;
  return proto.patientProfileApi.SearchPatientsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.SearchPatientsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.SearchPatientsRequest}
 */
proto.patientProfileApi.SearchPatientsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.SearchPatientsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.SearchPatientsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.SearchPatientsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.SearchPatientsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.patientProfileApi.SearchPatientsRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.SearchPatientsRequest} returns this
 */
proto.patientProfileApi.SearchPatientsRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.SearchPatientsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.SearchPatientsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.SearchPatientsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.SearchPatientsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.SearchPatientsResponse}
 */
proto.patientProfileApi.SearchPatientsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.SearchPatientsResponse;
  return proto.patientProfileApi.SearchPatientsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.SearchPatientsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.SearchPatientsResponse}
 */
proto.patientProfileApi.SearchPatientsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.SearchPatientsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.SearchPatientsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.SearchPatientsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.SearchPatientsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.patientProfileApi.SearchPatientsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.patientProfileApi.SearchPatientsResponse} returns this
 */
proto.patientProfileApi.SearchPatientsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.patientProfileApi.SearchPatientsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.SearchPatientsResponse} returns this
 */
proto.patientProfileApi.SearchPatientsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.patientProfileApi.SearchPatientsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.SearchPatientsResponse} returns this
 */
proto.patientProfileApi.SearchPatientsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.ListDocumentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.ListDocumentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.ListDocumentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.ListDocumentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.ListDocumentsResponse}
 */
proto.patientProfileApi.ListDocumentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.ListDocumentsResponse;
  return proto.patientProfileApi.ListDocumentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.ListDocumentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.ListDocumentsResponse}
 */
proto.patientProfileApi.ListDocumentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.ListDocumentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.ListDocumentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.ListDocumentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.ListDocumentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.patientProfileApi.ListDocumentsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.patientProfileApi.ListDocumentsResponse} returns this
 */
proto.patientProfileApi.ListDocumentsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.patientProfileApi.ListDocumentsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.ListDocumentsResponse} returns this
 */
proto.patientProfileApi.ListDocumentsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.patientProfileApi.ListDocumentsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.ListDocumentsResponse} returns this
 */
proto.patientProfileApi.ListDocumentsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.Patient.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.Patient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.Patient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.Patient.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    socialsecuritynumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    identitynumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    birthdate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    mothername: jspb.Message.getFieldWithDefault(msg, 9, ""),
    fathername: jspb.Message.getFieldWithDefault(msg, 10, ""),
    picturefileid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    pictureurl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 13, ""),
    updatedat: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.Patient}
 */
proto.patientProfileApi.Patient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.Patient;
  return proto.patientProfileApi.Patient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.Patient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.Patient}
 */
proto.patientProfileApi.Patient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocialsecuritynumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitynumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthdate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMothername(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFathername(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPicturefileid(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureurl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.Patient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.Patient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.Patient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.Patient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSocialsecuritynumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIdentitynumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBirthdate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMothername();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFathername();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPicturefileid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPictureurl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUpdatedat();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string socialSecurityNumber = 5;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getSocialsecuritynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setSocialsecuritynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string identityNumber = 6;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getIdentitynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setIdentitynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string birthDate = 7;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getBirthdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setBirthdate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phoneNumber = 8;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string motherName = 9;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getMothername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setMothername = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string fatherName = 10;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getFathername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setFathername = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string pictureFileId = 11;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getPicturefileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setPicturefileid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string pictureUrl = 12;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getPictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setPictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string createdAt = 13;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string updatedAt = 14;
 * @return {string}
 */
proto.patientProfileApi.Patient.prototype.getUpdatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.Patient} returns this
 */
proto.patientProfileApi.Patient.prototype.setUpdatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.ListPatientsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.ListPatientsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.ListPatientsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.ListPatientsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.ListPatientsRequest}
 */
proto.patientProfileApi.ListPatientsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.ListPatientsRequest;
  return proto.patientProfileApi.ListPatientsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.ListPatientsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.ListPatientsRequest}
 */
proto.patientProfileApi.ListPatientsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.ListPatientsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.ListPatientsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.ListPatientsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.ListPatientsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.patientProfileApi.ListPatientsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.ListPatientsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.ListPatientsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.ListPatientsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.ListPatientsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientsList: jspb.Message.toObjectList(msg.getPatientsList(),
    proto.patientProfileApi.Patient.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.ListPatientsResponse}
 */
proto.patientProfileApi.ListPatientsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.ListPatientsResponse;
  return proto.patientProfileApi.ListPatientsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.ListPatientsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.ListPatientsResponse}
 */
proto.patientProfileApi.ListPatientsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.patientProfileApi.Patient;
      reader.readMessage(value,proto.patientProfileApi.Patient.deserializeBinaryFromReader);
      msg.addPatients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.ListPatientsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.ListPatientsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.ListPatientsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.ListPatientsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.patientProfileApi.Patient.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.patientProfileApi.ListPatientsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.patientProfileApi.ListPatientsResponse} returns this
 */
proto.patientProfileApi.ListPatientsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.patientProfileApi.ListPatientsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.ListPatientsResponse} returns this
 */
proto.patientProfileApi.ListPatientsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.patientProfileApi.ListPatientsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.ListPatientsResponse} returns this
 */
proto.patientProfileApi.ListPatientsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Patient patients = 4;
 * @return {!Array<!proto.patientProfileApi.Patient>}
 */
proto.patientProfileApi.ListPatientsResponse.prototype.getPatientsList = function() {
  return /** @type{!Array<!proto.patientProfileApi.Patient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.patientProfileApi.Patient, 4));
};


/**
 * @param {!Array<!proto.patientProfileApi.Patient>} value
 * @return {!proto.patientProfileApi.ListPatientsResponse} returns this
*/
proto.patientProfileApi.ListPatientsResponse.prototype.setPatientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.patientProfileApi.Patient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.patientProfileApi.Patient}
 */
proto.patientProfileApi.ListPatientsResponse.prototype.addPatients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.patientProfileApi.Patient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.patientProfileApi.ListPatientsResponse} returns this
 */
proto.patientProfileApi.ListPatientsResponse.prototype.clearPatientsList = function() {
  return this.setPatientsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.ListPatientHealthRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.ListPatientHealthRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.ListPatientHealthRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.ListPatientHealthRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    patientId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.ListPatientHealthRequest}
 */
proto.patientProfileApi.ListPatientHealthRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.ListPatientHealthRequest;
  return proto.patientProfileApi.ListPatientHealthRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.ListPatientHealthRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.ListPatientHealthRequest}
 */
proto.patientProfileApi.ListPatientHealthRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.ListPatientHealthRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.ListPatientHealthRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.ListPatientHealthRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.ListPatientHealthRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string patient_id = 1;
 * @return {string}
 */
proto.patientProfileApi.ListPatientHealthRequest.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.ListPatientHealthRequest} returns this
 */
proto.patientProfileApi.ListPatientHealthRequest.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.PatientHealth.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.PatientHealth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.PatientHealth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.PatientHealth.toObject = function(includeInstance, msg) {
  var f, obj = {
    patientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    birthDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    motherName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fatherName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pictureFileId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 9, ""),
    cisgender: jspb.Message.getFieldWithDefault(msg, 10, ""),
    civilStatus: jspb.Message.getFieldWithDefault(msg, 11, ""),
    nickname: jspb.Message.getFieldWithDefault(msg, 12, ""),
    organDonor: jspb.Message.getFieldWithDefault(msg, 13, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 14, ""),
    bloodType: jspb.Message.getFieldWithDefault(msg, 15, ""),
    rhFactor: jspb.Message.getFieldWithDefault(msg, 16, ""),
    socialSecurityNumber: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.PatientHealth}
 */
proto.patientProfileApi.PatientHealth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.PatientHealth;
  return proto.patientProfileApi.PatientHealth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.PatientHealth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.PatientHealth}
 */
proto.patientProfileApi.PatientHealth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMotherName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFatherName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureFileId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGender(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCisgender(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCivilStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganDonor(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBloodType(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setRhFactor(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocialSecurityNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.PatientHealth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.PatientHealth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.PatientHealth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.PatientHealth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBirthDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMotherName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFatherName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPictureFileId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCisgender();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCivilStatus();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOrganDonor();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBloodType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getRhFactor();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSocialSecurityNumber();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string patient_id = 1;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string birth_date = 4;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getBirthDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setBirthDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string mother_name = 5;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getMotherName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setMotherName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string father_name = 6;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getFatherName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setFatherName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string picture_file_id = 7;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getPictureFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setPictureFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string picture_url = 8;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string gender = 9;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setGender = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string cisgender = 10;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getCisgender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setCisgender = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string civil_status = 11;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getCivilStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setCivilStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string nickname = 12;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string organ_donor = 13;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getOrganDonor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setOrganDonor = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string occupation = 14;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string blood_type = 15;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getBloodType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setBloodType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string rh_factor = 16;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getRhFactor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setRhFactor = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string social_security_number = 17;
 * @return {string}
 */
proto.patientProfileApi.PatientHealth.prototype.getSocialSecurityNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.PatientHealth} returns this
 */
proto.patientProfileApi.PatientHealth.prototype.setSocialSecurityNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.patientProfileApi.ListPatientHealthResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.patientProfileApi.ListPatientHealthResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.patientProfileApi.ListPatientHealthResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.ListPatientHealthResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patient: (f = msg.getPatient()) && proto.patientProfileApi.PatientHealth.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.patientProfileApi.ListPatientHealthResponse}
 */
proto.patientProfileApi.ListPatientHealthResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.patientProfileApi.ListPatientHealthResponse;
  return proto.patientProfileApi.ListPatientHealthResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.patientProfileApi.ListPatientHealthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.patientProfileApi.ListPatientHealthResponse}
 */
proto.patientProfileApi.ListPatientHealthResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.patientProfileApi.PatientHealth;
      reader.readMessage(value,proto.patientProfileApi.PatientHealth.deserializeBinaryFromReader);
      msg.setPatient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.patientProfileApi.ListPatientHealthResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.patientProfileApi.ListPatientHealthResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.patientProfileApi.ListPatientHealthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.patientProfileApi.ListPatientHealthResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatient();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.patientProfileApi.PatientHealth.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.patientProfileApi.ListPatientHealthResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.patientProfileApi.ListPatientHealthResponse} returns this
 */
proto.patientProfileApi.ListPatientHealthResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.patientProfileApi.ListPatientHealthResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.ListPatientHealthResponse} returns this
 */
proto.patientProfileApi.ListPatientHealthResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.patientProfileApi.ListPatientHealthResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.patientProfileApi.ListPatientHealthResponse} returns this
 */
proto.patientProfileApi.ListPatientHealthResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PatientHealth patient = 4;
 * @return {?proto.patientProfileApi.PatientHealth}
 */
proto.patientProfileApi.ListPatientHealthResponse.prototype.getPatient = function() {
  return /** @type{?proto.patientProfileApi.PatientHealth} */ (
    jspb.Message.getWrapperField(this, proto.patientProfileApi.PatientHealth, 4));
};


/**
 * @param {?proto.patientProfileApi.PatientHealth|undefined} value
 * @return {!proto.patientProfileApi.ListPatientHealthResponse} returns this
*/
proto.patientProfileApi.ListPatientHealthResponse.prototype.setPatient = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.patientProfileApi.ListPatientHealthResponse} returns this
 */
proto.patientProfileApi.ListPatientHealthResponse.prototype.clearPatient = function() {
  return this.setPatient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.patientProfileApi.ListPatientHealthResponse.prototype.hasPatient = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.patientProfileApi);
