<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
        <path d="M12.5284 2.33337C10.6951 2.2417 8.86172 2.97504 7.48672 4.25837C6.20339 5.5417 5.47005 7.28337 5.47005 9.20837L3.82005 11.9584C3.72839 12.1417 3.63672 12.2334 3.63672 12.4167C3.63672 12.5084 3.63672 12.6 3.72839 12.6917L5.01172 16.2667V16.3584C5.47005 17.275 6.38672 17.9167 7.48672 17.9167H8.22005V19.75C8.22005 20.3 8.58672 20.6667 9.13672 20.6667C9.68672 20.6667 10.0534 20.3 10.0534 19.75V17.9167H11.8867C11.9784 17.9167 12.0701 17.9167 12.1617 17.825L15.5534 16.6334V16.725L16.4701 19.9334C16.5617 20.3 16.9284 20.575 17.3867 20.575C17.4784 20.575 17.5701 20.575 17.6617 20.575C18.1201 20.3917 18.3951 19.9334 18.3034 19.475L17.4784 16.5417L19.2201 9.85004C19.2201 9.75837 19.2201 9.6667 19.2201 9.57504V9.48337C19.2201 5.72504 16.2867 2.5167 12.5284 2.33337ZM16.0117 14.6167L12.8034 15.7167V13.0584L16.7451 11.775L16.0117 14.6167ZM17.2951 9.6667C17.2034 9.6667 17.1117 9.6667 17.0201 9.6667L11.7034 11.5H6.20339L7.21172 9.9417C7.30339 9.75837 7.39505 9.57504 7.30339 9.3917V9.20837C7.30339 6.45837 9.59505 4.1667 12.3451 4.1667C12.4367 4.1667 12.4367 4.1667 12.5284 4.1667C15.2784 4.35004 17.4784 6.6417 17.3867 9.48337L17.2951 9.6667Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>