import { PatientAccountProtoServicePromiseClient } from '../grpc/generated/patientAccount_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new PatientAccountProtoServicePromiseClient(apiUrl);

async function login(request) {
    const response = await svc.login(request, {});
    // console.log(response);

    return response.toObject();
}

async function register(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.register(request, metadata, {});

    return response.toObject();
}

async function verifyEmail(request) {
    const response = await svc.verifyEmail(request, {});
    console.log(response);
    return response.toObject();
}

async function sendEmailConfirmation(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.sendEmailConfirmation(request, metadata, {});

    return response.toObject();
}

async function sendPhoneConfirmation(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.sendPhoneConfirmation(request, metadata, {});

    return response.toObject();
}

async function verifyPhone(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.verifyPhone(request, metadata, {});

    return response.toObject();
}

async function updatePhone(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.updatePhone(request, metadata, {});
    return response.toObject();
}

async function verifyPasswordRecoveryCode(request) {
    const response = await svc.verifyPasswordRecoveryCode(request, {});
    return response.toObject();
}

// async function verifyContinueRegister(request) {
//     const response = await svc.verifyContinueRegister(request, {});
//     return response.toObject();
// }

async function verifyContinueRegister(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.verifyContinueRegister(request, metadata, {});
    return response.toObject();
}

async function sendPasswordRecoveryCodeToEmail(request) {
    const response = await svc.sendPasswordRecoveryCodeToEmail(request, {});
    return response.toObject();
}

async function sendPasswordRecoveryCodeToUser(request) {
    const response = await svc.sendPasswordRecoveryCodeToUser(request, {});
    return response.toObject();
}

async function sendEmailOnClose(request) {
    const response = await svc.sendEmailAfterUserCloseBrowser(request, {});
    return response.toObject();
}

async function findUserAccount(request) {
    const response = await svc.findUserAccount(request, {});
    return response.toObject();
}

async function updatePassword(request) {
    const response = await svc.updatePassword(request, {});
    return response.toObject();
}

async function updateEmail(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.updateEmail(request, metadata, {});
    return response.toObject();
}

async function getInfoPatient(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.getInfoPatient(request, metadata, {});
    return response.toObject();
}

async function saveInfo(request) {
    const response = await svc.saveInfoAfterUserCloseBrowserPageOne(request, {});
    return response.toObject();
}

async function sendSMSConfirmEmail(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.sendSMSConfirmEmail(request, metadata, {});
    return response.toObject();
}

async function confirmExchangeEmail(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.confirmExchangeEmail(request, metadata, {});
    return response.toObject();
}

async function verifyToken(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.verifyToken(request, metadata, {});
    return response.toObject();
}

export default {
    login,
    register,
    verifyEmail,
    updateEmail,
    saveInfo,
    getInfoPatient,
    sendSMSConfirmEmail,
    confirmExchangeEmail,
    verifyPasswordRecoveryCode,
    verifyContinueRegister,
    sendPhoneConfirmation,
    sendEmailConfirmation,
    sendPasswordRecoveryCodeToEmail,
    sendPasswordRecoveryCodeToUser,
    findUserAccount,
    sendEmailOnClose,
    verifyPhone,
    updatePhone,
    updatePassword,
    verifyToken,
}