<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M18.167 8.33366C17.8337 7.33366 16.8337 6.66699 15.8337 6.66699C14.417 6.66699 13.3337 7.75033 13.3337 9.16699C13.3337 10.2503 14.0003 11.167 15.0003 11.5003V12.917C15.0003 15.0003 13.3337 16.667 11.2503 16.667C9.16699 16.667 7.50033 15.0003 7.50033 12.917V12.417C9.91699 12.0003 11.667 9.91699 11.667 7.50033V2.50033C11.667 2.00033 11.3337 1.66699 10.8337 1.66699H9.16699C8.66699 1.66699 8.33366 2.00033 8.33366 2.50033C8.33366 3.00033 8.66699 3.33366 9.16699 3.33366H10.0003V7.50033C10.0003 9.33366 8.50033 10.8337 6.66699 10.8337C4.83366 10.8337 3.33366 9.33366 3.33366 7.50033V3.33366H4.16699C4.66699 3.33366 5.00033 3.00033 5.00033 2.50033C5.00033 2.00033 4.66699 1.66699 4.16699 1.66699H2.50033C2.00033 1.66699 1.66699 2.00033 1.66699 2.50033V7.50033C1.66699 9.91699 3.41699 12.0003 5.83366 12.417V12.917C5.83366 15.917 8.25033 18.3337 11.2503 18.3337C14.2503 18.3337 16.667 15.917 16.667 12.917V11.5003C18.0003 11.0837 18.667 9.66699 18.167 8.33366Z" fill="#4F4F4F"/>
        <span> Pesquisar profissional </span>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>