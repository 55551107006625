import { defineStore } from 'pinia';
import { LoginRequest, SendPhoneConfirmationRequest } from '../modules/core/grpc/generated/patientAccount_pb';
import patientAcountService from '../modules/core/services/patientAcountService';
import patientProfileService from '../modules/core/services/patientProfileService';
import router from '../router';
// import { get } from 'core-js/core/dict';

import { LoginHealthPatientThroughPlatform } from "@/actions/shareHealth";

async function fetchTokenSecondary() {
    const resSecondary = await LoginHealthPatientThroughPlatform();

    if(resSecondary?.success){
        const token_secondary = JSON?.parse(resSecondary?.data)?.token;
        if(token_secondary){
            localStorage.setItem("token_secondary", token_secondary);
        }
    }
}

export default defineStore('store', {
    state: () => {
        return {
            token: localStorage.getItem('token') || '',
            userInfo: {},
            screenResponse: false,
            email: localStorage.getItem('email') || '',
            error: '',
            error_data: {},
        }
    },

    getters: {
        isLoggedIn: (state) => !!state.token
    },

    actions: {
        async login(username, password) {
            let request = new LoginRequest();
            request.setUser(username);
            request.setPassword(password);

            const response = await patientAcountService.login(request);

            if (response.success) {
                this.error = '';
                this.error_data = {};
                const data = JSON.parse(response.data);
                localStorage.setItem('token', data?.token);

                await fetchTokenSecondary();                
                
                if (!data.emailConfirmed) {
                    localStorage.setItem('email', data.email);
                    // localStorage.setItem('emailToken', data.emailToken);
                    // router.push('/account/email/confirmation/'+ data.email +'&'+ data.emailToken);
                } else {
                    localStorage.removeItem('email');
                }

                if (!data.phoneConfirmed) {
                    localStorage.setItem('phone', data.phone);
                } else {
                    localStorage.removeItem('phone');
                }

                // get info
                const infoResponse = await patientProfileService.getInfo();
                if (infoResponse.success) {
                    const data = JSON.parse(infoResponse.data);
                    this.userInfo = data;
                }
                
                this.checkScreen(data.screen);
            } else {
                console.log(response);
                this.error = response.errormessage;
                
                if(response.data){
                    this.error_data = JSON.parse(response.data);
                }
            }
        },

        // verifyEmail() {
        //     this.email = localStorage.getItem('email');
        //     this.emailToken = localStorage.getItem('emailToken');
        //     if(this.email) {
        //         router.push('/account/email/confirmation/'+ this.email +'&'+ this.emailToken);
        //     } else {
        //         router.push('/profile/services');
        //     }
        // },

        logout() {
            console.log("logout");
            localStorage.removeItem('token');
            this.token = '';
            this.userInfo = {};
            router.push('/account/login');
        },

        async checkScreen(data) {
            switch(data) {
                case 0: {
                   break;
                }
                case 2: {
                    const request = new SendPhoneConfirmationRequest();
                    request.setPhone(localStorage.getItem('phone'));
                    const response = await patientAcountService.sendPhoneConfirmation(request);

                    if (response.success == true) {
                        router.push('/account/phone/confirmation');
                    }
                    break;
                }
                case 3: {
                    router.push('/account/infos');
                    break;
                }
                case 4: {
                    router.push('/account/address');
                    break;
                }
                case 5: {
                    router.push('/account/upload');
                    break;
                }
                case 6: {
                    {
                        // this.verifyEmail();
                        // router.push('/');
                        if(localStorage.getItem('email')) {
                            // this.screenResponse = true;
                            router.push('/account/email/confirmation');
                        }else {
                            router.push('/');
                        }
                        break;
                    }
                    break;
                }
                case 7:
                case 8:
                {
                    // this.verifyEmail();
                    // router.push('/');
                    if(localStorage.getItem('email')) {
                        // this.screenResponse = true;
                        router.push('/account/email/confirmation');
                    }else {
                        router.push('/');
                    }
                    break;
                }
                default: {
                    console.log('Desculpe, mas algo deu errado para não entrar em nenhum CASE');
                }
            }
        }
    }
});