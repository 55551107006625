import shareHealthService from "@/modules/core/services/shareHealthService"
import shareHealthPb from "@/modules/core/grpc/generated/shareHealth_pb";

export async function LoginHealthPatientThroughPlatform () {
    try {
        const req = new shareHealthPb.EmptyRequest();
        const res = await shareHealthService.loginHealthPatientThroughPlatform(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}
