<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path d="M18.0711 9.66675C17.8501 9.66675 17.6382 9.75455 17.4819 9.91083C17.3256 10.0671 17.2378 10.2791 17.2378 10.5001C17.2477 12.0609 16.713 13.5764 15.7258 14.7855C14.7386 15.9945 13.3607 16.8215 11.8294 17.1239C10.2981 17.4264 8.70928 17.1854 7.33655 16.4424C5.96382 15.6994 4.89313 14.5011 4.3089 13.0536C3.72466 11.6062 3.66345 10.0003 4.13581 8.51264C4.60817 7.02494 5.58452 5.74853 6.89671 4.90323C8.20891 4.05793 9.77479 3.69668 11.3247 3.88167C12.8746 4.06667 14.3114 4.78635 15.3878 5.91675H13.3878C13.1668 5.91675 12.9548 6.00454 12.7985 6.16083C12.6423 6.31711 12.5545 6.52907 12.5545 6.75008C12.5545 6.97109 12.6423 7.18306 12.7985 7.33934C12.9548 7.49562 13.1668 7.58341 13.3878 7.58341H17.1628C17.3838 7.58341 17.5958 7.49562 17.7521 7.33934C17.9083 7.18306 17.9961 6.97109 17.9961 6.75008V3.00008C17.9961 2.77907 17.9083 2.56711 17.7521 2.41083C17.5958 2.25455 17.3838 2.16675 17.1628 2.16675C16.9418 2.16675 16.7298 2.25455 16.5735 2.41083C16.4173 2.56711 16.3295 2.77907 16.3295 3.00008V4.47508C14.9417 3.14846 13.1384 2.34171 11.2245 2.19119C9.31053 2.04067 7.40332 2.55562 5.82523 3.64899C4.24714 4.74236 3.09497 6.34709 2.56349 8.19191C2.032 10.0367 2.1538 12.0085 2.9083 13.7739C3.6628 15.5392 5.00371 16.99 6.70438 17.8808C8.40504 18.7716 10.3611 19.0479 12.242 18.663C14.1229 18.278 15.8131 17.2555 17.0271 15.7681C18.241 14.2808 18.9042 12.4199 18.9045 10.5001C18.9045 10.2791 18.8167 10.0671 18.6604 9.91083C18.5041 9.75455 18.2921 9.66675 18.0711 9.66675Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>