<template>
  <div class="layout-topbar z-4">
    <!-- Logo -->
    <div class="flex align-items-center gap-12 cursor-pointer" @click="redirectToHome">
      <div class="ommed-logo fadein animation-duration-1000" :style="{ opacity: !isOutside && !viewSearchProfession ? '0.3' : '1' }">
        <img src="../../../assets/Logos/ommed_logo.svg" alt="Description" width="200" height="100">
      </div> 
      <div class="ommed-isotipo" :style="{ opacity: !isOutside && !viewSearchProfession ? '0.3' : '1' }">
        <img src="../../../assets/Logos/ommed_isotipo.svg" alt="Description" width="60" height="40">
      </div> 
    </div>
    <!-- Search bar -->
    <div class="search-bar-container fadein animation-duration-1000" ref="target" v-if="!viewSearchProfession">
      <!-- <Button class="btn-toggle" icon="pi pi-bars" @click="toggleMenu" /> -->
      <div class="search-bar">
        <!-- Search Fields -->
        <div class="search-fields">

            <Button 
              class="button-search-professional" 
              type="button" 
              @click="openSearchFilterProfession"
            > <Stethoscope /> <span>Pesquisar profissional</span></Button>

            <!-- Fica dentro do buttona cima -->
            <!-- v-model="searchProfessional"
              @click="openSearch"  -->

            <Button 
              class="button-search-location" 
              type="button" 
              @click="openSearchFilterLocation" 
            > <Location /> <span>Escolher Local</span></Button>

            <!-- Fica dentro do Button acima -->
            <!-- v-model="searchLocation"
              @click="openSearch"  -->

          <!-- Search icon -->
          <div class="search-icon-container">
            <Button class="search-icon" icon="pi pi-search" rounded outlined  @click="searchListAppointments()"/>
          </div>
        </div>
      </div>
      <!-- Mobile Search -->
      <div class="mobile-search fadein animation-duration-500" v-tooltip.left="'Pesquisar profissional'">
        <div class="icon">
          <Stethoscope />
        </div>
      </div>
    </div>

    <!-- Search Appointment components -->
    <div ref="Outside" class="search-appointment" v-if="viewSearchProfession">
      <Shortcuts  v-if="viewSearchProfession" />
      <SearchAppointment 
        class="z-4" 
        v-if="viewSearchProfession" 
        :selectingProfession="selectingProfession"
        @update:selectingProfession="selectingProfession = $event"
      />
    </div>

    <div class="topbar-right fadein animation-duration-1000" :style="{ opacity: !isOutside && !viewSearchProfession ? '0.3' : '1' }">
      <ul class="topbar-menu">

        <div class="chat-notifications-search-actions">
          <!-- Chat -->
          <div class="chat-container">
            <li class="icon-menu">
              <!-- <div class="notification-bg">
                <div class="color-notification" />
              </div> -->
              <div class="icon" @click="toggleActions('chat')">
                <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 7.99997H18V4.99997C18 4.20432 17.6839 3.44126 17.1213 2.87865C16.5587 2.31604 15.7956 1.99997 15 1.99997H5C4.20435 1.99997 3.44129 2.31604 2.87868 2.87865C2.31607 3.44126 2 4.20432 2 4.99997V17C2.00099 17.1974 2.06039 17.3901 2.17072 17.5538C2.28105 17.7175 2.43738 17.8449 2.62 17.92C2.73868 17.9759 2.86882 18.0033 3 18C3.13161 18.0007 3.26207 17.9755 3.38391 17.9257C3.50574 17.876 3.61656 17.8026 3.71 17.71L6.52 14.89H8V16.33C8 17.1256 8.31607 17.8887 8.87868 18.4513C9.44129 19.0139 10.2044 19.33 11 19.33H17.92L20.29 21.71C20.3834 21.8026 20.4943 21.876 20.6161 21.9257C20.7379 21.9755 20.8684 22.0007 21 22C21.1312 22.0033 21.2613 21.9759 21.38 21.92C21.5626 21.8449 21.7189 21.7175 21.8293 21.5538C21.9396 21.3901 21.999 21.1974 22 21V11C22 10.2043 21.6839 9.44126 21.1213 8.87865C20.5587 8.31604 19.7956 7.99997 19 7.99997ZM8 11V12.89H6.11C5.97839 12.8892 5.84793 12.9144 5.72609 12.9642C5.60426 13.014 5.49344 13.0873 5.4 13.18L4 14.59V4.99997C4 4.73475 4.10536 4.4804 4.29289 4.29286C4.48043 4.10533 4.73478 3.99997 5 3.99997H15C15.2652 3.99997 15.5196 4.10533 15.7071 4.29286C15.8946 4.4804 16 4.73475 16 4.99997V7.99997H11C10.2044 7.99997 9.44129 8.31604 8.87868 8.87865C8.31607 9.44126 8 10.2043 8 11ZM20 18.59L19 17.59C18.9074 17.4955 18.7969 17.4203 18.6751 17.3688C18.5532 17.3173 18.4223 17.2905 18.29 17.29H11C10.7348 17.29 10.4804 17.1846 10.2929 16.9971C10.1054 16.8095 10 16.5552 10 16.29V11C10 10.7348 10.1054 10.4804 10.2929 10.2929C10.4804 10.1053 10.7348 9.99997 11 9.99997H19C19.2652 9.99997 19.5196 10.1053 19.7071 10.2929C19.8946 10.4804 20 10.7348 20 11V18.59Z" fill="#6A6A6A"/>
                  <circle opacity="0.5" cx="21.7927" cy="12.1665" r="6" fill="white"/>
                  <circle cx="22" cy="11.9999" r="4" fill="#FF6A33"/>
                </svg>
              </div>
              <Chat v-if="boolActions.chat" @toggleMenu="toggleActions('chat')"/>
            </li>
          </div>

          <!-- Notifications -->
          <div class="notifications-container">
            <li class="icon-menu icon-notification">
              <!-- <div class="notification-bg">
                <div class="color-notification" />
              </div> -->
              <div class="icon" @click="toggleActions('notification')">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.7927 13.18V9.99997C18.7912 8.58309 18.2884 7.21244 17.3733 6.13074C16.4581 5.04905 15.1897 4.32612 13.7927 4.08997V2.99997C13.7927 2.73475 13.6873 2.4804 13.4998 2.29286C13.3122 2.10533 13.0579 1.99997 12.7927 1.99997C12.5274 1.99997 12.2731 2.10533 12.0856 2.29286C11.898 2.4804 11.7927 2.73475 11.7927 2.99997V4.08997C10.3956 4.32612 9.12719 5.04905 8.21205 6.13074C7.29691 7.21244 6.7941 8.58309 6.79266 9.99997V13.18C6.20911 13.3863 5.70366 13.768 5.3456 14.2729C4.98754 14.7778 4.7944 15.381 4.79266 16V18C4.79266 18.2652 4.89802 18.5195 5.08556 18.7071C5.27309 18.8946 5.52745 19 5.79266 19H8.93266C9.16295 19.8473 9.66567 20.5954 10.3633 21.1287C11.0609 21.662 11.9146 21.951 12.7927 21.951C13.6708 21.951 14.5245 21.662 15.2221 21.1287C15.9197 20.5954 16.4224 19.8473 16.6527 19H19.7927C20.0579 19 20.3122 18.8946 20.4998 18.7071C20.6873 18.5195 20.7927 18.2652 20.7927 18V16C20.7909 15.381 20.5978 14.7778 20.2397 14.2729C19.8817 13.768 19.3762 13.3863 18.7927 13.18ZM8.79266 9.99997C8.79266 8.9391 9.21409 7.92169 9.96424 7.17154C10.7144 6.4214 11.7318 5.99997 12.7927 5.99997C13.8535 5.99997 14.8709 6.4214 15.6211 7.17154C16.3712 7.92169 16.7927 8.9391 16.7927 9.99997V13H8.79266V9.99997ZM12.7927 20C12.4436 19.9979 12.1012 19.9045 11.7995 19.729C11.4978 19.5536 11.2472 19.3022 11.0727 19H14.5127C14.3382 19.3022 14.0876 19.5536 13.7858 19.729C13.4841 19.9045 13.1417 19.9979 12.7927 20ZM18.7927 17H6.79266V16C6.79266 15.7348 6.89802 15.4804 7.08556 15.2929C7.27309 15.1053 7.52745 15 7.79266 15H17.7927C18.0579 15 18.3122 15.1053 18.4998 15.2929C18.6873 15.4804 18.7927 15.7348 18.7927 16V17Z" fill="#6A6A6A"/>
                </svg>
              </div>
              <Notification v-if="boolActions.notification" @toggleMenu="toggleActions('notification')" />
            </li>
          </div>
        </div>

        <div>
          <ProfessionalDropdown :name="name" @toggleMenu="toggleActions('profile')" />
          <Profile v-if="boolActions.profile" @toggleMenu="toggleActions('profile')" />
        </div>
      </ul>
    </div>
  </div>
  <div class="full-screen-background z-3" v-if="viewSearchProfession"></div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useMouseInElement, onClickOutside } from '@vueuse/core'
import { useRoute, useRouter } from "vue-router";
import { useMenu } from "@/store/menu.js"

// Components
import ProfessionalDropdown from "../../../components/professional/ProfessionalDropdown.vue";
import professionalProfileService from "../../core/services/professionalProfileService";
import useStore from "../../../store";
import Notification from "./Notification";
import Chat from "./Chat/Chat.vue";
import Profile from "./Profile/Profile.vue";
import Stethoscope from "../../../assets/IconsComponent/Stethoscope.vue"
import Location from "../../../assets/IconsComponent/Location.vue"
import Shortcuts from "./SearchAppointment/Components/ShortCuts.vue"
import SearchAppointment from "./SearchAppointment/SearchAppointment.vue"

// Icons
import SearchMobileIcon from "../../../assets/IconsComponent/MobileSearch.vue"

export default {
  name: "AppTopbar",
  components: { ProfessionalDropdown, Notification, Chat, Profile, Stethoscope, Location, SearchMobileIcon, Shortcuts, SearchAppointment },
  props: {
    topbarNotificationMenuActive: Boolean,
    topbarMessageMenuActive: Boolean,
    topbarUserMenuActive: Boolean,
  },
  emits: ["toggle-menu", "toggleMenu"],
  
  setup(props, { emit }) {
    const menu = useMenu();
    const store = useStore();
    let name = ref("");
    let boolActions = ref({
      notification: false,
      chat: false,
      profile: false,
    });
    const target = ref(null)
    const viewSearchProfession = ref(false);
    const Outside = ref(null)
    const selectingProfession = ref(false);
    const searchProfessional = ref([])

    const router = useRouter();

    const openSearchFilterProfession = () => {
      selectingProfession.value = true;
      viewSearchProfession.value = true;
    }

    const openSearchFilterLocation = () => {
      selectingProfession.value = false;
      viewSearchProfession.value = true;
    }

    const toggleMenu = (event) => {
      emit("toggle-menu", event.target.value);
    };


    // Variable to control if the toggleActions function can be called
    let canToggle = true;
    
    const toggleActions = (name) => {
      // Check if the function can be called
      if (!canToggle) return;
      
      // Toggle the state of the specified action
      const oldValue = boolActions.value[name];
      boolActions.value = {
        notification: false,
        chat: false,
        profile: false,
      };
      boolActions.value[name] = !oldValue;

      // Prevent the function from being called again
      canToggle = false;
      setTimeout(() => {
        canToggle = true;
      }, 500);
    };

    const isAction = (name) => {
      const bool = boolActions.value;
      if (bool.notification || bool.chat) {
        if (bool[name]) {
          return "";
        } else {
          return "inative";
        }
      }
      return "";
    };

    const searchListAppointments = () => {
      let routePath = '/search/';
      
      window.open(router.resolve({ path: routePath += `${[]}&${[]}` }).href, '_blank')
    };

    const redirectToHome = () => {
      menu.changeRoute("/");
    };

    // Controls if mouse is hovering the search-bar class, then use it to apply :style on another classes.
    const { x, y, isOutside } = useMouseInElement(target)

    // Close search when clicked outside of search area
    onClickOutside(Outside, (event) => {
      // console.log(event)
      viewSearchProfession.value = false
    })

    onMounted(async () => {
      const infoResponse = await professionalProfileService.getInfo();
      if (infoResponse.success) {
        const data = JSON.parse(infoResponse.data);
        name.value = data.firstName;
        // console.log(name.value);
      }
    });

    return {
      toggleMenu,
      name,
      items: [],
      searchBar: "",
      visibleRight: false,
      professional: {
        name: store.userInfo.firstName + " " + store.userInfo.lastName,
      },
      boolActions,
      toggleActions,
      isAction,
      target,
      x,
      y,
      isOutside,
      viewSearchProfession,
      openSearchFilterProfession,
      openSearchFilterLocation,
      Outside,
      selectingProfession,
      searchListAppointments,
      redirectToHome,
    };
  },
};
</script>

<style lang="scss" scoped>

.full-screen-background {
  /* Additional styles for full-screen background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(40.774227142333984px); /* Apply background blur */
  background-color: rgba(245, 245, 245, 0.50);
}
.layout-topbar {
  border-bottom: 0;
  position: relative;
  padding: 0px 100px;
  width: 100%;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  background: linear-gradient(180deg, rgba(248, 248, 248, 1) 0%, #F8F8F8 25%, #F2F2F2 75%, rgba(242, 242, 242, 1) 100%);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px; 

  .tag-plus {
    color: var(--White, #FFF);
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    border-radius: 8px;
    background: var(--Gray-3, #828282);
    padding: 0px 8px;
  }

  // backdrop-filter: blur(40.774227142333984px); /* Apply background blur */
  // backdrop-filter: blur(40.774227142333984px);

  // Mudança de padding
  @media (max-width: 1360px) {
    padding: 0px 20px;
  };

  // Para mostrar o isotipo
  @media (max-width: 1151px) {
    .ommed-logo {
      transition: opacity 0.9s;
      display: none;
    }

    .ommed-isotipo {
      transition: opacity 0.9s;
      display: block;
    }
  };

  // Para mostrar o Logo
  @media (min-width: 1152px) {
    .ommed-logo {
      transition: opacity 0.9s;
      display: block;
    }

    .ommed-isotipo {
      transition: opacity 0.9s;
      display: none;
    }
  };

  .search-bar-container {
    .search-bar {
      display: flex;
      max-height: 50px;
      min-width: 250px;
      max-width: 450px;
      padding: 5px;
      justify-content: flex-end;
      align-items: center;
      gap: 57px;

      border-radius: 128.571px;
      background: var(--white, #FFF);
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.08);
        transition: transform 0.3s ease-in-out;
      }

      // Remove search-bar when below 774px
      @media (max-width: 774px) {
        display: none;  
      };
    }

    .mobile-search {
      display: flex;
      align-items: center;
      margin-right: 10px;

      height: 40px;
      padding: 4px 18px 5px 18px;
      gap: 16px;

      border-radius: 128.571px;
      background: var(--white, #FFF);
      box-shadow: 0px 3px 6px 0px rgba(61, 71, 102, 0.06), 0px 8px 24px 0px rgba(61, 71, 102, 0.08);

      cursor: pointer;

      // Para mostrar o Search Bar
      @media (min-width: 774px) {
        display: none;  
      };
    }
  }

  .search-appointment {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
  }

  .search-fields {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.btn-toggle {
  height: 40px !important;
  display: none !important;
  width: 40px !important;
  padding-left: 3px !important;

  @media (max-width: 830px) {
    display: block !important;
    text-align: center !important;
  }
}

.topbar-menu li {
  position: relative;
  margin-left: 0px !important;
}

.notification-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 7px;
  right: 7px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #f1f5f8;
  z-index: 9999999;
  cursor: pointer;

  .color-notification {
    background-color: #ff4500;
    border-radius: 100%;
    width: 8px;
    height: 8px;
  }
}

.topbar-menu {
  display: flex;
  align-items: center;

  .chat-notifications-search-actions {
    display: flex;
    padding: 0 10px;
    align-items: center;
    // box-shadow: 0px 2px 5px 0px rgba(61, 71, 102, 0.06), 0px 8px 24px 0px rgba(61, 71, 102, 0.08);
    // gap: 14px;
    margin-right: 10px;

    border-radius: 128.571px;
    background: var(--white, #FFF);
  }

  .icon-menu {
    // padding: 18px;
    height: 40px;

    .icon {
      cursor: pointer;

      // height: 40px;
      padding: 4px;
      border-radius: 100%;

      &:hover {
        transform: scale(1.1); /* Aumenta a escala em 10% (pode ajustar o valor conforme desejado) */
        transition: transform 0.3s ease-in-out;
        // border-radius: 100px;
        background: #F3F3F3;
      }
    }

    .inative {
      filter: invert(92%) sepia(0%) saturate(5536%) hue-rotate(313deg) brightness(133%) contrast(76%);
    }
  }

  li {
    display: flex;
    align-items: center !important;

    a {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      height: max-content;
    }
  }
}

.btn-qrcode {
  display: flex;
  gap: 8px;
  padding: 10px 17px;
  align-items: center;
  background: #bdbdbd;
  border-radius: 41.5px;
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;

  @media (max-width: 1360px) {
    .qrcode-text {
      display: none;
    }
  }

  @media (max-width: 660px) {
      display: none;
  }
}

.topbar-right {
  position: relative;
  transition: opacity 0.9s;
}

.inative {
  filter: invert(92%) sepia(0%) saturate(5536%) hue-rotate(313deg) brightness(133%) contrast(76%);
};

:deep() {
  .button-search-professional {
    background: white;
    border-color: white;
    box-shadow: none;

    color: var(--gray-3, #828282);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.571px;
  
    border-radius: 128.571px;
    gap: 8px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    &:disabled {
      background: #F5F5F5 !important;
      border-color: #F5F5F5;
      box-shadow: none;
      color: #BDBDBD !important;
      border-radius: 128.571px;
      gap: 8px;
      min-width: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: not-allowed !important;
      
    }
  
    &:hover {
      background: #FAFAFA !important;
      border-color: #FAFAFA;
      box-shadow: none;
      color: #828282 !important;
      text-shadow: #828282 0 0 0.1rem;
      
    }
  }

  .button-search-location {
    background: white;
    border-color: white;
    box-shadow: none;
  
    color: var(--gray-3, #828282);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.571px;

    border-radius: 128.571px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    &:disabled {
      background: #F5F5F5 !important;
      border-color: #F5F5F5;
      box-shadow: none;
      color: #BDBDBD !important;
      border-radius: 128.571px;
      gap: 8px;
      min-width: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: not-allowed !important;
      
    }
  
    &:hover {
      background: #FAFAFA !important;
      border-color: #FAFAFA;
      box-shadow: none;
      color: #828282 !important;
      text-shadow: #828282 0 0 0.1rem;
      
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

  .search-icon {
    border-color: white;
    box-shadow: none;
    color: var(--gray-3, #828282);

    width: 60px;
    height: 40px;
    flex-shrink: 0;

    border-radius: 100px;
    background: var(--white, #FFF);

    /* Shadow Cards */
    box-shadow: 0px 3px 6px 0px rgba(61, 71, 102, 0.06), 0px 8px 24px 0px rgba(61, 71, 102, 0.08);

    display: flex;
    align-items: center;
    justify-content: center;
  
    &:disabled {
      background: #F5F5F5 !important;
      border-color: #F5F5F5;
      box-shadow: none;
      color: #BDBDBD !important;
      border-radius: 128.571px;
      gap: 8px;
      min-width: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: not-allowed !important;
      
    }
  
    &:hover {
      background: #FAFAFA !important;
      border-color: #FAFAFA;
      box-shadow: none;
      color: #828282 !important;
      text-shadow: #828282 0 0 0.1rem;
      
    }
  }
}
</style>
