import patientAccountService from "../modules/core/services/patientAcountService";
import { 
    EmptyRequest,
} from "../modules/core/grpc/generated/patientAccount_pb";

export const VerifyToken = async () => {
    try {
        const req = new EmptyRequest();

        const res = await patientAccountService.verifyToken(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

export default {
    VerifyToken,
}