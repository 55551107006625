<template>
  <!-- <div v-if="routedComponent">
      <router-view />
  </div> -->
  <!-- <div :class="containerClass" :data-theme="colorScheme" @click="onDocumentClick($event)">
      <div class="layout-content-wrapper">
          <AppTopBar
              :topbarNotificationMenuActive="topbarNotificationMenuActive"
              :topbarMessageMenuActive="topbarMessageMenuActive"
              @menu-button-click="onMenuButtonClick"
              @search-click="toggleSearch"
              @topbar-notification="onTopbarNotificationMenuButtonClick"
              @message-notification="onTopbarMessageMenuButtonClick"
              @topbar-user-menu="onTopbarUserMenuButtonClick"
              @right-menu-click="onRightMenuButtonClick"
              @right-menubutton-click="onRightMenuButtonClick"
          ></AppTopBar>
          <div class="layout-content">
              <router-view />
          </div>
      </div>

      <AppMenu
          :model="menu"
          :layoutMode="layoutMode"
          :active="menuActive"
          :mobileMenuActive="staticMenuMobileActive"
          @menu-click="onMenuClick"
          @menuitem-click="onMenuItemClick"
          @root-menuitem-click="onRootMenuItemClick"
      ></AppMenu>

      <AppRightMenu :rightMenuActive="rightMenuActive" @right-menu-click="onRightMenuClick"></AppRightMenu>

      <AppSearch :searchActive="searchActive" @search-click="onSearchClick" @search-hide="onSearchHide" />

      <div class="layout-mask modal-in"></div>
  </div> -->
  <router-view />
</template>

<script>
import { ref, computed } from "vue";
import EventBus from '../utils/event-bus'
// import AppTopBar from './AppTopbar'
// import AppSidebar from './AppSidebar.vue'
// import AppFooter from "./AppFooter"; 
// import AppConfig from './AppConfig'
// import AppMenu from './AppMenu'
// import AppSearch from './AppSearch'
// import AppRightMenu from './AppRightMenu'
export default {
  name: 'App',
  /* components: {
    AppTopBar,
    AppFooter,
    AppConfig,
    AppSidebar,
    AppMenu,
    AppSearch,
    AppRightMenu,
  }, */
  setup() {
    const menuActive = ref(false);
    const layoutMode = 'static';
    const colorScheme = 'light';
    const menuTheme = 'layout-sidebar-light';
    let overlayMenuActive = ref(false);
    let staticMenuDesktopInactive = ref(false);
    let staticMenuMobileActive = ref(false);
    let menuClick = ref(false);
    let messageMenuClick = ref(false);
    let searchActive = ref(false);
    let searchClick = ref(false);
    let userMenuClick = ref(false);
    let topbarUserMenuActive = ref(false);
    let notificationMenuClick = ref(false);
    let topbarNotificationMenuActive = ref(false);
    let topbarMessageMenuActive = ref(false);
    let rightMenuClick = ref(false);
    let rightMenuActive = ref(false);
    let configActive = ref(false);
    let configClick = ref(false);
    const menu = [
      {
        items: [
          { label: 'Dashboard', icon: 'dashboard', to: '/' },
          {
            label: 'Meus Serviços',
            icon: 'briefcase-medical',
            items: [
                { label: 'Serviços oferecidos', to: '/profile/services' },
                { label: 'Locais', to: '/places' },
                { label: 'Horários', to: '/professional/schedules' },
            ],
          },
          {
            label: 'Agenda',
            icon: 'pi pi-fw pi-calendar',
            items: [
                { label: 'Diária', to: '/professional/schedule?view=day' },
                { label: 'Semanal', to: '/professional/schedule?view=week' },
                { label: 'Mensal', to: '/professional/schedule?view=month' },
            ],
          },
          { label: 'Atendimentos', icon: 'pi pi-fw pi-user', to: '/attendances' },
          { label: 'Financeiro', icon: 'recipient', to: '/finances' },
          { label: 'Marketing', icon: 'marketing', to: '/marketing' },
        ],
      },
    ];

    const onDocumentClick = () => {
      if(searchClick.value && searchActive.value) {
        onSearchHide();
      }

      if(!userMenuClick.value) {
        topbarUserMenuActive.value = false;
      }

      if(!notificationMenuClick.value) {
        topbarNotificationMenuActive.value = false;
      }

      if(!messageMenuClick.value) {
        topbarMessageMenuActive.value = false;
      }

      if(!rightMenuClick.value) {
        rightMenuActive.value = false;
      }

      if(!menuClick.value) {
        if(isSlim()) {
          EventBus.emit('reset-active-index');
          menuActive.value = false;
        }

        if(overlayMenuActive.value || staticMenuMobileActive.value) {
          hideOverlayMenu();
        }

        unblockBodyScroll();
      }

      if(configActive.value && !configClick.value) {
        configActive = false;
      }

      searchClick.value = false;
      configClick.value = false
      userMenuClick.value = false
      rightMenuClick.value = false
      notificationMenuClick.value = false
      menuClick.value = false
    }

    const onMenuClick = () => {
      menuClick.value = true;
    }

    const onMenuButtonClick = (event) => {
      menuClick.value = true
      topbarUserMenuActive.value = false
      topbarNotificationMenuActive.value = false
      topbarMessageMenuActive.value = false
      rightMenuActive.value = false

      if (isOverlay()) {
          overlayMenuActive.value = !overlayMenuActive.value
      }

      if (isDesktop()) {
          staticMenuDesktopInactive.value = !staticMenuDesktopInactive.value
      } else {
          staticMenuMobileActive.value = !staticMenuMobileActive.value
          if (staticMenuMobileActive.value) {
            blockBodyScroll()
          } else {
            unblockBodyScroll()
          }
      }

      event.preventDefault();
    }

    const onMenuItemClick = (event) => {
      if(!event.item.items) {
        EventBus.emit('reset-active-index');
        hideOverlayMenu();
      }
      if(!event.item.items && isSlim()) {
        menuActive.value = false;
      }
    }

    const onRootMenuItemClick = () => {
      menuActive.value = !menuActive.value;
    }

    const onTopbarUserMenuButtonClick = (event) => {
      userMenuClick.value = true;
      topbarUserMenuActive.value = !topbarUserMenuActive.value;

      hideOverlayMenu();
      event.preventDefault();
    }

    const onTopbarNotificationMenuButtonClick = (event) => {
      notificationMenuClick.value = true;
      topbarNotificationMenuActive.value = !topbarNotificationMenuActive.value;

      hideOverlayMenu();
      event.preventDefault();
    }

    const toggleSearch = () => {
      searchActive.value = searchActive;
      searchClick.value = true;
    }

    const onSearchClick = () => {
      searchClick.value = true;
    }

    const onSearchHide = () => {
      searchActive.value = false;
      searchClick.value = false;
    }

    const onRightMenuClick = () => {
      rightMenuClick.value = true;
    }

    const onRightMenuButtonClick = (event) => {
      rightMenuClick.value = true;
      rightMenuActive.value = !rightMenuActive.value;

      hideOverlayMenu();
      event.preventDefault();
    }

    const onConfigClick = () => {
      configClick.value = true;
    }

    const onConfigButtonClick = () => {
      configActive.value = !configActive.value;
      configClick.value = true;
    }

    const hideOverlayMenu = () => {
      overlayMenuActive.value = false;
      staticMenuMobileActive.value = false;

      unblockBodyScroll();
    }

    const blockBodyScroll = () => {
      if(document.body.classList) {
        document.body.classList.add('blocked-scroll');
      } else {
        document.body.className += ' blocked-scroll';
      }
    }

    const unblockBodyScroll = () => {
      if (document.body.classList) {
        document.body.classList.remove('blocked-scroll')
      } else {
        document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
      }
    }

    const isSlim = () => {
      return layoutMode === 'slim'
    }

    const isOverlay = () => {
      return layoutMode === 'overlay'
    }

    const isDesktop = () => {
      return window.innerWidth > 991;
    }

    return {
      onDocumentClick, onConfigButtonClick, onConfigClick,
      onRightMenuButtonClick, onRightMenuClick, toggleSearch, 
      onTopbarNotificationMenuButtonClick, onTopbarUserMenuButtonClick, 
      onRootMenuItemClick, onMenuItemClick, onMenuButtonClick, onMenuClick,
      menu, onSearchClick,
      // routedComponent: computed(() => {
      //   return (
      //       $route.path === '/account/register' ||
      //       $route.path === '/account/sms/confirmation' ||
      //       $route.path === '/account/email/confirmation' ||
      //       $route.path === '/account/infos' ||
      //       $route.path === '/account/records'
      //   )
      // }),
      containerClass: computed(() => {
        return [
          'layout-wrapper',
          {
              'layout-overlay': layoutMode === 'overlay',
              'layout-static': layoutMode === 'static',
              'layout-slim': layoutMode === 'slim',
              'layout-sidebar-dim': colorScheme === 'dim',
              'layout-sidebar-dark': colorScheme === 'dark',
              'layout-overlay-active': overlayMenuActive.value,
              'layout-mobile-active': staticMenuMobileActive.value,
              'layout-static-inactive': staticMenuDesktopInactive.value && layoutMode === 'static',
              // 'p-input-filled': $appState.inputStyle === 'filled',
              // 'p-ripple-disabled': !$primevue.ripple,
          },
          colorScheme === 'light' ? menuTheme : '',
        ]
      })
    }
  }
}
</script>

<style scoped>
@import '../css/App.scss';
@import '../css/Flex.scss';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');
</style>
