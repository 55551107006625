import professionalProfileService from "../services/professionalProfileService"
import professionalProfilePb from "../grpc/generated/professionalProfile_pb"
import { EmptyRequest } from "../grpc/generated/appointment_pb"

const SearchAppointment = async (searchParams) => {
    try {
        const req = new professionalProfilePb.SearchAppointmentRequest(searchParams);    
        // console.log("Oque está chegando...", searchParams.first_option)
        console.log("Oque está chegando...", searchParams.second_option)
        req.setFirstFilter(searchParams.first_filter)
        req.setSecondFilter(searchParams.second_filter)

        searchParams?.first_option.forEach(option => {
            if (searchParams.first_option.length === 1) {
                req.addFirstOption(option);
            } else {
                req.addFirstOption(option);
            }
        });

        searchParams?.second_option.forEach(option => {
            if (searchParams.second_option.length === 1) {
                req.addSecondOption(option);
            } else {
                req.addSecondOption(option);
            }
        });
        // req.addFirstOption(...searchParams?.first_option);
        // req.addSecondOption(...searchParams?.second_option)

        const res = await professionalProfileService.searchAppointment(req);
        console.log("res Action", res)
        // console.log("res action", res)
        return res;
        
    } catch (error) {
        console.log(error);
        return error;
    }
}

const ListProfessionalAvailable = async (paramsListing) => {
    try {
        const req = new professionalProfilePb.ListProfessionalAvailableRequest(paramsListing);    
        // console.log("Oque está chegando...", paramsListing)
        req.setProfessionalId(paramsListing?.professionalId)
        req.setPlaceId(paramsListing?.placeId)
        req.setServiceId(paramsListing?.serviceId)
        req.setServiceTypeId(paramsListing?.modalityId)

        // Em standby até o backend liberar a localização
        let placePacient = new proto.professionalProfileApi.LocationPatient();
        placePacient.setLatitude(paramsListing.location.latitude)
        placePacient.setLongitude(paramsListing.location.longitude)

        req.setLocation(placePacient)

        const res = await professionalProfileService.listProfessionalAvailable(req);
        console.log("res Action", res)
        return res;
        
    } catch (error) {
        console.log(error);
        return error;
    }
}

export default {
    SearchAppointment,
    ListProfessionalAvailable,
}