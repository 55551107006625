// source: appointment.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.appointmentApi.AddAppointmentPatientRequest', null, global);
goog.exportSymbol('proto.appointmentApi.AddAppointmentPatientResponse', null, global);
goog.exportSymbol('proto.appointmentApi.AddAppointmentProfessionalRequest', null, global);
goog.exportSymbol('proto.appointmentApi.AddAppointmentProfessionalResponse', null, global);
goog.exportSymbol('proto.appointmentApi.AddPatientWaitingListRequest', null, global);
goog.exportSymbol('proto.appointmentApi.AddPatientWaitingListResponse', null, global);
goog.exportSymbol('proto.appointmentApi.AddStatusRequest', null, global);
goog.exportSymbol('proto.appointmentApi.AddStatusResponse', null, global);
goog.exportSymbol('proto.appointmentApi.AppointmentLP', null, global);
goog.exportSymbol('proto.appointmentApi.AppointmentList', null, global);
goog.exportSymbol('proto.appointmentApi.AppointmentListPrevious', null, global);
goog.exportSymbol('proto.appointmentApi.AppointmentPatientProto', null, global);
goog.exportSymbol('proto.appointmentApi.AppointmentReschedule', null, global);
goog.exportSymbol('proto.appointmentApi.CancelAppointmentProfessionalRequest', null, global);
goog.exportSymbol('proto.appointmentApi.CancelAppointmentProfessionalResponse', null, global);
goog.exportSymbol('proto.appointmentApi.ConferCheckInRequest', null, global);
goog.exportSymbol('proto.appointmentApi.ConferCheckInResponse', null, global);
goog.exportSymbol('proto.appointmentApi.DateInfo', null, global);
goog.exportSymbol('proto.appointmentApi.DateList', null, global);
goog.exportSymbol('proto.appointmentApi.DateListAppointment', null, global);
goog.exportSymbol('proto.appointmentApi.DateListQueuePreviuos', null, global);
goog.exportSymbol('proto.appointmentApi.EmptyRequest', null, global);
goog.exportSymbol('proto.appointmentApi.FutureMedicalCareRequest', null, global);
goog.exportSymbol('proto.appointmentApi.FutureMedicalCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.GetStartPatientCareRequest', null, global);
goog.exportSymbol('proto.appointmentApi.GetStartPatientCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.Holidays', null, global);
goog.exportSymbol('proto.appointmentApi.HomeListPatientFutureAppointment', null, global);
goog.exportSymbol('proto.appointmentApi.HomeListProfessionalFutureAppointment', null, global);
goog.exportSymbol('proto.appointmentApi.InfoDatesProto', null, global);
goog.exportSymbol('proto.appointmentApi.InfoPatientAppointmentProto', null, global);
goog.exportSymbol('proto.appointmentApi.ListAppointmentData', null, global);
goog.exportSymbol('proto.appointmentApi.ListAppointmentsRequest', null, global);
goog.exportSymbol('proto.appointmentApi.ListAppointmentsResponse', null, global);
goog.exportSymbol('proto.appointmentApi.ListDaysConflicts', null, global);
goog.exportSymbol('proto.appointmentApi.ListFutureMedicalCareRequest', null, global);
goog.exportSymbol('proto.appointmentApi.ListFutureMedicalCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.ListMedicalCareRequest', null, global);
goog.exportSymbol('proto.appointmentApi.ListMedicalCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.ListNextMedicalCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.ListPatientAppointmentDecisionRequest', null, global);
goog.exportSymbol('proto.appointmentApi.ListPatientAppointmentDecisionResponse', null, global);
goog.exportSymbol('proto.appointmentApi.ListPatientAppointmentProto', null, global);
goog.exportSymbol('proto.appointmentApi.ListPatientAppointmentsRequest', null, global);
goog.exportSymbol('proto.appointmentApi.ListPatientAppointmentsResponse', null, global);
goog.exportSymbol('proto.appointmentApi.ListPatientFutureAppointmentsResponse', null, global);
goog.exportSymbol('proto.appointmentApi.ListPatientsRequest', null, global);
goog.exportSymbol('proto.appointmentApi.ListPatientsResponse', null, global);
goog.exportSymbol('proto.appointmentApi.ListPreviousMedicalCareRequest', null, global);
goog.exportSymbol('proto.appointmentApi.ListPreviousMedicalCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.ListWaitingListResponse', null, global);
goog.exportSymbol('proto.appointmentApi.MonthDateList', null, global);
goog.exportSymbol('proto.appointmentApi.MonthlyScheduleRequest', null, global);
goog.exportSymbol('proto.appointmentApi.MonthlyScheduleResponse', null, global);
goog.exportSymbol('proto.appointmentApi.Patient', null, global);
goog.exportSymbol('proto.appointmentApi.PatientActionReschedulingRequest', null, global);
goog.exportSymbol('proto.appointmentApi.PatientActionReschedulingResponse', null, global);
goog.exportSymbol('proto.appointmentApi.PatientAppointmentDecisionRequest', null, global);
goog.exportSymbol('proto.appointmentApi.PatientAppointmentDecisionResponse', null, global);
goog.exportSymbol('proto.appointmentApi.PatientCheckInRequest', null, global);
goog.exportSymbol('proto.appointmentApi.PatientCheckInResponse', null, global);
goog.exportSymbol('proto.appointmentApi.PatientInfo', null, global);
goog.exportSymbol('proto.appointmentApi.PatientProto', null, global);
goog.exportSymbol('proto.appointmentApi.PatientReschedule', null, global);
goog.exportSymbol('proto.appointmentApi.PatientReschedulingDecisionRequest', null, global);
goog.exportSymbol('proto.appointmentApi.PatientReschedulingDecisionResponse', null, global);
goog.exportSymbol('proto.appointmentApi.PatientSchedule', null, global);
goog.exportSymbol('proto.appointmentApi.PreviousMedicalCareRequest', null, global);
goog.exportSymbol('proto.appointmentApi.PreviousMedicalCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.ProfessionalCheckInRequest', null, global);
goog.exportSymbol('proto.appointmentApi.ProfessionalCheckInResponse', null, global);
goog.exportSymbol('proto.appointmentApi.Queue', null, global);
goog.exportSymbol('proto.appointmentApi.QueueData', null, global);
goog.exportSymbol('proto.appointmentApi.QueueListProto', null, global);
goog.exportSymbol('proto.appointmentApi.QueueProto', null, global);
goog.exportSymbol('proto.appointmentApi.RescheduleAppointmentProfessionalRequest', null, global);
goog.exportSymbol('proto.appointmentApi.RescheduleAppointmentProfessionalResponse', null, global);
goog.exportSymbol('proto.appointmentApi.RescheduleRequest', null, global);
goog.exportSymbol('proto.appointmentApi.RescheduleResponse', null, global);
goog.exportSymbol('proto.appointmentApi.SearchCpfRequest', null, global);
goog.exportSymbol('proto.appointmentApi.SearchCpfResponse', null, global);
goog.exportSymbol('proto.appointmentApi.SearchEmailRequest', null, global);
goog.exportSymbol('proto.appointmentApi.SearchEmailResponse', null, global);
goog.exportSymbol('proto.appointmentApi.SearchListFutureMedicalCareRequest', null, global);
goog.exportSymbol('proto.appointmentApi.SearchListFutureMedicalCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.SearchListPatientsRequest', null, global);
goog.exportSymbol('proto.appointmentApi.SearchListPatientsResponse', null, global);
goog.exportSymbol('proto.appointmentApi.SearchListPreviousMedicalCareRequest', null, global);
goog.exportSymbol('proto.appointmentApi.SearchListPreviousMedicalCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.SearchTodayMedicalCareRequest', null, global);
goog.exportSymbol('proto.appointmentApi.SearchTodayMedicalCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.StartPatientCareRequest', null, global);
goog.exportSymbol('proto.appointmentApi.StartPatientCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.StopPatientCareRequest', null, global);
goog.exportSymbol('proto.appointmentApi.StopPatientCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.TodayMedicalCareRequest', null, global);
goog.exportSymbol('proto.appointmentApi.TodayMedicalCareResponse', null, global);
goog.exportSymbol('proto.appointmentApi.UpdateOrderQueueRequest', null, global);
goog.exportSymbol('proto.appointmentApi.UpdateOrderQueueResponse', null, global);
goog.exportSymbol('proto.appointmentApi.UpdateQueueRequest', null, global);
goog.exportSymbol('proto.appointmentApi.UpdateQueueResponse', null, global);
goog.exportSymbol('proto.appointmentApi.VerifyConflictRequest', null, global);
goog.exportSymbol('proto.appointmentApi.VerifyConflictResponse', null, global);
goog.exportSymbol('proto.appointmentApi.VerifyHolidayRequest', null, global);
goog.exportSymbol('proto.appointmentApi.VerifyHolidayResponse', null, global);
goog.exportSymbol('proto.appointmentApi.VerifyQueueResponse', null, global);
goog.exportSymbol('proto.appointmentApi.VerifyReschedulingRequest', null, global);
goog.exportSymbol('proto.appointmentApi.VerifyReschedulingResponse', null, global);
goog.exportSymbol('proto.appointmentApi.VerifyScheduleRegistryRequest', null, global);
goog.exportSymbol('proto.appointmentApi.VerifyScheduleRegistryResponse', null, global);
goog.exportSymbol('proto.appointmentApi.WaitingListProto', null, global);
goog.exportSymbol('proto.appointmentApi.WeekAppointmentList', null, global);
goog.exportSymbol('proto.appointmentApi.WeekDateList', null, global);
goog.exportSymbol('proto.appointmentApi.WeekPlaceList', null, global);
goog.exportSymbol('proto.appointmentApi.WeeklyScheduleRequest', null, global);
goog.exportSymbol('proto.appointmentApi.WeeklyScheduleResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListAppointmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.ListAppointmentsRequest.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.ListAppointmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListAppointmentsRequest.displayName = 'proto.appointmentApi.ListAppointmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListNextMedicalCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.ListNextMedicalCareResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.ListNextMedicalCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListNextMedicalCareResponse.displayName = 'proto.appointmentApi.ListNextMedicalCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.HomeListProfessionalFutureAppointment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.HomeListProfessionalFutureAppointment.displayName = 'proto.appointmentApi.HomeListProfessionalFutureAppointment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.ListPatientFutureAppointmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.ListPatientFutureAppointmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListPatientFutureAppointmentsResponse.displayName = 'proto.appointmentApi.ListPatientFutureAppointmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.HomeListPatientFutureAppointment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.HomeListPatientFutureAppointment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.HomeListPatientFutureAppointment.displayName = 'proto.appointmentApi.HomeListPatientFutureAppointment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListDaysConflicts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.ListDaysConflicts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListDaysConflicts.displayName = 'proto.appointmentApi.ListDaysConflicts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.GetStartPatientCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.GetStartPatientCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.GetStartPatientCareRequest.displayName = 'proto.appointmentApi.GetStartPatientCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.GetStartPatientCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.GetStartPatientCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.GetStartPatientCareResponse.displayName = 'proto.appointmentApi.GetStartPatientCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.QueueData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.QueueData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.QueueData.displayName = 'proto.appointmentApi.QueueData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListMedicalCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.ListMedicalCareRequest.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.ListMedicalCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListMedicalCareRequest.displayName = 'proto.appointmentApi.ListMedicalCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListMedicalCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.ListMedicalCareResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.ListMedicalCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListMedicalCareResponse.displayName = 'proto.appointmentApi.ListMedicalCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.InfoDatesProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.InfoDatesProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.InfoDatesProto.displayName = 'proto.appointmentApi.InfoDatesProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.DateInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.DateInfo.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.DateInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.DateInfo.displayName = 'proto.appointmentApi.DateInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AppointmentLP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.AppointmentLP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AppointmentLP.displayName = 'proto.appointmentApi.AppointmentLP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.QueueProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.QueueProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.QueueProto.displayName = 'proto.appointmentApi.QueueProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListPatientAppointmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.ListPatientAppointmentsRequest.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.ListPatientAppointmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListPatientAppointmentsRequest.displayName = 'proto.appointmentApi.ListPatientAppointmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListPatientAppointmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.ListPatientAppointmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListPatientAppointmentsResponse.displayName = 'proto.appointmentApi.ListPatientAppointmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.InfoPatientAppointmentProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.InfoPatientAppointmentProto.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.InfoPatientAppointmentProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.InfoPatientAppointmentProto.displayName = 'proto.appointmentApi.InfoPatientAppointmentProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListPatientAppointmentProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.ListPatientAppointmentProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListPatientAppointmentProto.displayName = 'proto.appointmentApi.ListPatientAppointmentProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AddStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.AddStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AddStatusRequest.displayName = 'proto.appointmentApi.AddStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AddStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.AddStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AddStatusResponse.displayName = 'proto.appointmentApi.AddStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AddAppointmentPatientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.AddAppointmentPatientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AddAppointmentPatientRequest.displayName = 'proto.appointmentApi.AddAppointmentPatientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AddAppointmentPatientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.AddAppointmentPatientResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.AddAppointmentPatientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AddAppointmentPatientResponse.displayName = 'proto.appointmentApi.AddAppointmentPatientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.EmptyRequest.displayName = 'proto.appointmentApi.EmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListAppointmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.ListAppointmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.ListAppointmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListAppointmentsResponse.displayName = 'proto.appointmentApi.ListAppointmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.DateList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.DateList.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.DateList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.DateList.displayName = 'proto.appointmentApi.DateList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.DateListAppointment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.DateListAppointment.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.DateListAppointment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.DateListAppointment.displayName = 'proto.appointmentApi.DateListAppointment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AppointmentList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.AppointmentList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AppointmentList.displayName = 'proto.appointmentApi.AppointmentList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AppointmentListPrevious = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.AppointmentListPrevious, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AppointmentListPrevious.displayName = 'proto.appointmentApi.AppointmentListPrevious';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.QueueListProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.QueueListProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.QueueListProto.displayName = 'proto.appointmentApi.QueueListProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.SearchCpfRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.SearchCpfRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.SearchCpfRequest.displayName = 'proto.appointmentApi.SearchCpfRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.SearchCpfResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.SearchCpfResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.SearchCpfResponse.displayName = 'proto.appointmentApi.SearchCpfResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.Patient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.Patient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.Patient.displayName = 'proto.appointmentApi.Patient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.SearchEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.SearchEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.SearchEmailRequest.displayName = 'proto.appointmentApi.SearchEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.SearchEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.SearchEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.SearchEmailResponse.displayName = 'proto.appointmentApi.SearchEmailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AddAppointmentProfessionalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.AddAppointmentProfessionalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AddAppointmentProfessionalRequest.displayName = 'proto.appointmentApi.AddAppointmentProfessionalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AddAppointmentProfessionalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.AddAppointmentProfessionalResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.AddAppointmentProfessionalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AddAppointmentProfessionalResponse.displayName = 'proto.appointmentApi.AddAppointmentProfessionalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.VerifyConflictRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.VerifyConflictRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.VerifyConflictRequest.displayName = 'proto.appointmentApi.VerifyConflictRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.VerifyConflictResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.VerifyConflictResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.VerifyConflictResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.VerifyConflictResponse.displayName = 'proto.appointmentApi.VerifyConflictResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.WeeklyScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.WeeklyScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.WeeklyScheduleRequest.displayName = 'proto.appointmentApi.WeeklyScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.WeeklyScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.WeeklyScheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.WeeklyScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.WeeklyScheduleResponse.displayName = 'proto.appointmentApi.WeeklyScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.WeekDateList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.WeekDateList.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.WeekDateList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.WeekDateList.displayName = 'proto.appointmentApi.WeekDateList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.WeekPlaceList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.WeekPlaceList.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.WeekPlaceList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.WeekPlaceList.displayName = 'proto.appointmentApi.WeekPlaceList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.WeekAppointmentList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.WeekAppointmentList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.WeekAppointmentList.displayName = 'proto.appointmentApi.WeekAppointmentList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.MonthlyScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.MonthlyScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.MonthlyScheduleRequest.displayName = 'proto.appointmentApi.MonthlyScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.MonthlyScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.MonthlyScheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.MonthlyScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.MonthlyScheduleResponse.displayName = 'proto.appointmentApi.MonthlyScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.MonthDateList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.MonthDateList.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.MonthDateList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.MonthDateList.displayName = 'proto.appointmentApi.MonthDateList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListWaitingListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.ListWaitingListResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.ListWaitingListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListWaitingListResponse.displayName = 'proto.appointmentApi.ListWaitingListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.WaitingListProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.WaitingListProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.WaitingListProto.displayName = 'proto.appointmentApi.WaitingListProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.RescheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.RescheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.RescheduleRequest.displayName = 'proto.appointmentApi.RescheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.RescheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.RescheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.RescheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.RescheduleResponse.displayName = 'proto.appointmentApi.RescheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.VerifyHolidayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.VerifyHolidayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.VerifyHolidayRequest.displayName = 'proto.appointmentApi.VerifyHolidayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.VerifyHolidayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.VerifyHolidayResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.VerifyHolidayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.VerifyHolidayResponse.displayName = 'proto.appointmentApi.VerifyHolidayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.Holidays = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.Holidays, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.Holidays.displayName = 'proto.appointmentApi.Holidays';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.VerifyScheduleRegistryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.VerifyScheduleRegistryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.VerifyScheduleRegistryRequest.displayName = 'proto.appointmentApi.VerifyScheduleRegistryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.VerifyScheduleRegistryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.VerifyScheduleRegistryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.VerifyScheduleRegistryResponse.displayName = 'proto.appointmentApi.VerifyScheduleRegistryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PatientSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PatientSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PatientSchedule.displayName = 'proto.appointmentApi.PatientSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PatientReschedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PatientReschedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PatientReschedule.displayName = 'proto.appointmentApi.PatientReschedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AppointmentReschedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.AppointmentReschedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AppointmentReschedule.displayName = 'proto.appointmentApi.AppointmentReschedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PatientActionReschedulingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PatientActionReschedulingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PatientActionReschedulingRequest.displayName = 'proto.appointmentApi.PatientActionReschedulingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PatientActionReschedulingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PatientActionReschedulingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PatientActionReschedulingResponse.displayName = 'proto.appointmentApi.PatientActionReschedulingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AddPatientWaitingListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.AddPatientWaitingListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AddPatientWaitingListRequest.displayName = 'proto.appointmentApi.AddPatientWaitingListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AddPatientWaitingListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.AddPatientWaitingListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AddPatientWaitingListResponse.displayName = 'proto.appointmentApi.AddPatientWaitingListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.RescheduleAppointmentProfessionalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.RescheduleAppointmentProfessionalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.RescheduleAppointmentProfessionalRequest.displayName = 'proto.appointmentApi.RescheduleAppointmentProfessionalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.RescheduleAppointmentProfessionalResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.RescheduleAppointmentProfessionalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.RescheduleAppointmentProfessionalResponse.displayName = 'proto.appointmentApi.RescheduleAppointmentProfessionalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.VerifyReschedulingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.VerifyReschedulingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.VerifyReschedulingRequest.displayName = 'proto.appointmentApi.VerifyReschedulingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.VerifyReschedulingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.VerifyReschedulingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.VerifyReschedulingResponse.displayName = 'proto.appointmentApi.VerifyReschedulingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PatientReschedulingDecisionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PatientReschedulingDecisionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PatientReschedulingDecisionRequest.displayName = 'proto.appointmentApi.PatientReschedulingDecisionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PatientReschedulingDecisionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.PatientReschedulingDecisionResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.PatientReschedulingDecisionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PatientReschedulingDecisionResponse.displayName = 'proto.appointmentApi.PatientReschedulingDecisionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PatientAppointmentDecisionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PatientAppointmentDecisionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PatientAppointmentDecisionRequest.displayName = 'proto.appointmentApi.PatientAppointmentDecisionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PatientAppointmentDecisionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PatientAppointmentDecisionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PatientAppointmentDecisionResponse.displayName = 'proto.appointmentApi.PatientAppointmentDecisionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListPatientAppointmentDecisionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.ListPatientAppointmentDecisionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListPatientAppointmentDecisionRequest.displayName = 'proto.appointmentApi.ListPatientAppointmentDecisionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.ListPatientAppointmentDecisionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListPatientAppointmentDecisionResponse.displayName = 'proto.appointmentApi.ListPatientAppointmentDecisionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListAppointmentData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.ListAppointmentData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListAppointmentData.displayName = 'proto.appointmentApi.ListAppointmentData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.CancelAppointmentProfessionalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.CancelAppointmentProfessionalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.CancelAppointmentProfessionalRequest.displayName = 'proto.appointmentApi.CancelAppointmentProfessionalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.CancelAppointmentProfessionalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.CancelAppointmentProfessionalResponse.displayName = 'proto.appointmentApi.CancelAppointmentProfessionalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.FutureMedicalCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.FutureMedicalCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.FutureMedicalCareRequest.displayName = 'proto.appointmentApi.FutureMedicalCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.FutureMedicalCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.FutureMedicalCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.FutureMedicalCareResponse.displayName = 'proto.appointmentApi.FutureMedicalCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListFutureMedicalCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.ListFutureMedicalCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListFutureMedicalCareRequest.displayName = 'proto.appointmentApi.ListFutureMedicalCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListFutureMedicalCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.ListFutureMedicalCareResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.ListFutureMedicalCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListFutureMedicalCareResponse.displayName = 'proto.appointmentApi.ListFutureMedicalCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.SearchListFutureMedicalCareRequest.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.SearchListFutureMedicalCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.SearchListFutureMedicalCareRequest.displayName = 'proto.appointmentApi.SearchListFutureMedicalCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.SearchListFutureMedicalCareResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.SearchListFutureMedicalCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.SearchListFutureMedicalCareResponse.displayName = 'proto.appointmentApi.SearchListFutureMedicalCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PreviousMedicalCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PreviousMedicalCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PreviousMedicalCareRequest.displayName = 'proto.appointmentApi.PreviousMedicalCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PreviousMedicalCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PreviousMedicalCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PreviousMedicalCareResponse.displayName = 'proto.appointmentApi.PreviousMedicalCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListPreviousMedicalCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.ListPreviousMedicalCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListPreviousMedicalCareRequest.displayName = 'proto.appointmentApi.ListPreviousMedicalCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListPreviousMedicalCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.ListPreviousMedicalCareResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.ListPreviousMedicalCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListPreviousMedicalCareResponse.displayName = 'proto.appointmentApi.ListPreviousMedicalCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.SearchListPreviousMedicalCareRequest.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.SearchListPreviousMedicalCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.SearchListPreviousMedicalCareRequest.displayName = 'proto.appointmentApi.SearchListPreviousMedicalCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.SearchListPreviousMedicalCareResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.SearchListPreviousMedicalCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.SearchListPreviousMedicalCareResponse.displayName = 'proto.appointmentApi.SearchListPreviousMedicalCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.TodayMedicalCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.TodayMedicalCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.TodayMedicalCareRequest.displayName = 'proto.appointmentApi.TodayMedicalCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.TodayMedicalCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.TodayMedicalCareResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.TodayMedicalCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.TodayMedicalCareResponse.displayName = 'proto.appointmentApi.TodayMedicalCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.SearchTodayMedicalCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.SearchTodayMedicalCareRequest.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.SearchTodayMedicalCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.SearchTodayMedicalCareRequest.displayName = 'proto.appointmentApi.SearchTodayMedicalCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.SearchTodayMedicalCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.SearchTodayMedicalCareResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.SearchTodayMedicalCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.SearchTodayMedicalCareResponse.displayName = 'proto.appointmentApi.SearchTodayMedicalCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PatientCheckInRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PatientCheckInRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PatientCheckInRequest.displayName = 'proto.appointmentApi.PatientCheckInRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PatientCheckInResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PatientCheckInResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PatientCheckInResponse.displayName = 'proto.appointmentApi.PatientCheckInResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ConferCheckInRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.ConferCheckInRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ConferCheckInRequest.displayName = 'proto.appointmentApi.ConferCheckInRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ConferCheckInResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.ConferCheckInResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.ConferCheckInResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ConferCheckInResponse.displayName = 'proto.appointmentApi.ConferCheckInResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PatientInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PatientInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PatientInfo.displayName = 'proto.appointmentApi.PatientInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ProfessionalCheckInRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.ProfessionalCheckInRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ProfessionalCheckInRequest.displayName = 'proto.appointmentApi.ProfessionalCheckInRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ProfessionalCheckInResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.ProfessionalCheckInResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ProfessionalCheckInResponse.displayName = 'proto.appointmentApi.ProfessionalCheckInResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.UpdateQueueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.UpdateQueueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.UpdateQueueRequest.displayName = 'proto.appointmentApi.UpdateQueueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.UpdateQueueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.UpdateQueueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.UpdateQueueResponse.displayName = 'proto.appointmentApi.UpdateQueueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.UpdateOrderQueueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.UpdateOrderQueueRequest.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.UpdateOrderQueueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.UpdateOrderQueueRequest.displayName = 'proto.appointmentApi.UpdateOrderQueueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.Queue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.Queue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.Queue.displayName = 'proto.appointmentApi.Queue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.UpdateOrderQueueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.UpdateOrderQueueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.UpdateOrderQueueResponse.displayName = 'proto.appointmentApi.UpdateOrderQueueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.StartPatientCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.StartPatientCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.StartPatientCareRequest.displayName = 'proto.appointmentApi.StartPatientCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.StartPatientCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.StartPatientCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.StartPatientCareResponse.displayName = 'proto.appointmentApi.StartPatientCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.StopPatientCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.StopPatientCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.StopPatientCareRequest.displayName = 'proto.appointmentApi.StopPatientCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.StopPatientCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.StopPatientCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.StopPatientCareResponse.displayName = 'proto.appointmentApi.StopPatientCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.VerifyQueueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.VerifyQueueResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.VerifyQueueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.VerifyQueueResponse.displayName = 'proto.appointmentApi.VerifyQueueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.DateListQueuePreviuos = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.DateListQueuePreviuos.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.DateListQueuePreviuos, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.DateListQueuePreviuos.displayName = 'proto.appointmentApi.DateListQueuePreviuos';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListPatientsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.ListPatientsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListPatientsRequest.displayName = 'proto.appointmentApi.ListPatientsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.ListPatientsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.ListPatientsResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.ListPatientsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.ListPatientsResponse.displayName = 'proto.appointmentApi.ListPatientsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.PatientProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.PatientProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.PatientProto.displayName = 'proto.appointmentApi.PatientProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.AppointmentPatientProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.AppointmentPatientProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.AppointmentPatientProto.displayName = 'proto.appointmentApi.AppointmentPatientProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.SearchListPatientsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointmentApi.SearchListPatientsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.SearchListPatientsRequest.displayName = 'proto.appointmentApi.SearchListPatientsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointmentApi.SearchListPatientsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointmentApi.SearchListPatientsResponse.repeatedFields_, null);
};
goog.inherits(proto.appointmentApi.SearchListPatientsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointmentApi.SearchListPatientsResponse.displayName = 'proto.appointmentApi.SearchListPatientsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.ListAppointmentsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListAppointmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListAppointmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListAppointmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListAppointmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    wordList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListAppointmentsRequest}
 */
proto.appointmentApi.ListAppointmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListAppointmentsRequest;
  return proto.appointmentApi.ListAppointmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListAppointmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListAppointmentsRequest}
 */
proto.appointmentApi.ListAppointmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListAppointmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListAppointmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListAppointmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListAppointmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWordList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string word = 1;
 * @return {!Array<string>}
 */
proto.appointmentApi.ListAppointmentsRequest.prototype.getWordList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.appointmentApi.ListAppointmentsRequest} returns this
 */
proto.appointmentApi.ListAppointmentsRequest.prototype.setWordList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.ListAppointmentsRequest} returns this
 */
proto.appointmentApi.ListAppointmentsRequest.prototype.addWord = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.ListAppointmentsRequest} returns this
 */
proto.appointmentApi.ListAppointmentsRequest.prototype.clearWordList = function() {
  return this.setWordList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.ListNextMedicalCareResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListNextMedicalCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListNextMedicalCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListNextMedicalCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListNextMedicalCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    appointmentsList: jspb.Message.toObjectList(msg.getAppointmentsList(),
    proto.appointmentApi.HomeListProfessionalFutureAppointment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListNextMedicalCareResponse}
 */
proto.appointmentApi.ListNextMedicalCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListNextMedicalCareResponse;
  return proto.appointmentApi.ListNextMedicalCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListNextMedicalCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListNextMedicalCareResponse}
 */
proto.appointmentApi.ListNextMedicalCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.HomeListProfessionalFutureAppointment;
      reader.readMessage(value,proto.appointmentApi.HomeListProfessionalFutureAppointment.deserializeBinaryFromReader);
      msg.addAppointments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListNextMedicalCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListNextMedicalCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListNextMedicalCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListNextMedicalCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAppointmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.HomeListProfessionalFutureAppointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.ListNextMedicalCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListNextMedicalCareResponse} returns this
 */
proto.appointmentApi.ListNextMedicalCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.ListNextMedicalCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListNextMedicalCareResponse} returns this
 */
proto.appointmentApi.ListNextMedicalCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.ListNextMedicalCareResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListNextMedicalCareResponse} returns this
 */
proto.appointmentApi.ListNextMedicalCareResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated HomeListProfessionalFutureAppointment appointments = 4;
 * @return {!Array<!proto.appointmentApi.HomeListProfessionalFutureAppointment>}
 */
proto.appointmentApi.ListNextMedicalCareResponse.prototype.getAppointmentsList = function() {
  return /** @type{!Array<!proto.appointmentApi.HomeListProfessionalFutureAppointment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.HomeListProfessionalFutureAppointment, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.HomeListProfessionalFutureAppointment>} value
 * @return {!proto.appointmentApi.ListNextMedicalCareResponse} returns this
*/
proto.appointmentApi.ListNextMedicalCareResponse.prototype.setAppointmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.HomeListProfessionalFutureAppointment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment}
 */
proto.appointmentApi.ListNextMedicalCareResponse.prototype.addAppointments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.HomeListProfessionalFutureAppointment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.ListNextMedicalCareResponse} returns this
 */
proto.appointmentApi.ListNextMedicalCareResponse.prototype.clearAppointmentsList = function() {
  return this.setAppointmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.HomeListProfessionalFutureAppointment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.HomeListProfessionalFutureAppointment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    patientPicture: jspb.Message.getFieldWithDefault(msg, 5, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    serviceDescription: jspb.Message.getFieldWithDefault(msg, 7, ""),
    serviceMode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 10, ""),
    status: jspb.Message.getFieldWithDefault(msg, 11, ""),
    chiefComplaint: jspb.Message.getFieldWithDefault(msg, 12, ""),
    queue: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    positionQueue: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.HomeListProfessionalFutureAppointment;
  return proto.appointmentApi.HomeListProfessionalFutureAppointment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.HomeListProfessionalFutureAppointment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientPicture(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceDescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceMode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setChiefComplaint(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQueue(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPositionQueue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.HomeListProfessionalFutureAppointment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.HomeListProfessionalFutureAppointment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPatientPicture();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getServiceDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getServiceMode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getChiefComplaint();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getQueue();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getPositionQueue();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initial_date = 2;
 * @return {string}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string estimated_time = 3;
 * @return {string}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getEstimatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string patient_name = 4;
 * @return {string}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getPatientName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setPatientName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string patient_picture = 5;
 * @return {string}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getPatientPicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setPatientPicture = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string service_name = 6;
 * @return {string}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string service_description = 7;
 * @return {string}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getServiceDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setServiceDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string service_mode = 8;
 * @return {string}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getServiceMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setServiceMode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string place_name = 9;
 * @return {string}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string occupation = 10;
 * @return {string}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string status = 11;
 * @return {string}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string chief_complaint = 12;
 * @return {string}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getChiefComplaint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setChiefComplaint = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool queue = 13;
 * @return {boolean}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getQueue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setQueue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 position_queue = 14;
 * @return {number}
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.getPositionQueue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.HomeListProfessionalFutureAppointment} returns this
 */
proto.appointmentApi.HomeListProfessionalFutureAppointment.prototype.setPositionQueue = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListPatientFutureAppointmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListPatientFutureAppointmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    appointmentsList: jspb.Message.toObjectList(msg.getAppointmentsList(),
    proto.appointmentApi.HomeListPatientFutureAppointment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListPatientFutureAppointmentsResponse}
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListPatientFutureAppointmentsResponse;
  return proto.appointmentApi.ListPatientFutureAppointmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListPatientFutureAppointmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListPatientFutureAppointmentsResponse}
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.HomeListPatientFutureAppointment;
      reader.readMessage(value,proto.appointmentApi.HomeListPatientFutureAppointment.deserializeBinaryFromReader);
      msg.addAppointments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListPatientFutureAppointmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListPatientFutureAppointmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAppointmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.HomeListPatientFutureAppointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListPatientFutureAppointmentsResponse} returns this
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientFutureAppointmentsResponse} returns this
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error_message = 3;
 * @return {string}
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientFutureAppointmentsResponse} returns this
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated HomeListPatientFutureAppointment appointments = 4;
 * @return {!Array<!proto.appointmentApi.HomeListPatientFutureAppointment>}
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.prototype.getAppointmentsList = function() {
  return /** @type{!Array<!proto.appointmentApi.HomeListPatientFutureAppointment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.HomeListPatientFutureAppointment, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.HomeListPatientFutureAppointment>} value
 * @return {!proto.appointmentApi.ListPatientFutureAppointmentsResponse} returns this
*/
proto.appointmentApi.ListPatientFutureAppointmentsResponse.prototype.setAppointmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.HomeListPatientFutureAppointment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment}
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.prototype.addAppointments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.HomeListPatientFutureAppointment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.ListPatientFutureAppointmentsResponse} returns this
 */
proto.appointmentApi.ListPatientFutureAppointmentsResponse.prototype.clearAppointmentsList = function() {
  return this.setAppointmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.HomeListPatientFutureAppointment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.HomeListPatientFutureAppointment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.HomeListPatientFutureAppointment.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    professionalName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    professionalPicture: jspb.Message.getFieldWithDefault(msg, 5, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    serviceDescription: jspb.Message.getFieldWithDefault(msg, 7, ""),
    serviceMode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 10, ""),
    status: jspb.Message.getFieldWithDefault(msg, 11, ""),
    chiefComplaint: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.HomeListPatientFutureAppointment;
  return proto.appointmentApi.HomeListPatientFutureAppointment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.HomeListPatientFutureAppointment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalPicture(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceDescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceMode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setChiefComplaint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.HomeListPatientFutureAppointment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.HomeListPatientFutureAppointment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.HomeListPatientFutureAppointment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfessionalName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProfessionalPicture();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getServiceDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getServiceMode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getChiefComplaint();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment} returns this
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initial_date = 2;
 * @return {string}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment} returns this
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string estimated_time = 3;
 * @return {string}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.getEstimatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment} returns this
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string professional_name = 4;
 * @return {string}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.getProfessionalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment} returns this
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.setProfessionalName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string professional_picture = 5;
 * @return {string}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.getProfessionalPicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment} returns this
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.setProfessionalPicture = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string service_name = 6;
 * @return {string}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment} returns this
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string service_description = 7;
 * @return {string}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.getServiceDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment} returns this
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.setServiceDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string service_mode = 8;
 * @return {string}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.getServiceMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment} returns this
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.setServiceMode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string place_name = 9;
 * @return {string}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment} returns this
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string occupation = 10;
 * @return {string}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment} returns this
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string status = 11;
 * @return {string}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment} returns this
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string chief_complaint = 12;
 * @return {string}
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.getChiefComplaint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.HomeListPatientFutureAppointment} returns this
 */
proto.appointmentApi.HomeListPatientFutureAppointment.prototype.setChiefComplaint = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListDaysConflicts.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListDaysConflicts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListDaysConflicts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListDaysConflicts.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableScheduleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListDaysConflicts}
 */
proto.appointmentApi.ListDaysConflicts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListDaysConflicts;
  return proto.appointmentApi.ListDaysConflicts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListDaysConflicts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListDaysConflicts}
 */
proto.appointmentApi.ListDaysConflicts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailableScheduleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListDaysConflicts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListDaysConflicts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListDaysConflicts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListDaysConflicts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableScheduleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string available_schedule_id = 1;
 * @return {string}
 */
proto.appointmentApi.ListDaysConflicts.prototype.getAvailableScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListDaysConflicts} returns this
 */
proto.appointmentApi.ListDaysConflicts.prototype.setAvailableScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initial_date = 2;
 * @return {string}
 */
proto.appointmentApi.ListDaysConflicts.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListDaysConflicts} returns this
 */
proto.appointmentApi.ListDaysConflicts.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string final_date = 3;
 * @return {string}
 */
proto.appointmentApi.ListDaysConflicts.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListDaysConflicts} returns this
 */
proto.appointmentApi.ListDaysConflicts.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.GetStartPatientCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.GetStartPatientCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.GetStartPatientCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.GetStartPatientCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appointmentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    patientId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.GetStartPatientCareRequest}
 */
proto.appointmentApi.GetStartPatientCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.GetStartPatientCareRequest;
  return proto.appointmentApi.GetStartPatientCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.GetStartPatientCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.GetStartPatientCareRequest}
 */
proto.appointmentApi.GetStartPatientCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.GetStartPatientCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.GetStartPatientCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.GetStartPatientCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.GetStartPatientCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string professional_id = 1;
 * @return {string}
 */
proto.appointmentApi.GetStartPatientCareRequest.prototype.getProfessionalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.GetStartPatientCareRequest} returns this
 */
proto.appointmentApi.GetStartPatientCareRequest.prototype.setProfessionalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string appointment_id = 2;
 * @return {string}
 */
proto.appointmentApi.GetStartPatientCareRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.GetStartPatientCareRequest} returns this
 */
proto.appointmentApi.GetStartPatientCareRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string patient_id = 3;
 * @return {string}
 */
proto.appointmentApi.GetStartPatientCareRequest.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.GetStartPatientCareRequest} returns this
 */
proto.appointmentApi.GetStartPatientCareRequest.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.GetStartPatientCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.GetStartPatientCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.GetStartPatientCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.GetStartPatientCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    queueData: (f = msg.getQueueData()) && proto.appointmentApi.QueueData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.GetStartPatientCareResponse}
 */
proto.appointmentApi.GetStartPatientCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.GetStartPatientCareResponse;
  return proto.appointmentApi.GetStartPatientCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.GetStartPatientCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.GetStartPatientCareResponse}
 */
proto.appointmentApi.GetStartPatientCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.QueueData;
      reader.readMessage(value,proto.appointmentApi.QueueData.deserializeBinaryFromReader);
      msg.setQueueData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.GetStartPatientCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.GetStartPatientCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.GetStartPatientCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.GetStartPatientCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQueueData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.appointmentApi.QueueData.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.GetStartPatientCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.GetStartPatientCareResponse} returns this
 */
proto.appointmentApi.GetStartPatientCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.GetStartPatientCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.GetStartPatientCareResponse} returns this
 */
proto.appointmentApi.GetStartPatientCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.GetStartPatientCareResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.GetStartPatientCareResponse} returns this
 */
proto.appointmentApi.GetStartPatientCareResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional QueueData queue_data = 4;
 * @return {?proto.appointmentApi.QueueData}
 */
proto.appointmentApi.GetStartPatientCareResponse.prototype.getQueueData = function() {
  return /** @type{?proto.appointmentApi.QueueData} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.QueueData, 4));
};


/**
 * @param {?proto.appointmentApi.QueueData|undefined} value
 * @return {!proto.appointmentApi.GetStartPatientCareResponse} returns this
*/
proto.appointmentApi.GetStartPatientCareResponse.prototype.setQueueData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.GetStartPatientCareResponse} returns this
 */
proto.appointmentApi.GetStartPatientCareResponse.prototype.clearQueueData = function() {
  return this.setQueueData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.GetStartPatientCareResponse.prototype.hasQueueData = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.QueueData.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.QueueData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.QueueData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.QueueData.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    queueposition: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.QueueData}
 */
proto.appointmentApi.QueueData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.QueueData;
  return proto.appointmentApi.QueueData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.QueueData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.QueueData}
 */
proto.appointmentApi.QueueData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQueueposition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.QueueData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.QueueData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.QueueData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.QueueData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getQueueposition();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointmentApi.QueueData.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueData} returns this
 */
proto.appointmentApi.QueueData.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 position = 2;
 * @return {number}
 */
proto.appointmentApi.QueueData.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.QueueData} returns this
 */
proto.appointmentApi.QueueData.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 status = 3;
 * @return {number}
 */
proto.appointmentApi.QueueData.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.QueueData} returns this
 */
proto.appointmentApi.QueueData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 queuePosition = 4;
 * @return {number}
 */
proto.appointmentApi.QueueData.prototype.getQueueposition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.QueueData} returns this
 */
proto.appointmentApi.QueueData.prototype.setQueueposition = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.ListMedicalCareRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListMedicalCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListMedicalCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListMedicalCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    wordList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListMedicalCareRequest}
 */
proto.appointmentApi.ListMedicalCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListMedicalCareRequest;
  return proto.appointmentApi.ListMedicalCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListMedicalCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListMedicalCareRequest}
 */
proto.appointmentApi.ListMedicalCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListMedicalCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListMedicalCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListMedicalCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWordList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListMedicalCareRequest} returns this
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListMedicalCareRequest} returns this
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string initial_date = 3;
 * @return {string}
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListMedicalCareRequest} returns this
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string final_date = 4;
 * @return {string}
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListMedicalCareRequest} returns this
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string word = 5;
 * @return {!Array<string>}
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.getWordList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.appointmentApi.ListMedicalCareRequest} returns this
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.setWordList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.ListMedicalCareRequest} returns this
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.addWord = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.ListMedicalCareRequest} returns this
 */
proto.appointmentApi.ListMedicalCareRequest.prototype.clearWordList = function() {
  return this.setWordList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.ListMedicalCareResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListMedicalCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListMedicalCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListMedicalCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.appointmentApi.DateInfo.toObject, includeInstance),
    infoDates: (f = msg.getInfoDates()) && proto.appointmentApi.InfoDatesProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListMedicalCareResponse}
 */
proto.appointmentApi.ListMedicalCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListMedicalCareResponse;
  return proto.appointmentApi.ListMedicalCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListMedicalCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListMedicalCareResponse}
 */
proto.appointmentApi.ListMedicalCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.DateInfo;
      reader.readMessage(value,proto.appointmentApi.DateInfo.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    case 5:
      var value = new proto.appointmentApi.InfoDatesProto;
      reader.readMessage(value,proto.appointmentApi.InfoDatesProto.deserializeBinaryFromReader);
      msg.setInfoDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListMedicalCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListMedicalCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListMedicalCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.DateInfo.serializeBinaryToWriter
    );
  }
  f = message.getInfoDates();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.appointmentApi.InfoDatesProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListMedicalCareResponse} returns this
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListMedicalCareResponse} returns this
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListMedicalCareResponse} returns this
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DateInfo dates = 4;
 * @return {!Array<!proto.appointmentApi.DateInfo>}
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.appointmentApi.DateInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.DateInfo, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.DateInfo>} value
 * @return {!proto.appointmentApi.ListMedicalCareResponse} returns this
*/
proto.appointmentApi.ListMedicalCareResponse.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.DateInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.DateInfo}
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.DateInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.ListMedicalCareResponse} returns this
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};


/**
 * optional InfoDatesProto info_dates = 5;
 * @return {?proto.appointmentApi.InfoDatesProto}
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.getInfoDates = function() {
  return /** @type{?proto.appointmentApi.InfoDatesProto} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.InfoDatesProto, 5));
};


/**
 * @param {?proto.appointmentApi.InfoDatesProto|undefined} value
 * @return {!proto.appointmentApi.ListMedicalCareResponse} returns this
*/
proto.appointmentApi.ListMedicalCareResponse.prototype.setInfoDates = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.ListMedicalCareResponse} returns this
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.clearInfoDates = function() {
  return this.setInfoDates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.ListMedicalCareResponse.prototype.hasInfoDates = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.InfoDatesProto.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.InfoDatesProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.InfoDatesProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.InfoDatesProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.InfoDatesProto}
 */
proto.appointmentApi.InfoDatesProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.InfoDatesProto;
  return proto.appointmentApi.InfoDatesProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.InfoDatesProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.InfoDatesProto}
 */
proto.appointmentApi.InfoDatesProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.InfoDatesProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.InfoDatesProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.InfoDatesProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.InfoDatesProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string initial_date = 1;
 * @return {string}
 */
proto.appointmentApi.InfoDatesProto.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.InfoDatesProto} returns this
 */
proto.appointmentApi.InfoDatesProto.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string final_date = 2;
 * @return {string}
 */
proto.appointmentApi.InfoDatesProto.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.InfoDatesProto} returns this
 */
proto.appointmentApi.InfoDatesProto.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.DateInfo.repeatedFields_ = [3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.DateInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.DateInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.DateInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.DateInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    appointmentsList: jspb.Message.toObjectList(msg.getAppointmentsList(),
    proto.appointmentApi.AppointmentList.toObject, includeInstance),
    queueList: jspb.Message.toObjectList(msg.getQueueList(),
    proto.appointmentApi.QueueProto.toObject, includeInstance),
    attendedList: jspb.Message.toObjectList(msg.getAttendedList(),
    proto.appointmentApi.AppointmentLP.toObject, includeInstance),
    noShowList: jspb.Message.toObjectList(msg.getNoShowList(),
    proto.appointmentApi.AppointmentLP.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.DateInfo}
 */
proto.appointmentApi.DateInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.DateInfo;
  return proto.appointmentApi.DateInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.DateInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.DateInfo}
 */
proto.appointmentApi.DateInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.appointmentApi.AppointmentList;
      reader.readMessage(value,proto.appointmentApi.AppointmentList.deserializeBinaryFromReader);
      msg.addAppointments(value);
      break;
    case 4:
      var value = new proto.appointmentApi.QueueProto;
      reader.readMessage(value,proto.appointmentApi.QueueProto.deserializeBinaryFromReader);
      msg.addQueue(value);
      break;
    case 5:
      var value = new proto.appointmentApi.AppointmentLP;
      reader.readMessage(value,proto.appointmentApi.AppointmentLP.deserializeBinaryFromReader);
      msg.addAttended(value);
      break;
    case 6:
      var value = new proto.appointmentApi.AppointmentLP;
      reader.readMessage(value,proto.appointmentApi.AppointmentLP.deserializeBinaryFromReader);
      msg.addNoShow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.DateInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.DateInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.DateInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.DateInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAppointmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.appointmentApi.AppointmentList.serializeBinaryToWriter
    );
  }
  f = message.getQueueList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.QueueProto.serializeBinaryToWriter
    );
  }
  f = message.getAttendedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.appointmentApi.AppointmentLP.serializeBinaryToWriter
    );
  }
  f = message.getNoShowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.appointmentApi.AppointmentLP.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.appointmentApi.DateInfo.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.DateInfo} returns this
 */
proto.appointmentApi.DateInfo.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.appointmentApi.DateInfo.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.DateInfo} returns this
 */
proto.appointmentApi.DateInfo.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated AppointmentList appointments = 3;
 * @return {!Array<!proto.appointmentApi.AppointmentList>}
 */
proto.appointmentApi.DateInfo.prototype.getAppointmentsList = function() {
  return /** @type{!Array<!proto.appointmentApi.AppointmentList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.AppointmentList, 3));
};


/**
 * @param {!Array<!proto.appointmentApi.AppointmentList>} value
 * @return {!proto.appointmentApi.DateInfo} returns this
*/
proto.appointmentApi.DateInfo.prototype.setAppointmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.appointmentApi.AppointmentList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.AppointmentList}
 */
proto.appointmentApi.DateInfo.prototype.addAppointments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.appointmentApi.AppointmentList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.DateInfo} returns this
 */
proto.appointmentApi.DateInfo.prototype.clearAppointmentsList = function() {
  return this.setAppointmentsList([]);
};


/**
 * repeated QueueProto queue = 4;
 * @return {!Array<!proto.appointmentApi.QueueProto>}
 */
proto.appointmentApi.DateInfo.prototype.getQueueList = function() {
  return /** @type{!Array<!proto.appointmentApi.QueueProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.QueueProto, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.QueueProto>} value
 * @return {!proto.appointmentApi.DateInfo} returns this
*/
proto.appointmentApi.DateInfo.prototype.setQueueList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.QueueProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.QueueProto}
 */
proto.appointmentApi.DateInfo.prototype.addQueue = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.QueueProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.DateInfo} returns this
 */
proto.appointmentApi.DateInfo.prototype.clearQueueList = function() {
  return this.setQueueList([]);
};


/**
 * repeated AppointmentLP attended = 5;
 * @return {!Array<!proto.appointmentApi.AppointmentLP>}
 */
proto.appointmentApi.DateInfo.prototype.getAttendedList = function() {
  return /** @type{!Array<!proto.appointmentApi.AppointmentLP>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.AppointmentLP, 5));
};


/**
 * @param {!Array<!proto.appointmentApi.AppointmentLP>} value
 * @return {!proto.appointmentApi.DateInfo} returns this
*/
proto.appointmentApi.DateInfo.prototype.setAttendedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.appointmentApi.AppointmentLP=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.AppointmentLP}
 */
proto.appointmentApi.DateInfo.prototype.addAttended = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.appointmentApi.AppointmentLP, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.DateInfo} returns this
 */
proto.appointmentApi.DateInfo.prototype.clearAttendedList = function() {
  return this.setAttendedList([]);
};


/**
 * repeated AppointmentLP no_show = 6;
 * @return {!Array<!proto.appointmentApi.AppointmentLP>}
 */
proto.appointmentApi.DateInfo.prototype.getNoShowList = function() {
  return /** @type{!Array<!proto.appointmentApi.AppointmentLP>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.AppointmentLP, 6));
};


/**
 * @param {!Array<!proto.appointmentApi.AppointmentLP>} value
 * @return {!proto.appointmentApi.DateInfo} returns this
*/
proto.appointmentApi.DateInfo.prototype.setNoShowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.appointmentApi.AppointmentLP=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.AppointmentLP}
 */
proto.appointmentApi.DateInfo.prototype.addNoShow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.appointmentApi.AppointmentLP, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.DateInfo} returns this
 */
proto.appointmentApi.DateInfo.prototype.clearNoShowList = function() {
  return this.setNoShowList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AppointmentLP.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AppointmentLP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AppointmentLP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AppointmentLP.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    patientName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientPictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    date: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dayOfYear: jspb.Message.getFieldWithDefault(msg, 6, 0),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    appointmentStatus: jspb.Message.getFieldWithDefault(msg, 8, ""),
    appointmentColor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    serviceDescription: jspb.Message.getFieldWithDefault(msg, 11, ""),
    serviceMode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    placeId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    color: jspb.Message.getFieldWithDefault(msg, 15, ""),
    placePictureUrl: jspb.Message.getFieldWithDefault(msg, 16, ""),
    placeAddress: jspb.Message.getFieldWithDefault(msg, 17, ""),
    placeNumber: jspb.Message.getFieldWithDefault(msg, 18, ""),
    placeComplement: jspb.Message.getFieldWithDefault(msg, 19, ""),
    placeCity: jspb.Message.getFieldWithDefault(msg, 20, ""),
    placeState: jspb.Message.getFieldWithDefault(msg, 21, ""),
    placePostalCode: jspb.Message.getFieldWithDefault(msg, 22, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 23, ""),
    specitliaty: jspb.Message.getFieldWithDefault(msg, 24, ""),
    actuation: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AppointmentLP}
 */
proto.appointmentApi.AppointmentLP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AppointmentLP;
  return proto.appointmentApi.AppointmentLP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AppointmentLP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AppointmentLP}
 */
proto.appointmentApi.AppointmentLP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientPictureUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayOfYear(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentColor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceMode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacePictureUrl(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceAddress(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceNumber(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceComplement(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceCity(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceState(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacePostalCode(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecitliaty(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AppointmentLP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AppointmentLP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AppointmentLP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AppointmentLP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPatientName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDayOfYear();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAppointmentStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAppointmentColor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getServiceDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getServiceMode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPlacePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPlaceAddress();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPlaceNumber();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPlaceComplement();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPlaceCity();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPlaceState();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPlacePostalCode();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getSpecitliaty();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getActuation();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patient_id = 2;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string patient_name = 3;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getPatientName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setPatientName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string patient_picture_url = 4;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getPatientPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setPatientPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date = 5;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 day_of_year = 6;
 * @return {number}
 */
proto.appointmentApi.AppointmentLP.prototype.getDayOfYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setDayOfYear = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string estimated_time = 7;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getEstimatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string appointment_status = 8;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getAppointmentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setAppointmentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string appointment_color = 9;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getAppointmentColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setAppointmentColor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string service_name = 10;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string service_description = 11;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getServiceDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setServiceDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string service_mode = 12;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getServiceMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setServiceMode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string place_id = 13;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string place_name = 14;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string color = 15;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string place_picture_url = 16;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getPlacePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setPlacePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string place_address = 17;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getPlaceAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setPlaceAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string place_number = 18;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getPlaceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setPlaceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string place_complement = 19;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getPlaceComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setPlaceComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string place_city = 20;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getPlaceCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setPlaceCity = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string place_state = 21;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getPlaceState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setPlaceState = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string place_postal_code = 22;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getPlacePostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setPlacePostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string occupation = 23;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string specitliaty = 24;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getSpecitliaty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setSpecitliaty = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string actuation = 25;
 * @return {string}
 */
proto.appointmentApi.AppointmentLP.prototype.getActuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentLP} returns this
 */
proto.appointmentApi.AppointmentLP.prototype.setActuation = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.QueueProto.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.QueueProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.QueueProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.QueueProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    patientName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientPictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    date: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dayOfYear: jspb.Message.getFieldWithDefault(msg, 6, 0),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    appointmentStatus: jspb.Message.getFieldWithDefault(msg, 8, ""),
    appointmentColor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    serviceDescription: jspb.Message.getFieldWithDefault(msg, 11, ""),
    serviceMode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    placeId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    color: jspb.Message.getFieldWithDefault(msg, 15, ""),
    placePictureUrl: jspb.Message.getFieldWithDefault(msg, 16, ""),
    placeAddress: jspb.Message.getFieldWithDefault(msg, 17, ""),
    placeNumber: jspb.Message.getFieldWithDefault(msg, 18, ""),
    placeComplement: jspb.Message.getFieldWithDefault(msg, 19, ""),
    placeCity: jspb.Message.getFieldWithDefault(msg, 20, ""),
    placeState: jspb.Message.getFieldWithDefault(msg, 21, ""),
    placePostalCode: jspb.Message.getFieldWithDefault(msg, 22, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 23, ""),
    specitliaty: jspb.Message.getFieldWithDefault(msg, 24, ""),
    actuation: jspb.Message.getFieldWithDefault(msg, 25, ""),
    positionQueue: jspb.Message.getFieldWithDefault(msg, 26, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.QueueProto}
 */
proto.appointmentApi.QueueProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.QueueProto;
  return proto.appointmentApi.QueueProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.QueueProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.QueueProto}
 */
proto.appointmentApi.QueueProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientPictureUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayOfYear(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentColor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceMode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacePictureUrl(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceAddress(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceNumber(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceComplement(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceCity(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceState(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacePostalCode(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecitliaty(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuation(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPositionQueue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.QueueProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.QueueProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.QueueProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.QueueProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPatientName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDayOfYear();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAppointmentStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAppointmentColor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getServiceDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getServiceMode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPlacePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPlaceAddress();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPlaceNumber();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPlaceComplement();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPlaceCity();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPlaceState();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPlacePostalCode();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getSpecitliaty();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getActuation();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getPositionQueue();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patient_id = 2;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string patient_name = 3;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getPatientName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPatientName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string patient_picture_url = 4;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getPatientPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPatientPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date = 5;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 day_of_year = 6;
 * @return {number}
 */
proto.appointmentApi.QueueProto.prototype.getDayOfYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setDayOfYear = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string estimated_time = 7;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getEstimatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string appointment_status = 8;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getAppointmentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setAppointmentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string appointment_color = 9;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getAppointmentColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setAppointmentColor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string service_name = 10;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string service_description = 11;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getServiceDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setServiceDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string service_mode = 12;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getServiceMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setServiceMode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string place_id = 13;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string place_name = 14;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string color = 15;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string place_picture_url = 16;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getPlacePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPlacePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string place_address = 17;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getPlaceAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPlaceAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string place_number = 18;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getPlaceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPlaceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string place_complement = 19;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getPlaceComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPlaceComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string place_city = 20;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getPlaceCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPlaceCity = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string place_state = 21;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getPlaceState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPlaceState = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string place_postal_code = 22;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getPlacePostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPlacePostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string occupation = 23;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string specitliaty = 24;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getSpecitliaty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setSpecitliaty = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string actuation = 25;
 * @return {string}
 */
proto.appointmentApi.QueueProto.prototype.getActuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setActuation = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional int32 position_queue = 26;
 * @return {number}
 */
proto.appointmentApi.QueueProto.prototype.getPositionQueue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.QueueProto} returns this
 */
proto.appointmentApi.QueueProto.prototype.setPositionQueue = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.ListPatientAppointmentsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListPatientAppointmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListPatientAppointmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListPatientAppointmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientAppointmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialdate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    finaldate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    wordList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListPatientAppointmentsRequest}
 */
proto.appointmentApi.ListPatientAppointmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListPatientAppointmentsRequest;
  return proto.appointmentApi.ListPatientAppointmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListPatientAppointmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListPatientAppointmentsRequest}
 */
proto.appointmentApi.ListPatientAppointmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialdate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinaldate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListPatientAppointmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListPatientAppointmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListPatientAppointmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientAppointmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialdate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFinaldate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWordList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentsRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentsRequest} returns this
 */
proto.appointmentApi.ListPatientAppointmentsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initialDate = 2;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentsRequest.prototype.getInitialdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentsRequest} returns this
 */
proto.appointmentApi.ListPatientAppointmentsRequest.prototype.setInitialdate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string finalDate = 3;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentsRequest.prototype.getFinaldate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentsRequest} returns this
 */
proto.appointmentApi.ListPatientAppointmentsRequest.prototype.setFinaldate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string word = 4;
 * @return {!Array<string>}
 */
proto.appointmentApi.ListPatientAppointmentsRequest.prototype.getWordList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.appointmentApi.ListPatientAppointmentsRequest} returns this
 */
proto.appointmentApi.ListPatientAppointmentsRequest.prototype.setWordList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.ListPatientAppointmentsRequest} returns this
 */
proto.appointmentApi.ListPatientAppointmentsRequest.prototype.addWord = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.ListPatientAppointmentsRequest} returns this
 */
proto.appointmentApi.ListPatientAppointmentsRequest.prototype.clearWordList = function() {
  return this.setWordList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListPatientAppointmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListPatientAppointmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListPatientAppointmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientAppointmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    infos: (f = msg.getInfos()) && proto.appointmentApi.InfoPatientAppointmentProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListPatientAppointmentsResponse}
 */
proto.appointmentApi.ListPatientAppointmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListPatientAppointmentsResponse;
  return proto.appointmentApi.ListPatientAppointmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListPatientAppointmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListPatientAppointmentsResponse}
 */
proto.appointmentApi.ListPatientAppointmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.InfoPatientAppointmentProto;
      reader.readMessage(value,proto.appointmentApi.InfoPatientAppointmentProto.deserializeBinaryFromReader);
      msg.setInfos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListPatientAppointmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListPatientAppointmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListPatientAppointmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientAppointmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInfos();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.appointmentApi.InfoPatientAppointmentProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.ListPatientAppointmentsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListPatientAppointmentsResponse} returns this
 */
proto.appointmentApi.ListPatientAppointmentsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentsResponse} returns this
 */
proto.appointmentApi.ListPatientAppointmentsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentsResponse} returns this
 */
proto.appointmentApi.ListPatientAppointmentsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional InfoPatientAppointmentProto infos = 4;
 * @return {?proto.appointmentApi.InfoPatientAppointmentProto}
 */
proto.appointmentApi.ListPatientAppointmentsResponse.prototype.getInfos = function() {
  return /** @type{?proto.appointmentApi.InfoPatientAppointmentProto} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.InfoPatientAppointmentProto, 4));
};


/**
 * @param {?proto.appointmentApi.InfoPatientAppointmentProto|undefined} value
 * @return {!proto.appointmentApi.ListPatientAppointmentsResponse} returns this
*/
proto.appointmentApi.ListPatientAppointmentsResponse.prototype.setInfos = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.ListPatientAppointmentsResponse} returns this
 */
proto.appointmentApi.ListPatientAppointmentsResponse.prototype.clearInfos = function() {
  return this.setInfos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.ListPatientAppointmentsResponse.prototype.hasInfos = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.InfoPatientAppointmentProto.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.InfoPatientAppointmentProto.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.InfoPatientAppointmentProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.InfoPatientAppointmentProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.InfoPatientAppointmentProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialdate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    finaldate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    appointmentsList: jspb.Message.toObjectList(msg.getAppointmentsList(),
    proto.appointmentApi.ListPatientAppointmentProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.InfoPatientAppointmentProto}
 */
proto.appointmentApi.InfoPatientAppointmentProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.InfoPatientAppointmentProto;
  return proto.appointmentApi.InfoPatientAppointmentProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.InfoPatientAppointmentProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.InfoPatientAppointmentProto}
 */
proto.appointmentApi.InfoPatientAppointmentProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialdate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinaldate(value);
      break;
    case 3:
      var value = new proto.appointmentApi.ListPatientAppointmentProto;
      reader.readMessage(value,proto.appointmentApi.ListPatientAppointmentProto.deserializeBinaryFromReader);
      msg.addAppointments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.InfoPatientAppointmentProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.InfoPatientAppointmentProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.InfoPatientAppointmentProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.InfoPatientAppointmentProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialdate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFinaldate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAppointmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.appointmentApi.ListPatientAppointmentProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string initialDate = 1;
 * @return {string}
 */
proto.appointmentApi.InfoPatientAppointmentProto.prototype.getInitialdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.InfoPatientAppointmentProto} returns this
 */
proto.appointmentApi.InfoPatientAppointmentProto.prototype.setInitialdate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string finalDate = 2;
 * @return {string}
 */
proto.appointmentApi.InfoPatientAppointmentProto.prototype.getFinaldate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.InfoPatientAppointmentProto} returns this
 */
proto.appointmentApi.InfoPatientAppointmentProto.prototype.setFinaldate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ListPatientAppointmentProto appointments = 3;
 * @return {!Array<!proto.appointmentApi.ListPatientAppointmentProto>}
 */
proto.appointmentApi.InfoPatientAppointmentProto.prototype.getAppointmentsList = function() {
  return /** @type{!Array<!proto.appointmentApi.ListPatientAppointmentProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.ListPatientAppointmentProto, 3));
};


/**
 * @param {!Array<!proto.appointmentApi.ListPatientAppointmentProto>} value
 * @return {!proto.appointmentApi.InfoPatientAppointmentProto} returns this
*/
proto.appointmentApi.InfoPatientAppointmentProto.prototype.setAppointmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.appointmentApi.ListPatientAppointmentProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.ListPatientAppointmentProto}
 */
proto.appointmentApi.InfoPatientAppointmentProto.prototype.addAppointments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.appointmentApi.ListPatientAppointmentProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.InfoPatientAppointmentProto} returns this
 */
proto.appointmentApi.InfoPatientAppointmentProto.prototype.clearAppointmentsList = function() {
  return this.setAppointmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListPatientAppointmentProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListPatientAppointmentProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientAppointmentProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    professionalid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    professionalname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    professionalpictureurl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    date: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dayofyear: jspb.Message.getFieldWithDefault(msg, 7, 0),
    initialhour: jspb.Message.getFieldWithDefault(msg, 8, ""),
    estimatedtime: jspb.Message.getFieldWithDefault(msg, 9, ""),
    appointmentstatus: jspb.Message.getFieldWithDefault(msg, 10, ""),
    servicename: jspb.Message.getFieldWithDefault(msg, 11, ""),
    servicedescription: jspb.Message.getFieldWithDefault(msg, 12, ""),
    servicemode: jspb.Message.getFieldWithDefault(msg, 13, ""),
    placeid: jspb.Message.getFieldWithDefault(msg, 14, ""),
    placename: jspb.Message.getFieldWithDefault(msg, 15, ""),
    color: jspb.Message.getFieldWithDefault(msg, 16, ""),
    placepictureurl: jspb.Message.getFieldWithDefault(msg, 17, ""),
    placeaddress: jspb.Message.getFieldWithDefault(msg, 18, ""),
    placenumber: jspb.Message.getFieldWithDefault(msg, 19, ""),
    placecomplement: jspb.Message.getFieldWithDefault(msg, 20, ""),
    placecity: jspb.Message.getFieldWithDefault(msg, 21, ""),
    placestate: jspb.Message.getFieldWithDefault(msg, 22, ""),
    placepostalcode: jspb.Message.getFieldWithDefault(msg, 23, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 24, ""),
    specitliaty: jspb.Message.getFieldWithDefault(msg, 25, ""),
    actuation: jspb.Message.getFieldWithDefault(msg, 26, ""),
    waitinglist: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    addwaitinglist: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    cancelappointment: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    reschedule: jspb.Message.getBooleanFieldWithDefault(msg, 30, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListPatientAppointmentProto}
 */
proto.appointmentApi.ListPatientAppointmentProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListPatientAppointmentProto;
  return proto.appointmentApi.ListPatientAppointmentProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListPatientAppointmentProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListPatientAppointmentProto}
 */
proto.appointmentApi.ListPatientAppointmentProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalpictureurl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayofyear(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialhour(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedtime(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentstatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicename(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicedescription(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacename(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacepictureurl(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceaddress(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacenumber(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacecomplement(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacecity(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacestate(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacepostalcode(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecitliaty(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuation(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitinglist(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddwaitinglist(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCancelappointment(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReschedule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListPatientAppointmentProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListPatientAppointmentProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientAppointmentProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfessionalid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfessionalname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProfessionalpictureurl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDayofyear();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getInitialhour();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEstimatedtime();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAppointmentstatus();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getServicename();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getServicedescription();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPlaceid();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPlacename();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPlacepictureurl();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPlaceaddress();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPlacenumber();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPlacecomplement();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPlacecity();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPlacestate();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getPlacepostalcode();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getSpecitliaty();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getActuation();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getWaitinglist();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getAddwaitinglist();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getCancelappointment();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getReschedule();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string professionalId = 2;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getProfessionalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setProfessionalid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string professionalName = 3;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getProfessionalname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setProfessionalname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string professionalPictureUrl = 5;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getProfessionalpictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setProfessionalpictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string date = 6;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 dayOfYear = 7;
 * @return {number}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getDayofyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setDayofyear = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string initialHour = 8;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getInitialhour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setInitialhour = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string estimatedTime = 9;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getEstimatedtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setEstimatedtime = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string appointmentStatus = 10;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getAppointmentstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setAppointmentstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string serviceName = 11;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getServicename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setServicename = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string serviceDescription = 12;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getServicedescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setServicedescription = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string serviceMode = 13;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string placeId = 14;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getPlaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setPlaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string placeName = 15;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getPlacename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setPlacename = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string color = 16;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string placePictureUrl = 17;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getPlacepictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setPlacepictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string placeAddress = 18;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getPlaceaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setPlaceaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string placeNumber = 19;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getPlacenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setPlacenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string placeComplement = 20;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getPlacecomplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setPlacecomplement = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string placeCity = 21;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getPlacecity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setPlacecity = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string placeState = 22;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getPlacestate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setPlacestate = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string placePostalCode = 23;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getPlacepostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setPlacepostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string occupation = 24;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string specitliaty = 25;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getSpecitliaty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setSpecitliaty = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string actuation = 26;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getActuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setActuation = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional bool waitingList = 27;
 * @return {boolean}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getWaitinglist = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setWaitinglist = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool addWaitingList = 28;
 * @return {boolean}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getAddwaitinglist = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setAddwaitinglist = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional bool cancelAppointment = 29;
 * @return {boolean}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getCancelappointment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setCancelappointment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional bool reschedule = 30;
 * @return {boolean}
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.getReschedule = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListPatientAppointmentProto} returns this
 */
proto.appointmentApi.ListPatientAppointmentProto.prototype.setReschedule = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AddStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AddStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AddStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AddStatusRequest}
 */
proto.appointmentApi.AddStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AddStatusRequest;
  return proto.appointmentApi.AddStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AddStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AddStatusRequest}
 */
proto.appointmentApi.AddStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AddStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AddStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AddStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.appointmentApi.AddStatusRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddStatusRequest} returns this
 */
proto.appointmentApi.AddStatusRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AddStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AddStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AddStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AddStatusResponse}
 */
proto.appointmentApi.AddStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AddStatusResponse;
  return proto.appointmentApi.AddStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AddStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AddStatusResponse}
 */
proto.appointmentApi.AddStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AddStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AddStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AddStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.AddStatusResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.AddStatusResponse} returns this
 */
proto.appointmentApi.AddStatusResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.AddStatusResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddStatusResponse} returns this
 */
proto.appointmentApi.AddStatusResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.AddStatusResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddStatusResponse} returns this
 */
proto.appointmentApi.AddStatusResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AddAppointmentPatientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AddAppointmentPatientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddAppointmentPatientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceTypeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateComplete: jspb.Message.getFieldWithDefault(msg, 2, ""),
    speciality: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actuation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    waitingList: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    descriptionWaitingList: jspb.Message.getFieldWithDefault(msg, 6, ""),
    chiefComplaint: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AddAppointmentPatientRequest}
 */
proto.appointmentApi.AddAppointmentPatientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AddAppointmentPatientRequest;
  return proto.appointmentApi.AddAppointmentPatientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AddAppointmentPatientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AddAppointmentPatientRequest}
 */
proto.appointmentApi.AddAppointmentPatientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateComplete(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpeciality(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuation(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitingList(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptionWaitingList(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setChiefComplaint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AddAppointmentPatientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AddAppointmentPatientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddAppointmentPatientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceTypeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateComplete();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSpeciality();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActuation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWaitingList();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDescriptionWaitingList();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getChiefComplaint();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string service_type_id = 1;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.getServiceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentPatientRequest} returns this
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.setServiceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date_complete = 2;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.getDateComplete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentPatientRequest} returns this
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.setDateComplete = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string speciality = 3;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.getSpeciality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentPatientRequest} returns this
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.setSpeciality = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string actuation = 4;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.getActuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentPatientRequest} returns this
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.setActuation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool waiting_list = 5;
 * @return {boolean}
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.getWaitingList = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.AddAppointmentPatientRequest} returns this
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.setWaitingList = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string description_waiting_list = 6;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.getDescriptionWaitingList = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentPatientRequest} returns this
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.setDescriptionWaitingList = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string chief_complaint = 7;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.getChiefComplaint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentPatientRequest} returns this
 */
proto.appointmentApi.AddAppointmentPatientRequest.prototype.setChiefComplaint = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.AddAppointmentPatientResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AddAppointmentPatientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AddAppointmentPatientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AddAppointmentPatientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddAppointmentPatientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    listConflictsList: jspb.Message.toObjectList(msg.getListConflictsList(),
    proto.appointmentApi.ListDaysConflicts.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AddAppointmentPatientResponse}
 */
proto.appointmentApi.AddAppointmentPatientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AddAppointmentPatientResponse;
  return proto.appointmentApi.AddAppointmentPatientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AddAppointmentPatientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AddAppointmentPatientResponse}
 */
proto.appointmentApi.AddAppointmentPatientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.ListDaysConflicts;
      reader.readMessage(value,proto.appointmentApi.ListDaysConflicts.deserializeBinaryFromReader);
      msg.addListConflicts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AddAppointmentPatientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AddAppointmentPatientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AddAppointmentPatientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddAppointmentPatientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getListConflictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.ListDaysConflicts.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.AddAppointmentPatientResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.AddAppointmentPatientResponse} returns this
 */
proto.appointmentApi.AddAppointmentPatientResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentPatientResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentPatientResponse} returns this
 */
proto.appointmentApi.AddAppointmentPatientResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentPatientResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentPatientResponse} returns this
 */
proto.appointmentApi.AddAppointmentPatientResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDaysConflicts list_conflicts = 4;
 * @return {!Array<!proto.appointmentApi.ListDaysConflicts>}
 */
proto.appointmentApi.AddAppointmentPatientResponse.prototype.getListConflictsList = function() {
  return /** @type{!Array<!proto.appointmentApi.ListDaysConflicts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.ListDaysConflicts, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.ListDaysConflicts>} value
 * @return {!proto.appointmentApi.AddAppointmentPatientResponse} returns this
*/
proto.appointmentApi.AddAppointmentPatientResponse.prototype.setListConflictsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.ListDaysConflicts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.ListDaysConflicts}
 */
proto.appointmentApi.AddAppointmentPatientResponse.prototype.addListConflicts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.ListDaysConflicts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.AddAppointmentPatientResponse} returns this
 */
proto.appointmentApi.AddAppointmentPatientResponse.prototype.clearListConflictsList = function() {
  return this.setListConflictsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.EmptyRequest}
 */
proto.appointmentApi.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.EmptyRequest;
  return proto.appointmentApi.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.EmptyRequest}
 */
proto.appointmentApi.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.ListAppointmentsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListAppointmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListAppointmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListAppointmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListAppointmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.appointmentApi.DateListAppointment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListAppointmentsResponse}
 */
proto.appointmentApi.ListAppointmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListAppointmentsResponse;
  return proto.appointmentApi.ListAppointmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListAppointmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListAppointmentsResponse}
 */
proto.appointmentApi.ListAppointmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.DateListAppointment;
      reader.readMessage(value,proto.appointmentApi.DateListAppointment.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListAppointmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListAppointmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListAppointmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListAppointmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.DateListAppointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.ListAppointmentsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListAppointmentsResponse} returns this
 */
proto.appointmentApi.ListAppointmentsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.ListAppointmentsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListAppointmentsResponse} returns this
 */
proto.appointmentApi.ListAppointmentsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.ListAppointmentsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListAppointmentsResponse} returns this
 */
proto.appointmentApi.ListAppointmentsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DateListAppointment dates = 4;
 * @return {!Array<!proto.appointmentApi.DateListAppointment>}
 */
proto.appointmentApi.ListAppointmentsResponse.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.appointmentApi.DateListAppointment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.DateListAppointment, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.DateListAppointment>} value
 * @return {!proto.appointmentApi.ListAppointmentsResponse} returns this
*/
proto.appointmentApi.ListAppointmentsResponse.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.DateListAppointment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.DateListAppointment}
 */
proto.appointmentApi.ListAppointmentsResponse.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.DateListAppointment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.ListAppointmentsResponse} returns this
 */
proto.appointmentApi.ListAppointmentsResponse.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.DateList.repeatedFields_ = [3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.DateList.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.DateList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.DateList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.DateList.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    appointmentsList: jspb.Message.toObjectList(msg.getAppointmentsList(),
    proto.appointmentApi.AppointmentList.toObject, includeInstance),
    queueList: jspb.Message.toObjectList(msg.getQueueList(),
    proto.appointmentApi.QueueListProto.toObject, includeInstance),
    attendedList: jspb.Message.toObjectList(msg.getAttendedList(),
    proto.appointmentApi.AppointmentListPrevious.toObject, includeInstance),
    noshowList: jspb.Message.toObjectList(msg.getNoshowList(),
    proto.appointmentApi.AppointmentListPrevious.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.DateList}
 */
proto.appointmentApi.DateList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.DateList;
  return proto.appointmentApi.DateList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.DateList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.DateList}
 */
proto.appointmentApi.DateList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.appointmentApi.AppointmentList;
      reader.readMessage(value,proto.appointmentApi.AppointmentList.deserializeBinaryFromReader);
      msg.addAppointments(value);
      break;
    case 4:
      var value = new proto.appointmentApi.QueueListProto;
      reader.readMessage(value,proto.appointmentApi.QueueListProto.deserializeBinaryFromReader);
      msg.addQueue(value);
      break;
    case 5:
      var value = new proto.appointmentApi.AppointmentListPrevious;
      reader.readMessage(value,proto.appointmentApi.AppointmentListPrevious.deserializeBinaryFromReader);
      msg.addAttended(value);
      break;
    case 6:
      var value = new proto.appointmentApi.AppointmentListPrevious;
      reader.readMessage(value,proto.appointmentApi.AppointmentListPrevious.deserializeBinaryFromReader);
      msg.addNoshow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.DateList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.DateList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.DateList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.DateList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAppointmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.appointmentApi.AppointmentList.serializeBinaryToWriter
    );
  }
  f = message.getQueueList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.QueueListProto.serializeBinaryToWriter
    );
  }
  f = message.getAttendedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.appointmentApi.AppointmentListPrevious.serializeBinaryToWriter
    );
  }
  f = message.getNoshowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.appointmentApi.AppointmentListPrevious.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.appointmentApi.DateList.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.DateList} returns this
 */
proto.appointmentApi.DateList.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.appointmentApi.DateList.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.DateList} returns this
 */
proto.appointmentApi.DateList.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated AppointmentList appointments = 3;
 * @return {!Array<!proto.appointmentApi.AppointmentList>}
 */
proto.appointmentApi.DateList.prototype.getAppointmentsList = function() {
  return /** @type{!Array<!proto.appointmentApi.AppointmentList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.AppointmentList, 3));
};


/**
 * @param {!Array<!proto.appointmentApi.AppointmentList>} value
 * @return {!proto.appointmentApi.DateList} returns this
*/
proto.appointmentApi.DateList.prototype.setAppointmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.appointmentApi.AppointmentList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.AppointmentList}
 */
proto.appointmentApi.DateList.prototype.addAppointments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.appointmentApi.AppointmentList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.DateList} returns this
 */
proto.appointmentApi.DateList.prototype.clearAppointmentsList = function() {
  return this.setAppointmentsList([]);
};


/**
 * repeated QueueListProto queue = 4;
 * @return {!Array<!proto.appointmentApi.QueueListProto>}
 */
proto.appointmentApi.DateList.prototype.getQueueList = function() {
  return /** @type{!Array<!proto.appointmentApi.QueueListProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.QueueListProto, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.QueueListProto>} value
 * @return {!proto.appointmentApi.DateList} returns this
*/
proto.appointmentApi.DateList.prototype.setQueueList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.QueueListProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.QueueListProto}
 */
proto.appointmentApi.DateList.prototype.addQueue = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.QueueListProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.DateList} returns this
 */
proto.appointmentApi.DateList.prototype.clearQueueList = function() {
  return this.setQueueList([]);
};


/**
 * repeated AppointmentListPrevious attended = 5;
 * @return {!Array<!proto.appointmentApi.AppointmentListPrevious>}
 */
proto.appointmentApi.DateList.prototype.getAttendedList = function() {
  return /** @type{!Array<!proto.appointmentApi.AppointmentListPrevious>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.AppointmentListPrevious, 5));
};


/**
 * @param {!Array<!proto.appointmentApi.AppointmentListPrevious>} value
 * @return {!proto.appointmentApi.DateList} returns this
*/
proto.appointmentApi.DateList.prototype.setAttendedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.appointmentApi.AppointmentListPrevious=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.AppointmentListPrevious}
 */
proto.appointmentApi.DateList.prototype.addAttended = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.appointmentApi.AppointmentListPrevious, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.DateList} returns this
 */
proto.appointmentApi.DateList.prototype.clearAttendedList = function() {
  return this.setAttendedList([]);
};


/**
 * repeated AppointmentListPrevious noShow = 6;
 * @return {!Array<!proto.appointmentApi.AppointmentListPrevious>}
 */
proto.appointmentApi.DateList.prototype.getNoshowList = function() {
  return /** @type{!Array<!proto.appointmentApi.AppointmentListPrevious>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.AppointmentListPrevious, 6));
};


/**
 * @param {!Array<!proto.appointmentApi.AppointmentListPrevious>} value
 * @return {!proto.appointmentApi.DateList} returns this
*/
proto.appointmentApi.DateList.prototype.setNoshowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.appointmentApi.AppointmentListPrevious=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.AppointmentListPrevious}
 */
proto.appointmentApi.DateList.prototype.addNoshow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.appointmentApi.AppointmentListPrevious, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.DateList} returns this
 */
proto.appointmentApi.DateList.prototype.clearNoshowList = function() {
  return this.setNoshowList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.DateListAppointment.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.DateListAppointment.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.DateListAppointment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.DateListAppointment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.DateListAppointment.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    appointmentsList: jspb.Message.toObjectList(msg.getAppointmentsList(),
    proto.appointmentApi.AppointmentList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.DateListAppointment}
 */
proto.appointmentApi.DateListAppointment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.DateListAppointment;
  return proto.appointmentApi.DateListAppointment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.DateListAppointment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.DateListAppointment}
 */
proto.appointmentApi.DateListAppointment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.appointmentApi.AppointmentList;
      reader.readMessage(value,proto.appointmentApi.AppointmentList.deserializeBinaryFromReader);
      msg.addAppointments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.DateListAppointment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.DateListAppointment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.DateListAppointment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.DateListAppointment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAppointmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.appointmentApi.AppointmentList.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.appointmentApi.DateListAppointment.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.DateListAppointment} returns this
 */
proto.appointmentApi.DateListAppointment.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.appointmentApi.DateListAppointment.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.DateListAppointment} returns this
 */
proto.appointmentApi.DateListAppointment.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated AppointmentList appointments = 3;
 * @return {!Array<!proto.appointmentApi.AppointmentList>}
 */
proto.appointmentApi.DateListAppointment.prototype.getAppointmentsList = function() {
  return /** @type{!Array<!proto.appointmentApi.AppointmentList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.AppointmentList, 3));
};


/**
 * @param {!Array<!proto.appointmentApi.AppointmentList>} value
 * @return {!proto.appointmentApi.DateListAppointment} returns this
*/
proto.appointmentApi.DateListAppointment.prototype.setAppointmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.appointmentApi.AppointmentList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.AppointmentList}
 */
proto.appointmentApi.DateListAppointment.prototype.addAppointments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.appointmentApi.AppointmentList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.DateListAppointment} returns this
 */
proto.appointmentApi.DateListAppointment.prototype.clearAppointmentsList = function() {
  return this.setAppointmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AppointmentList.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AppointmentList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AppointmentList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AppointmentList.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    patientName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientPictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    date: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dayOfYear: jspb.Message.getFieldWithDefault(msg, 6, 0),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    appointmentStatus: jspb.Message.getFieldWithDefault(msg, 8, ""),
    appointmentColor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    serviceDescription: jspb.Message.getFieldWithDefault(msg, 11, ""),
    serviceMode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    placeId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    color: jspb.Message.getFieldWithDefault(msg, 15, ""),
    placePictureUrl: jspb.Message.getFieldWithDefault(msg, 16, ""),
    placeAddress: jspb.Message.getFieldWithDefault(msg, 17, ""),
    placeNumber: jspb.Message.getFieldWithDefault(msg, 18, ""),
    placeComplement: jspb.Message.getFieldWithDefault(msg, 19, ""),
    placeCity: jspb.Message.getFieldWithDefault(msg, 20, ""),
    placeState: jspb.Message.getFieldWithDefault(msg, 21, ""),
    placePostalCode: jspb.Message.getFieldWithDefault(msg, 22, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 23, ""),
    specitliaty: jspb.Message.getFieldWithDefault(msg, 24, ""),
    actuation: jspb.Message.getFieldWithDefault(msg, 25, ""),
    waitingList: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    addWaitingList: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    cancelAppointment: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    reschedule: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    checkIn: jspb.Message.getBooleanFieldWithDefault(msg, 30, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AppointmentList}
 */
proto.appointmentApi.AppointmentList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AppointmentList;
  return proto.appointmentApi.AppointmentList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AppointmentList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AppointmentList}
 */
proto.appointmentApi.AppointmentList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientPictureUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayOfYear(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentColor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceMode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacePictureUrl(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceAddress(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceNumber(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceComplement(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceCity(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceState(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacePostalCode(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecitliaty(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuation(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitingList(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddWaitingList(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCancelAppointment(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReschedule(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AppointmentList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AppointmentList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AppointmentList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AppointmentList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPatientName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDayOfYear();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAppointmentStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAppointmentColor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getServiceDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getServiceMode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPlacePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPlaceAddress();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPlaceNumber();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPlaceComplement();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPlaceCity();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPlaceState();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPlacePostalCode();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getSpecitliaty();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getActuation();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getWaitingList();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getAddWaitingList();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getCancelAppointment();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getReschedule();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getCheckIn();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patient_id = 2;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string patient_name = 3;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getPatientName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setPatientName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string patient_picture_url = 4;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getPatientPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setPatientPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date = 5;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 day_of_year = 6;
 * @return {number}
 */
proto.appointmentApi.AppointmentList.prototype.getDayOfYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setDayOfYear = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string estimated_time = 7;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getEstimatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string appointment_status = 8;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getAppointmentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setAppointmentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string appointment_color = 9;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getAppointmentColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setAppointmentColor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string service_name = 10;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string service_description = 11;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getServiceDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setServiceDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string service_mode = 12;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getServiceMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setServiceMode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string place_id = 13;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string place_name = 14;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string color = 15;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string place_picture_url = 16;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getPlacePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setPlacePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string place_address = 17;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getPlaceAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setPlaceAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string place_number = 18;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getPlaceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setPlaceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string place_complement = 19;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getPlaceComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setPlaceComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string place_city = 20;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getPlaceCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setPlaceCity = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string place_state = 21;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getPlaceState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setPlaceState = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string place_postal_code = 22;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getPlacePostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setPlacePostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string occupation = 23;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string specitliaty = 24;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getSpecitliaty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setSpecitliaty = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string actuation = 25;
 * @return {string}
 */
proto.appointmentApi.AppointmentList.prototype.getActuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setActuation = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional bool waiting_list = 26;
 * @return {boolean}
 */
proto.appointmentApi.AppointmentList.prototype.getWaitingList = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setWaitingList = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool add_waiting_list = 27;
 * @return {boolean}
 */
proto.appointmentApi.AppointmentList.prototype.getAddWaitingList = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setAddWaitingList = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool cancel_appointment = 28;
 * @return {boolean}
 */
proto.appointmentApi.AppointmentList.prototype.getCancelAppointment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setCancelAppointment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional bool reschedule = 29;
 * @return {boolean}
 */
proto.appointmentApi.AppointmentList.prototype.getReschedule = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setReschedule = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional bool check_in = 30;
 * @return {boolean}
 */
proto.appointmentApi.AppointmentList.prototype.getCheckIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.AppointmentList} returns this
 */
proto.appointmentApi.AppointmentList.prototype.setCheckIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AppointmentListPrevious.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AppointmentListPrevious} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AppointmentListPrevious.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    patientname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientpictureurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    date: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dayofyear: jspb.Message.getFieldWithDefault(msg, 6, 0),
    initialhour: jspb.Message.getFieldWithDefault(msg, 7, ""),
    estimatedtime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    appointmentstatus: jspb.Message.getFieldWithDefault(msg, 9, ""),
    servicename: jspb.Message.getFieldWithDefault(msg, 10, ""),
    servicedescription: jspb.Message.getFieldWithDefault(msg, 11, ""),
    servicemode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    placeid: jspb.Message.getFieldWithDefault(msg, 13, ""),
    placename: jspb.Message.getFieldWithDefault(msg, 14, ""),
    color: jspb.Message.getFieldWithDefault(msg, 15, ""),
    placepictureurl: jspb.Message.getFieldWithDefault(msg, 16, ""),
    placeaddress: jspb.Message.getFieldWithDefault(msg, 17, ""),
    placenumber: jspb.Message.getFieldWithDefault(msg, 18, ""),
    placecomplement: jspb.Message.getFieldWithDefault(msg, 19, ""),
    placecity: jspb.Message.getFieldWithDefault(msg, 20, ""),
    placestate: jspb.Message.getFieldWithDefault(msg, 21, ""),
    placepostalcode: jspb.Message.getFieldWithDefault(msg, 22, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 23, ""),
    specitliaty: jspb.Message.getFieldWithDefault(msg, 24, ""),
    actuation: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AppointmentListPrevious}
 */
proto.appointmentApi.AppointmentListPrevious.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AppointmentListPrevious;
  return proto.appointmentApi.AppointmentListPrevious.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AppointmentListPrevious} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AppointmentListPrevious}
 */
proto.appointmentApi.AppointmentListPrevious.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientpictureurl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayofyear(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialhour(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedtime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentstatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicename(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicedescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceid(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacename(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacepictureurl(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceaddress(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacenumber(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacecomplement(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacecity(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacestate(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacepostalcode(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecitliaty(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AppointmentListPrevious.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AppointmentListPrevious} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AppointmentListPrevious.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPatientname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientpictureurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDayofyear();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getInitialhour();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEstimatedtime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAppointmentstatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getServicename();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getServicedescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPlaceid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPlacename();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPlacepictureurl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPlaceaddress();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPlacenumber();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPlacecomplement();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPlacecity();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPlacestate();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPlacepostalcode();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getSpecitliaty();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getActuation();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patientId = 2;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getPatientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setPatientid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string patientName = 3;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getPatientname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setPatientname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string patientPictureUrl = 4;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getPatientpictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setPatientpictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date = 5;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 dayOfYear = 6;
 * @return {number}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getDayofyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setDayofyear = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string initialHour = 7;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getInitialhour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setInitialhour = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string estimatedTime = 8;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getEstimatedtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setEstimatedtime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string appointmentStatus = 9;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getAppointmentstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setAppointmentstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string serviceName = 10;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getServicename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setServicename = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string serviceDescription = 11;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getServicedescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setServicedescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string serviceMode = 12;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string placeId = 13;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getPlaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setPlaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string placeName = 14;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getPlacename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setPlacename = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string color = 15;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string placePictureUrl = 16;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getPlacepictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setPlacepictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string placeAddress = 17;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getPlaceaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setPlaceaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string placeNumber = 18;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getPlacenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setPlacenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string placeComplement = 19;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getPlacecomplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setPlacecomplement = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string placeCity = 20;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getPlacecity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setPlacecity = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string placeState = 21;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getPlacestate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setPlacestate = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string placePostalCode = 22;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getPlacepostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setPlacepostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string occupation = 23;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string specitliaty = 24;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getSpecitliaty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setSpecitliaty = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string actuation = 25;
 * @return {string}
 */
proto.appointmentApi.AppointmentListPrevious.prototype.getActuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentListPrevious} returns this
 */
proto.appointmentApi.AppointmentListPrevious.prototype.setActuation = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.QueueListProto.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.QueueListProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.QueueListProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.QueueListProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    patientname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientpictureurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    date: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dayofyear: jspb.Message.getFieldWithDefault(msg, 6, 0),
    initialhour: jspb.Message.getFieldWithDefault(msg, 7, ""),
    estimatedtime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    appointmentstatus: jspb.Message.getFieldWithDefault(msg, 9, ""),
    servicename: jspb.Message.getFieldWithDefault(msg, 10, ""),
    servicedescription: jspb.Message.getFieldWithDefault(msg, 11, ""),
    servicemode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    placeid: jspb.Message.getFieldWithDefault(msg, 13, ""),
    placename: jspb.Message.getFieldWithDefault(msg, 14, ""),
    color: jspb.Message.getFieldWithDefault(msg, 15, ""),
    placepictureurl: jspb.Message.getFieldWithDefault(msg, 16, ""),
    placeaddress: jspb.Message.getFieldWithDefault(msg, 17, ""),
    placenumber: jspb.Message.getFieldWithDefault(msg, 18, ""),
    placecomplement: jspb.Message.getFieldWithDefault(msg, 19, ""),
    placecity: jspb.Message.getFieldWithDefault(msg, 20, ""),
    placestate: jspb.Message.getFieldWithDefault(msg, 21, ""),
    placepostalcode: jspb.Message.getFieldWithDefault(msg, 22, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 23, ""),
    specitliaty: jspb.Message.getFieldWithDefault(msg, 24, ""),
    actuation: jspb.Message.getFieldWithDefault(msg, 25, ""),
    positionqueue: jspb.Message.getFieldWithDefault(msg, 27, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.QueueListProto}
 */
proto.appointmentApi.QueueListProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.QueueListProto;
  return proto.appointmentApi.QueueListProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.QueueListProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.QueueListProto}
 */
proto.appointmentApi.QueueListProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientpictureurl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayofyear(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialhour(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedtime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentstatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicename(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicedescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceid(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacename(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacepictureurl(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceaddress(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacenumber(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacecomplement(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacecity(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacestate(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacepostalcode(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecitliaty(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuation(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPositionqueue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.QueueListProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.QueueListProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.QueueListProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.QueueListProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPatientname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientpictureurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDayofyear();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getInitialhour();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEstimatedtime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAppointmentstatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getServicename();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getServicedescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPlaceid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPlacename();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPlacepictureurl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPlaceaddress();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPlacenumber();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPlacecomplement();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPlacecity();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPlacestate();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPlacepostalcode();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getSpecitliaty();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getActuation();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getPositionqueue();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patientId = 2;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getPatientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPatientid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string patientName = 3;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getPatientname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPatientname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string patientPictureUrl = 4;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getPatientpictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPatientpictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date = 5;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 dayOfYear = 6;
 * @return {number}
 */
proto.appointmentApi.QueueListProto.prototype.getDayofyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setDayofyear = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string initialHour = 7;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getInitialhour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setInitialhour = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string estimatedTime = 8;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getEstimatedtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setEstimatedtime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string appointmentStatus = 9;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getAppointmentstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setAppointmentstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string serviceName = 10;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getServicename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setServicename = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string serviceDescription = 11;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getServicedescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setServicedescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string serviceMode = 12;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string placeId = 13;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getPlaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPlaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string placeName = 14;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getPlacename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPlacename = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string color = 15;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string placePictureUrl = 16;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getPlacepictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPlacepictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string placeAddress = 17;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getPlaceaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPlaceaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string placeNumber = 18;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getPlacenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPlacenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string placeComplement = 19;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getPlacecomplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPlacecomplement = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string placeCity = 20;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getPlacecity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPlacecity = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string placeState = 21;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getPlacestate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPlacestate = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string placePostalCode = 22;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getPlacepostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPlacepostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string occupation = 23;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string specitliaty = 24;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getSpecitliaty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setSpecitliaty = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string actuation = 25;
 * @return {string}
 */
proto.appointmentApi.QueueListProto.prototype.getActuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setActuation = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional int32 positionQueue = 27;
 * @return {number}
 */
proto.appointmentApi.QueueListProto.prototype.getPositionqueue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.QueueListProto} returns this
 */
proto.appointmentApi.QueueListProto.prototype.setPositionqueue = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.SearchCpfRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.SearchCpfRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.SearchCpfRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchCpfRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cpf: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.SearchCpfRequest}
 */
proto.appointmentApi.SearchCpfRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.SearchCpfRequest;
  return proto.appointmentApi.SearchCpfRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.SearchCpfRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.SearchCpfRequest}
 */
proto.appointmentApi.SearchCpfRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.SearchCpfRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.SearchCpfRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.SearchCpfRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchCpfRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCpf();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string cpf = 1;
 * @return {string}
 */
proto.appointmentApi.SearchCpfRequest.prototype.getCpf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchCpfRequest} returns this
 */
proto.appointmentApi.SearchCpfRequest.prototype.setCpf = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.SearchCpfResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.SearchCpfResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.SearchCpfResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchCpfResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patient: (f = msg.getPatient()) && proto.appointmentApi.Patient.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.SearchCpfResponse}
 */
proto.appointmentApi.SearchCpfResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.SearchCpfResponse;
  return proto.appointmentApi.SearchCpfResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.SearchCpfResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.SearchCpfResponse}
 */
proto.appointmentApi.SearchCpfResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.Patient;
      reader.readMessage(value,proto.appointmentApi.Patient.deserializeBinaryFromReader);
      msg.setPatient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.SearchCpfResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.SearchCpfResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.SearchCpfResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchCpfResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatient();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.appointmentApi.Patient.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.SearchCpfResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.SearchCpfResponse} returns this
 */
proto.appointmentApi.SearchCpfResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.SearchCpfResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchCpfResponse} returns this
 */
proto.appointmentApi.SearchCpfResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.SearchCpfResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchCpfResponse} returns this
 */
proto.appointmentApi.SearchCpfResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Patient patient = 4;
 * @return {?proto.appointmentApi.Patient}
 */
proto.appointmentApi.SearchCpfResponse.prototype.getPatient = function() {
  return /** @type{?proto.appointmentApi.Patient} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.Patient, 4));
};


/**
 * @param {?proto.appointmentApi.Patient|undefined} value
 * @return {!proto.appointmentApi.SearchCpfResponse} returns this
*/
proto.appointmentApi.SearchCpfResponse.prototype.setPatient = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.SearchCpfResponse} returns this
 */
proto.appointmentApi.SearchCpfResponse.prototype.clearPatient = function() {
  return this.setPatient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.SearchCpfResponse.prototype.hasPatient = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.Patient.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.Patient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.Patient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.Patient.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cpf: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.Patient}
 */
proto.appointmentApi.Patient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.Patient;
  return proto.appointmentApi.Patient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.Patient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.Patient}
 */
proto.appointmentApi.Patient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.Patient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.Patient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.Patient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.Patient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCpf();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.appointmentApi.Patient.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.Patient} returns this
 */
proto.appointmentApi.Patient.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.appointmentApi.Patient.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.Patient} returns this
 */
proto.appointmentApi.Patient.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.appointmentApi.Patient.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.Patient} returns this
 */
proto.appointmentApi.Patient.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country_code = 4;
 * @return {string}
 */
proto.appointmentApi.Patient.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.Patient} returns this
 */
proto.appointmentApi.Patient.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone = 5;
 * @return {string}
 */
proto.appointmentApi.Patient.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.Patient} returns this
 */
proto.appointmentApi.Patient.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cpf = 6;
 * @return {string}
 */
proto.appointmentApi.Patient.prototype.getCpf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.Patient} returns this
 */
proto.appointmentApi.Patient.prototype.setCpf = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.SearchEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.SearchEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.SearchEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.SearchEmailRequest}
 */
proto.appointmentApi.SearchEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.SearchEmailRequest;
  return proto.appointmentApi.SearchEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.SearchEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.SearchEmailRequest}
 */
proto.appointmentApi.SearchEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.SearchEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.SearchEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.SearchEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.appointmentApi.SearchEmailRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchEmailRequest} returns this
 */
proto.appointmentApi.SearchEmailRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.SearchEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.SearchEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.SearchEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patient: (f = msg.getPatient()) && proto.appointmentApi.Patient.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.SearchEmailResponse}
 */
proto.appointmentApi.SearchEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.SearchEmailResponse;
  return proto.appointmentApi.SearchEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.SearchEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.SearchEmailResponse}
 */
proto.appointmentApi.SearchEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.Patient;
      reader.readMessage(value,proto.appointmentApi.Patient.deserializeBinaryFromReader);
      msg.setPatient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.SearchEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.SearchEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.SearchEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatient();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.appointmentApi.Patient.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.SearchEmailResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.SearchEmailResponse} returns this
 */
proto.appointmentApi.SearchEmailResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.SearchEmailResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchEmailResponse} returns this
 */
proto.appointmentApi.SearchEmailResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.SearchEmailResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchEmailResponse} returns this
 */
proto.appointmentApi.SearchEmailResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Patient patient = 4;
 * @return {?proto.appointmentApi.Patient}
 */
proto.appointmentApi.SearchEmailResponse.prototype.getPatient = function() {
  return /** @type{?proto.appointmentApi.Patient} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.Patient, 4));
};


/**
 * @param {?proto.appointmentApi.Patient|undefined} value
 * @return {!proto.appointmentApi.SearchEmailResponse} returns this
*/
proto.appointmentApi.SearchEmailResponse.prototype.setPatient = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.SearchEmailResponse} returns this
 */
proto.appointmentApi.SearchEmailResponse.prototype.clearPatient = function() {
  return this.setPatient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.SearchEmailResponse.prototype.hasPatient = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AddAppointmentProfessionalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AddAppointmentProfessionalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    patient: (f = msg.getPatient()) && proto.appointmentApi.Patient.toObject(includeInstance, f),
    serviceTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dateComplete: jspb.Message.getFieldWithDefault(msg, 3, ""),
    specitliaty: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actuation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    chiefComplaint: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AddAppointmentProfessionalRequest}
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AddAppointmentProfessionalRequest;
  return proto.appointmentApi.AddAppointmentProfessionalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AddAppointmentProfessionalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AddAppointmentProfessionalRequest}
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.appointmentApi.Patient;
      reader.readMessage(value,proto.appointmentApi.Patient.deserializeBinaryFromReader);
      msg.setPatient(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceTypeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateComplete(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecitliaty(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChiefComplaint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AddAppointmentProfessionalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AddAppointmentProfessionalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.appointmentApi.Patient.serializeBinaryToWriter
    );
  }
  f = message.getServiceTypeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDateComplete();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSpecitliaty();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActuation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChiefComplaint();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional Patient patient = 1;
 * @return {?proto.appointmentApi.Patient}
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.getPatient = function() {
  return /** @type{?proto.appointmentApi.Patient} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.Patient, 1));
};


/**
 * @param {?proto.appointmentApi.Patient|undefined} value
 * @return {!proto.appointmentApi.AddAppointmentProfessionalRequest} returns this
*/
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.setPatient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.AddAppointmentProfessionalRequest} returns this
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.clearPatient = function() {
  return this.setPatient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.hasPatient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string service_type_id = 2;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.getServiceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentProfessionalRequest} returns this
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.setServiceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date_complete = 3;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.getDateComplete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentProfessionalRequest} returns this
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.setDateComplete = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string specitliaty = 4;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.getSpecitliaty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentProfessionalRequest} returns this
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.setSpecitliaty = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string actuation = 5;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.getActuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentProfessionalRequest} returns this
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.setActuation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string chief_complaint = 6;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.getChiefComplaint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentProfessionalRequest} returns this
 */
proto.appointmentApi.AddAppointmentProfessionalRequest.prototype.setChiefComplaint = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AddAppointmentProfessionalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AddAppointmentProfessionalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    listConflictsList: jspb.Message.toObjectList(msg.getListConflictsList(),
    proto.appointmentApi.ListDaysConflicts.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AddAppointmentProfessionalResponse}
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AddAppointmentProfessionalResponse;
  return proto.appointmentApi.AddAppointmentProfessionalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AddAppointmentProfessionalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AddAppointmentProfessionalResponse}
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.ListDaysConflicts;
      reader.readMessage(value,proto.appointmentApi.ListDaysConflicts.deserializeBinaryFromReader);
      msg.addListConflicts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AddAppointmentProfessionalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AddAppointmentProfessionalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getListConflictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.ListDaysConflicts.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.AddAppointmentProfessionalResponse} returns this
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentProfessionalResponse} returns this
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddAppointmentProfessionalResponse} returns this
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDaysConflicts list_conflicts = 4;
 * @return {!Array<!proto.appointmentApi.ListDaysConflicts>}
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.prototype.getListConflictsList = function() {
  return /** @type{!Array<!proto.appointmentApi.ListDaysConflicts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.ListDaysConflicts, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.ListDaysConflicts>} value
 * @return {!proto.appointmentApi.AddAppointmentProfessionalResponse} returns this
*/
proto.appointmentApi.AddAppointmentProfessionalResponse.prototype.setListConflictsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.ListDaysConflicts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.ListDaysConflicts}
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.prototype.addListConflicts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.ListDaysConflicts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.AddAppointmentProfessionalResponse} returns this
 */
proto.appointmentApi.AddAppointmentProfessionalResponse.prototype.clearListConflictsList = function() {
  return this.setListConflictsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.VerifyConflictRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.VerifyConflictRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.VerifyConflictRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyConflictRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceTypeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateComplete: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.VerifyConflictRequest}
 */
proto.appointmentApi.VerifyConflictRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.VerifyConflictRequest;
  return proto.appointmentApi.VerifyConflictRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.VerifyConflictRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.VerifyConflictRequest}
 */
proto.appointmentApi.VerifyConflictRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateComplete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.VerifyConflictRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.VerifyConflictRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.VerifyConflictRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyConflictRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceTypeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateComplete();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string service_type_id = 1;
 * @return {string}
 */
proto.appointmentApi.VerifyConflictRequest.prototype.getServiceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyConflictRequest} returns this
 */
proto.appointmentApi.VerifyConflictRequest.prototype.setServiceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date_complete = 2;
 * @return {string}
 */
proto.appointmentApi.VerifyConflictRequest.prototype.getDateComplete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyConflictRequest} returns this
 */
proto.appointmentApi.VerifyConflictRequest.prototype.setDateComplete = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.VerifyConflictResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.VerifyConflictResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.VerifyConflictResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.VerifyConflictResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyConflictResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    listConflictsList: jspb.Message.toObjectList(msg.getListConflictsList(),
    proto.appointmentApi.ListDaysConflicts.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.VerifyConflictResponse}
 */
proto.appointmentApi.VerifyConflictResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.VerifyConflictResponse;
  return proto.appointmentApi.VerifyConflictResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.VerifyConflictResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.VerifyConflictResponse}
 */
proto.appointmentApi.VerifyConflictResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.ListDaysConflicts;
      reader.readMessage(value,proto.appointmentApi.ListDaysConflicts.deserializeBinaryFromReader);
      msg.addListConflicts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.VerifyConflictResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.VerifyConflictResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.VerifyConflictResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyConflictResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getListConflictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.ListDaysConflicts.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.VerifyConflictResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.VerifyConflictResponse} returns this
 */
proto.appointmentApi.VerifyConflictResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.VerifyConflictResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyConflictResponse} returns this
 */
proto.appointmentApi.VerifyConflictResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.VerifyConflictResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyConflictResponse} returns this
 */
proto.appointmentApi.VerifyConflictResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDaysConflicts list_conflicts = 4;
 * @return {!Array<!proto.appointmentApi.ListDaysConflicts>}
 */
proto.appointmentApi.VerifyConflictResponse.prototype.getListConflictsList = function() {
  return /** @type{!Array<!proto.appointmentApi.ListDaysConflicts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.ListDaysConflicts, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.ListDaysConflicts>} value
 * @return {!proto.appointmentApi.VerifyConflictResponse} returns this
*/
proto.appointmentApi.VerifyConflictResponse.prototype.setListConflictsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.ListDaysConflicts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.ListDaysConflicts}
 */
proto.appointmentApi.VerifyConflictResponse.prototype.addListConflicts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.ListDaysConflicts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.VerifyConflictResponse} returns this
 */
proto.appointmentApi.VerifyConflictResponse.prototype.clearListConflictsList = function() {
  return this.setListConflictsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.WeeklyScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.WeeklyScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.WeeklyScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.WeeklyScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialdate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    finaldate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.WeeklyScheduleRequest}
 */
proto.appointmentApi.WeeklyScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.WeeklyScheduleRequest;
  return proto.appointmentApi.WeeklyScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.WeeklyScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.WeeklyScheduleRequest}
 */
proto.appointmentApi.WeeklyScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialdate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinaldate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.WeeklyScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.WeeklyScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.WeeklyScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.WeeklyScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialdate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFinaldate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string initialDate = 1;
 * @return {string}
 */
proto.appointmentApi.WeeklyScheduleRequest.prototype.getInitialdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeeklyScheduleRequest} returns this
 */
proto.appointmentApi.WeeklyScheduleRequest.prototype.setInitialdate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string finalDate = 2;
 * @return {string}
 */
proto.appointmentApi.WeeklyScheduleRequest.prototype.getFinaldate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeeklyScheduleRequest} returns this
 */
proto.appointmentApi.WeeklyScheduleRequest.prototype.setFinaldate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.WeeklyScheduleResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.WeeklyScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.WeeklyScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.WeeklyScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.WeeklyScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.appointmentApi.WeekDateList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.WeeklyScheduleResponse}
 */
proto.appointmentApi.WeeklyScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.WeeklyScheduleResponse;
  return proto.appointmentApi.WeeklyScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.WeeklyScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.WeeklyScheduleResponse}
 */
proto.appointmentApi.WeeklyScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.WeekDateList;
      reader.readMessage(value,proto.appointmentApi.WeekDateList.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.WeeklyScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.WeeklyScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.WeeklyScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.WeeklyScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.WeekDateList.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.WeeklyScheduleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.WeeklyScheduleResponse} returns this
 */
proto.appointmentApi.WeeklyScheduleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.WeeklyScheduleResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeeklyScheduleResponse} returns this
 */
proto.appointmentApi.WeeklyScheduleResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.WeeklyScheduleResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeeklyScheduleResponse} returns this
 */
proto.appointmentApi.WeeklyScheduleResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated WeekDateList dates = 4;
 * @return {!Array<!proto.appointmentApi.WeekDateList>}
 */
proto.appointmentApi.WeeklyScheduleResponse.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.appointmentApi.WeekDateList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.WeekDateList, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.WeekDateList>} value
 * @return {!proto.appointmentApi.WeeklyScheduleResponse} returns this
*/
proto.appointmentApi.WeeklyScheduleResponse.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.WeekDateList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.WeekDateList}
 */
proto.appointmentApi.WeeklyScheduleResponse.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.WeekDateList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.WeeklyScheduleResponse} returns this
 */
proto.appointmentApi.WeeklyScheduleResponse.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.WeekDateList.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.WeekDateList.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.WeekDateList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.WeekDateList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.WeekDateList.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dayofyear: jspb.Message.getFieldWithDefault(msg, 2, 0),
    initialhour: jspb.Message.getFieldWithDefault(msg, 3, ""),
    finalhour: jspb.Message.getFieldWithDefault(msg, 4, ""),
    placesList: jspb.Message.toObjectList(msg.getPlacesList(),
    proto.appointmentApi.WeekPlaceList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.WeekDateList}
 */
proto.appointmentApi.WeekDateList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.WeekDateList;
  return proto.appointmentApi.WeekDateList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.WeekDateList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.WeekDateList}
 */
proto.appointmentApi.WeekDateList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayofyear(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialhour(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalhour(value);
      break;
    case 5:
      var value = new proto.appointmentApi.WeekPlaceList;
      reader.readMessage(value,proto.appointmentApi.WeekPlaceList.deserializeBinaryFromReader);
      msg.addPlaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.WeekDateList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.WeekDateList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.WeekDateList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.WeekDateList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDayofyear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getInitialhour();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFinalhour();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.appointmentApi.WeekPlaceList.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.appointmentApi.WeekDateList.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekDateList} returns this
 */
proto.appointmentApi.WeekDateList.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 dayOfYear = 2;
 * @return {number}
 */
proto.appointmentApi.WeekDateList.prototype.getDayofyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.WeekDateList} returns this
 */
proto.appointmentApi.WeekDateList.prototype.setDayofyear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string initialHour = 3;
 * @return {string}
 */
proto.appointmentApi.WeekDateList.prototype.getInitialhour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekDateList} returns this
 */
proto.appointmentApi.WeekDateList.prototype.setInitialhour = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string finalHour = 4;
 * @return {string}
 */
proto.appointmentApi.WeekDateList.prototype.getFinalhour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekDateList} returns this
 */
proto.appointmentApi.WeekDateList.prototype.setFinalhour = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated WeekPlaceList places = 5;
 * @return {!Array<!proto.appointmentApi.WeekPlaceList>}
 */
proto.appointmentApi.WeekDateList.prototype.getPlacesList = function() {
  return /** @type{!Array<!proto.appointmentApi.WeekPlaceList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.WeekPlaceList, 5));
};


/**
 * @param {!Array<!proto.appointmentApi.WeekPlaceList>} value
 * @return {!proto.appointmentApi.WeekDateList} returns this
*/
proto.appointmentApi.WeekDateList.prototype.setPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.appointmentApi.WeekPlaceList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.WeekPlaceList}
 */
proto.appointmentApi.WeekDateList.prototype.addPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.appointmentApi.WeekPlaceList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.WeekDateList} returns this
 */
proto.appointmentApi.WeekDateList.prototype.clearPlacesList = function() {
  return this.setPlacesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.WeekPlaceList.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.WeekPlaceList.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.WeekPlaceList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.WeekPlaceList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.WeekPlaceList.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    total: jspb.Message.getFieldWithDefault(msg, 2, ""),
    placeid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    placename: jspb.Message.getFieldWithDefault(msg, 4, ""),
    placecolor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    appointmentsList: jspb.Message.toObjectList(msg.getAppointmentsList(),
    proto.appointmentApi.WeekAppointmentList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.WeekPlaceList}
 */
proto.appointmentApi.WeekPlaceList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.WeekPlaceList;
  return proto.appointmentApi.WeekPlaceList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.WeekPlaceList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.WeekPlaceList}
 */
proto.appointmentApi.WeekPlaceList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacename(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacecolor(value);
      break;
    case 6:
      var value = new proto.appointmentApi.WeekAppointmentList;
      reader.readMessage(value,proto.appointmentApi.WeekAppointmentList.deserializeBinaryFromReader);
      msg.addAppointments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.WeekPlaceList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.WeekPlaceList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.WeekPlaceList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.WeekPlaceList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlaceid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlacename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPlacecolor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAppointmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.appointmentApi.WeekAppointmentList.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.appointmentApi.WeekPlaceList.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekPlaceList} returns this
 */
proto.appointmentApi.WeekPlaceList.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string total = 2;
 * @return {string}
 */
proto.appointmentApi.WeekPlaceList.prototype.getTotal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekPlaceList} returns this
 */
proto.appointmentApi.WeekPlaceList.prototype.setTotal = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string placeId = 3;
 * @return {string}
 */
proto.appointmentApi.WeekPlaceList.prototype.getPlaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekPlaceList} returns this
 */
proto.appointmentApi.WeekPlaceList.prototype.setPlaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string placeName = 4;
 * @return {string}
 */
proto.appointmentApi.WeekPlaceList.prototype.getPlacename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekPlaceList} returns this
 */
proto.appointmentApi.WeekPlaceList.prototype.setPlacename = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string placeColor = 5;
 * @return {string}
 */
proto.appointmentApi.WeekPlaceList.prototype.getPlacecolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekPlaceList} returns this
 */
proto.appointmentApi.WeekPlaceList.prototype.setPlacecolor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated WeekAppointmentList appointments = 6;
 * @return {!Array<!proto.appointmentApi.WeekAppointmentList>}
 */
proto.appointmentApi.WeekPlaceList.prototype.getAppointmentsList = function() {
  return /** @type{!Array<!proto.appointmentApi.WeekAppointmentList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.WeekAppointmentList, 6));
};


/**
 * @param {!Array<!proto.appointmentApi.WeekAppointmentList>} value
 * @return {!proto.appointmentApi.WeekPlaceList} returns this
*/
proto.appointmentApi.WeekPlaceList.prototype.setAppointmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.appointmentApi.WeekAppointmentList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.WeekAppointmentList}
 */
proto.appointmentApi.WeekPlaceList.prototype.addAppointments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.appointmentApi.WeekAppointmentList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.WeekPlaceList} returns this
 */
proto.appointmentApi.WeekPlaceList.prototype.clearAppointmentsList = function() {
  return this.setAppointmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.WeekAppointmentList.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.WeekAppointmentList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.WeekAppointmentList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.WeekAppointmentList.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    patientname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientpictureurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    date: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dayofyear: jspb.Message.getFieldWithDefault(msg, 6, 0),
    initialhour: jspb.Message.getFieldWithDefault(msg, 7, ""),
    estimatedtime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    appointmentstatus: jspb.Message.getFieldWithDefault(msg, 9, ""),
    servicename: jspb.Message.getFieldWithDefault(msg, 10, ""),
    servicemode: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.WeekAppointmentList}
 */
proto.appointmentApi.WeekAppointmentList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.WeekAppointmentList;
  return proto.appointmentApi.WeekAppointmentList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.WeekAppointmentList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.WeekAppointmentList}
 */
proto.appointmentApi.WeekAppointmentList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientpictureurl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayofyear(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialhour(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedtime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentstatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicename(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.WeekAppointmentList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.WeekAppointmentList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.WeekAppointmentList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.WeekAppointmentList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPatientname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientpictureurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDayofyear();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getInitialhour();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEstimatedtime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAppointmentstatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getServicename();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.appointmentApi.WeekAppointmentList.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekAppointmentList} returns this
 */
proto.appointmentApi.WeekAppointmentList.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patientId = 2;
 * @return {string}
 */
proto.appointmentApi.WeekAppointmentList.prototype.getPatientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekAppointmentList} returns this
 */
proto.appointmentApi.WeekAppointmentList.prototype.setPatientid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string patientName = 3;
 * @return {string}
 */
proto.appointmentApi.WeekAppointmentList.prototype.getPatientname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekAppointmentList} returns this
 */
proto.appointmentApi.WeekAppointmentList.prototype.setPatientname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string patientPictureUrl = 4;
 * @return {string}
 */
proto.appointmentApi.WeekAppointmentList.prototype.getPatientpictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekAppointmentList} returns this
 */
proto.appointmentApi.WeekAppointmentList.prototype.setPatientpictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date = 5;
 * @return {string}
 */
proto.appointmentApi.WeekAppointmentList.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekAppointmentList} returns this
 */
proto.appointmentApi.WeekAppointmentList.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 dayOfYear = 6;
 * @return {number}
 */
proto.appointmentApi.WeekAppointmentList.prototype.getDayofyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.WeekAppointmentList} returns this
 */
proto.appointmentApi.WeekAppointmentList.prototype.setDayofyear = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string initialHour = 7;
 * @return {string}
 */
proto.appointmentApi.WeekAppointmentList.prototype.getInitialhour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekAppointmentList} returns this
 */
proto.appointmentApi.WeekAppointmentList.prototype.setInitialhour = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string estimatedTime = 8;
 * @return {string}
 */
proto.appointmentApi.WeekAppointmentList.prototype.getEstimatedtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekAppointmentList} returns this
 */
proto.appointmentApi.WeekAppointmentList.prototype.setEstimatedtime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string appointmentStatus = 9;
 * @return {string}
 */
proto.appointmentApi.WeekAppointmentList.prototype.getAppointmentstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekAppointmentList} returns this
 */
proto.appointmentApi.WeekAppointmentList.prototype.setAppointmentstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string serviceName = 10;
 * @return {string}
 */
proto.appointmentApi.WeekAppointmentList.prototype.getServicename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekAppointmentList} returns this
 */
proto.appointmentApi.WeekAppointmentList.prototype.setServicename = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string serviceMode = 11;
 * @return {string}
 */
proto.appointmentApi.WeekAppointmentList.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WeekAppointmentList} returns this
 */
proto.appointmentApi.WeekAppointmentList.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.MonthlyScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.MonthlyScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.MonthlyScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.MonthlyScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, 0),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.MonthlyScheduleRequest}
 */
proto.appointmentApi.MonthlyScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.MonthlyScheduleRequest;
  return proto.appointmentApi.MonthlyScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.MonthlyScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.MonthlyScheduleRequest}
 */
proto.appointmentApi.MonthlyScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.MonthlyScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.MonthlyScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.MonthlyScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.MonthlyScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 month = 1;
 * @return {number}
 */
proto.appointmentApi.MonthlyScheduleRequest.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.MonthlyScheduleRequest} returns this
 */
proto.appointmentApi.MonthlyScheduleRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.appointmentApi.MonthlyScheduleRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.MonthlyScheduleRequest} returns this
 */
proto.appointmentApi.MonthlyScheduleRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.MonthlyScheduleResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.MonthlyScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.MonthlyScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.MonthlyScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.MonthlyScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.appointmentApi.MonthDateList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.MonthlyScheduleResponse}
 */
proto.appointmentApi.MonthlyScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.MonthlyScheduleResponse;
  return proto.appointmentApi.MonthlyScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.MonthlyScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.MonthlyScheduleResponse}
 */
proto.appointmentApi.MonthlyScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.MonthDateList;
      reader.readMessage(value,proto.appointmentApi.MonthDateList.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.MonthlyScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.MonthlyScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.MonthlyScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.MonthlyScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.MonthDateList.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.MonthlyScheduleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.MonthlyScheduleResponse} returns this
 */
proto.appointmentApi.MonthlyScheduleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.MonthlyScheduleResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.MonthlyScheduleResponse} returns this
 */
proto.appointmentApi.MonthlyScheduleResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.MonthlyScheduleResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.MonthlyScheduleResponse} returns this
 */
proto.appointmentApi.MonthlyScheduleResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated MonthDateList dates = 4;
 * @return {!Array<!proto.appointmentApi.MonthDateList>}
 */
proto.appointmentApi.MonthlyScheduleResponse.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.appointmentApi.MonthDateList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.MonthDateList, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.MonthDateList>} value
 * @return {!proto.appointmentApi.MonthlyScheduleResponse} returns this
*/
proto.appointmentApi.MonthlyScheduleResponse.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.MonthDateList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.MonthDateList}
 */
proto.appointmentApi.MonthlyScheduleResponse.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.MonthDateList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.MonthlyScheduleResponse} returns this
 */
proto.appointmentApi.MonthlyScheduleResponse.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.MonthDateList.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.MonthDateList.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.MonthDateList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.MonthDateList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.MonthDateList.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dayofyear: jspb.Message.getFieldWithDefault(msg, 2, 0),
    placesList: jspb.Message.toObjectList(msg.getPlacesList(),
    proto.appointmentApi.WeekPlaceList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.MonthDateList}
 */
proto.appointmentApi.MonthDateList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.MonthDateList;
  return proto.appointmentApi.MonthDateList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.MonthDateList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.MonthDateList}
 */
proto.appointmentApi.MonthDateList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayofyear(value);
      break;
    case 3:
      var value = new proto.appointmentApi.WeekPlaceList;
      reader.readMessage(value,proto.appointmentApi.WeekPlaceList.deserializeBinaryFromReader);
      msg.addPlaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.MonthDateList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.MonthDateList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.MonthDateList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.MonthDateList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDayofyear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.appointmentApi.WeekPlaceList.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.appointmentApi.MonthDateList.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.MonthDateList} returns this
 */
proto.appointmentApi.MonthDateList.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 dayOfYear = 2;
 * @return {number}
 */
proto.appointmentApi.MonthDateList.prototype.getDayofyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.MonthDateList} returns this
 */
proto.appointmentApi.MonthDateList.prototype.setDayofyear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated WeekPlaceList places = 3;
 * @return {!Array<!proto.appointmentApi.WeekPlaceList>}
 */
proto.appointmentApi.MonthDateList.prototype.getPlacesList = function() {
  return /** @type{!Array<!proto.appointmentApi.WeekPlaceList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.WeekPlaceList, 3));
};


/**
 * @param {!Array<!proto.appointmentApi.WeekPlaceList>} value
 * @return {!proto.appointmentApi.MonthDateList} returns this
*/
proto.appointmentApi.MonthDateList.prototype.setPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.appointmentApi.WeekPlaceList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.WeekPlaceList}
 */
proto.appointmentApi.MonthDateList.prototype.addPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.appointmentApi.WeekPlaceList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.MonthDateList} returns this
 */
proto.appointmentApi.MonthDateList.prototype.clearPlacesList = function() {
  return this.setPlacesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.ListWaitingListResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListWaitingListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListWaitingListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListWaitingListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListWaitingListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientsList: jspb.Message.toObjectList(msg.getPatientsList(),
    proto.appointmentApi.WaitingListProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListWaitingListResponse}
 */
proto.appointmentApi.ListWaitingListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListWaitingListResponse;
  return proto.appointmentApi.ListWaitingListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListWaitingListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListWaitingListResponse}
 */
proto.appointmentApi.ListWaitingListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.WaitingListProto;
      reader.readMessage(value,proto.appointmentApi.WaitingListProto.deserializeBinaryFromReader);
      msg.addPatients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListWaitingListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListWaitingListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListWaitingListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListWaitingListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.WaitingListProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.ListWaitingListResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListWaitingListResponse} returns this
 */
proto.appointmentApi.ListWaitingListResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.ListWaitingListResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListWaitingListResponse} returns this
 */
proto.appointmentApi.ListWaitingListResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.ListWaitingListResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListWaitingListResponse} returns this
 */
proto.appointmentApi.ListWaitingListResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated WaitingListProto patients = 4;
 * @return {!Array<!proto.appointmentApi.WaitingListProto>}
 */
proto.appointmentApi.ListWaitingListResponse.prototype.getPatientsList = function() {
  return /** @type{!Array<!proto.appointmentApi.WaitingListProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.WaitingListProto, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.WaitingListProto>} value
 * @return {!proto.appointmentApi.ListWaitingListResponse} returns this
*/
proto.appointmentApi.ListWaitingListResponse.prototype.setPatientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.WaitingListProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.WaitingListProto}
 */
proto.appointmentApi.ListWaitingListResponse.prototype.addPatients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.WaitingListProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.ListWaitingListResponse} returns this
 */
proto.appointmentApi.ListWaitingListResponse.prototype.clearPatientsList = function() {
  return this.setPatientsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.WaitingListProto.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.WaitingListProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.WaitingListProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.WaitingListProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    idWaitingList: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    patientName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientPictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dayOfYear: jspb.Message.getFieldWithDefault(msg, 6, 0),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    serviceMode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    placeId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    placeColor: jspb.Message.getFieldWithDefault(msg, 12, ""),
    appointmentStatus: jspb.Message.getFieldWithDefault(msg, 13, ""),
    position: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.WaitingListProto}
 */
proto.appointmentApi.WaitingListProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.WaitingListProto;
  return proto.appointmentApi.WaitingListProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.WaitingListProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.WaitingListProto}
 */
proto.appointmentApi.WaitingListProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdWaitingList(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientPictureUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayOfYear(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceMode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceColor(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentStatus(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.WaitingListProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.WaitingListProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.WaitingListProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.WaitingListProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdWaitingList();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPatientName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDayOfYear();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getServiceMode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPlaceColor();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAppointmentStatus();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional string id_waiting_list = 1;
 * @return {string}
 */
proto.appointmentApi.WaitingListProto.prototype.getIdWaitingList = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setIdWaitingList = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patient_id = 2;
 * @return {string}
 */
proto.appointmentApi.WaitingListProto.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string patient_name = 3;
 * @return {string}
 */
proto.appointmentApi.WaitingListProto.prototype.getPatientName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setPatientName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string patient_picture_url = 4;
 * @return {string}
 */
proto.appointmentApi.WaitingListProto.prototype.getPatientPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setPatientPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string initial_date = 5;
 * @return {string}
 */
proto.appointmentApi.WaitingListProto.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 day_of_year = 6;
 * @return {number}
 */
proto.appointmentApi.WaitingListProto.prototype.getDayOfYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setDayOfYear = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string estimated_time = 7;
 * @return {string}
 */
proto.appointmentApi.WaitingListProto.prototype.getEstimatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string service_name = 8;
 * @return {string}
 */
proto.appointmentApi.WaitingListProto.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string service_mode = 9;
 * @return {string}
 */
proto.appointmentApi.WaitingListProto.prototype.getServiceMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setServiceMode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string place_id = 10;
 * @return {string}
 */
proto.appointmentApi.WaitingListProto.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string place_name = 11;
 * @return {string}
 */
proto.appointmentApi.WaitingListProto.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string place_color = 12;
 * @return {string}
 */
proto.appointmentApi.WaitingListProto.prototype.getPlaceColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setPlaceColor = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string appointment_status = 13;
 * @return {string}
 */
proto.appointmentApi.WaitingListProto.prototype.getAppointmentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setAppointmentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 position = 14;
 * @return {number}
 */
proto.appointmentApi.WaitingListProto.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.WaitingListProto} returns this
 */
proto.appointmentApi.WaitingListProto.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.RescheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.RescheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.RescheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.RescheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    waitingListId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.RescheduleRequest}
 */
proto.appointmentApi.RescheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.RescheduleRequest;
  return proto.appointmentApi.RescheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.RescheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.RescheduleRequest}
 */
proto.appointmentApi.RescheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWaitingListId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.RescheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.RescheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.RescheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.RescheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaitingListId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string waiting_list_id = 1;
 * @return {string}
 */
proto.appointmentApi.RescheduleRequest.prototype.getWaitingListId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.RescheduleRequest} returns this
 */
proto.appointmentApi.RescheduleRequest.prototype.setWaitingListId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initial_date = 2;
 * @return {string}
 */
proto.appointmentApi.RescheduleRequest.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.RescheduleRequest} returns this
 */
proto.appointmentApi.RescheduleRequest.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.RescheduleResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.RescheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.RescheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.RescheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.RescheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    listConflictsList: jspb.Message.toObjectList(msg.getListConflictsList(),
    proto.appointmentApi.ListDaysConflicts.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.RescheduleResponse}
 */
proto.appointmentApi.RescheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.RescheduleResponse;
  return proto.appointmentApi.RescheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.RescheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.RescheduleResponse}
 */
proto.appointmentApi.RescheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.ListDaysConflicts;
      reader.readMessage(value,proto.appointmentApi.ListDaysConflicts.deserializeBinaryFromReader);
      msg.addListConflicts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.RescheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.RescheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.RescheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.RescheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getListConflictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.ListDaysConflicts.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.RescheduleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.RescheduleResponse} returns this
 */
proto.appointmentApi.RescheduleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.RescheduleResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.RescheduleResponse} returns this
 */
proto.appointmentApi.RescheduleResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.RescheduleResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.RescheduleResponse} returns this
 */
proto.appointmentApi.RescheduleResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDaysConflicts list_conflicts = 4;
 * @return {!Array<!proto.appointmentApi.ListDaysConflicts>}
 */
proto.appointmentApi.RescheduleResponse.prototype.getListConflictsList = function() {
  return /** @type{!Array<!proto.appointmentApi.ListDaysConflicts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.ListDaysConflicts, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.ListDaysConflicts>} value
 * @return {!proto.appointmentApi.RescheduleResponse} returns this
*/
proto.appointmentApi.RescheduleResponse.prototype.setListConflictsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.ListDaysConflicts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.ListDaysConflicts}
 */
proto.appointmentApi.RescheduleResponse.prototype.addListConflicts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.ListDaysConflicts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.RescheduleResponse} returns this
 */
proto.appointmentApi.RescheduleResponse.prototype.clearListConflictsList = function() {
  return this.setListConflictsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.VerifyHolidayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.VerifyHolidayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.VerifyHolidayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyHolidayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceTypeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateComplete: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.VerifyHolidayRequest}
 */
proto.appointmentApi.VerifyHolidayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.VerifyHolidayRequest;
  return proto.appointmentApi.VerifyHolidayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.VerifyHolidayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.VerifyHolidayRequest}
 */
proto.appointmentApi.VerifyHolidayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateComplete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.VerifyHolidayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.VerifyHolidayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.VerifyHolidayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyHolidayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceTypeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateComplete();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string service_type_id = 1;
 * @return {string}
 */
proto.appointmentApi.VerifyHolidayRequest.prototype.getServiceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyHolidayRequest} returns this
 */
proto.appointmentApi.VerifyHolidayRequest.prototype.setServiceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date_complete = 2;
 * @return {string}
 */
proto.appointmentApi.VerifyHolidayRequest.prototype.getDateComplete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyHolidayRequest} returns this
 */
proto.appointmentApi.VerifyHolidayRequest.prototype.setDateComplete = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.VerifyHolidayResponse.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.VerifyHolidayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.VerifyHolidayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyHolidayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    listConflictsList: jspb.Message.toObjectList(msg.getListConflictsList(),
    proto.appointmentApi.ListDaysConflicts.toObject, includeInstance),
    holidaysList: jspb.Message.toObjectList(msg.getHolidaysList(),
    proto.appointmentApi.Holidays.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.VerifyHolidayResponse}
 */
proto.appointmentApi.VerifyHolidayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.VerifyHolidayResponse;
  return proto.appointmentApi.VerifyHolidayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.VerifyHolidayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.VerifyHolidayResponse}
 */
proto.appointmentApi.VerifyHolidayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.ListDaysConflicts;
      reader.readMessage(value,proto.appointmentApi.ListDaysConflicts.deserializeBinaryFromReader);
      msg.addListConflicts(value);
      break;
    case 5:
      var value = new proto.appointmentApi.Holidays;
      reader.readMessage(value,proto.appointmentApi.Holidays.deserializeBinaryFromReader);
      msg.addHolidays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.VerifyHolidayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.VerifyHolidayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyHolidayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getListConflictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.ListDaysConflicts.serializeBinaryToWriter
    );
  }
  f = message.getHolidaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.appointmentApi.Holidays.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.VerifyHolidayResponse} returns this
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyHolidayResponse} returns this
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyHolidayResponse} returns this
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDaysConflicts list_conflicts = 4;
 * @return {!Array<!proto.appointmentApi.ListDaysConflicts>}
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.getListConflictsList = function() {
  return /** @type{!Array<!proto.appointmentApi.ListDaysConflicts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.ListDaysConflicts, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.ListDaysConflicts>} value
 * @return {!proto.appointmentApi.VerifyHolidayResponse} returns this
*/
proto.appointmentApi.VerifyHolidayResponse.prototype.setListConflictsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.ListDaysConflicts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.ListDaysConflicts}
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.addListConflicts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.ListDaysConflicts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.VerifyHolidayResponse} returns this
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.clearListConflictsList = function() {
  return this.setListConflictsList([]);
};


/**
 * repeated Holidays holidays = 5;
 * @return {!Array<!proto.appointmentApi.Holidays>}
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.getHolidaysList = function() {
  return /** @type{!Array<!proto.appointmentApi.Holidays>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.Holidays, 5));
};


/**
 * @param {!Array<!proto.appointmentApi.Holidays>} value
 * @return {!proto.appointmentApi.VerifyHolidayResponse} returns this
*/
proto.appointmentApi.VerifyHolidayResponse.prototype.setHolidaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.appointmentApi.Holidays=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.Holidays}
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.addHolidays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.appointmentApi.Holidays, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.VerifyHolidayResponse} returns this
 */
proto.appointmentApi.VerifyHolidayResponse.prototype.clearHolidaysList = function() {
  return this.setHolidaysList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.Holidays.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.Holidays.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.Holidays} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.Holidays.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.Holidays}
 */
proto.appointmentApi.Holidays.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.Holidays;
  return proto.appointmentApi.Holidays.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.Holidays} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.Holidays}
 */
proto.appointmentApi.Holidays.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.Holidays.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.Holidays.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.Holidays} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.Holidays.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string day = 1;
 * @return {string}
 */
proto.appointmentApi.Holidays.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.Holidays} returns this
 */
proto.appointmentApi.Holidays.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.appointmentApi.Holidays.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.Holidays} returns this
 */
proto.appointmentApi.Holidays.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.appointmentApi.Holidays.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.Holidays} returns this
 */
proto.appointmentApi.Holidays.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.appointmentApi.Holidays.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.Holidays} returns this
 */
proto.appointmentApi.Holidays.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.appointmentApi.Holidays.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.Holidays} returns this
 */
proto.appointmentApi.Holidays.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.VerifyScheduleRegistryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.VerifyScheduleRegistryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.VerifyScheduleRegistryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyScheduleRegistryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.VerifyScheduleRegistryRequest}
 */
proto.appointmentApi.VerifyScheduleRegistryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.VerifyScheduleRegistryRequest;
  return proto.appointmentApi.VerifyScheduleRegistryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.VerifyScheduleRegistryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.VerifyScheduleRegistryRequest}
 */
proto.appointmentApi.VerifyScheduleRegistryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.VerifyScheduleRegistryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.VerifyScheduleRegistryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.VerifyScheduleRegistryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyScheduleRegistryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.appointmentApi.VerifyScheduleRegistryRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyScheduleRegistryRequest} returns this
 */
proto.appointmentApi.VerifyScheduleRegistryRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.appointmentApi.VerifyScheduleRegistryRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyScheduleRegistryRequest} returns this
 */
proto.appointmentApi.VerifyScheduleRegistryRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.appointmentApi.VerifyScheduleRegistryRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyScheduleRegistryRequest} returns this
 */
proto.appointmentApi.VerifyScheduleRegistryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.VerifyScheduleRegistryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.VerifyScheduleRegistryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patient: (f = msg.getPatient()) && proto.appointmentApi.PatientSchedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.VerifyScheduleRegistryResponse}
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.VerifyScheduleRegistryResponse;
  return proto.appointmentApi.VerifyScheduleRegistryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.VerifyScheduleRegistryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.VerifyScheduleRegistryResponse}
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.PatientSchedule;
      reader.readMessage(value,proto.appointmentApi.PatientSchedule.deserializeBinaryFromReader);
      msg.setPatient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.VerifyScheduleRegistryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.VerifyScheduleRegistryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatient();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.appointmentApi.PatientSchedule.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.VerifyScheduleRegistryResponse} returns this
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyScheduleRegistryResponse} returns this
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyScheduleRegistryResponse} returns this
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PatientSchedule patient = 4;
 * @return {?proto.appointmentApi.PatientSchedule}
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.prototype.getPatient = function() {
  return /** @type{?proto.appointmentApi.PatientSchedule} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.PatientSchedule, 4));
};


/**
 * @param {?proto.appointmentApi.PatientSchedule|undefined} value
 * @return {!proto.appointmentApi.VerifyScheduleRegistryResponse} returns this
*/
proto.appointmentApi.VerifyScheduleRegistryResponse.prototype.setPatient = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.VerifyScheduleRegistryResponse} returns this
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.prototype.clearPatient = function() {
  return this.setPatient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.VerifyScheduleRegistryResponse.prototype.hasPatient = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PatientSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PatientSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PatientSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    patientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceMode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    placeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    placeAddress: jspb.Message.getFieldWithDefault(msg, 7, ""),
    appointmentOld: (f = msg.getAppointmentOld()) && proto.appointmentApi.AppointmentReschedule.toObject(includeInstance, f),
    appointmentNew: (f = msg.getAppointmentNew()) && proto.appointmentApi.AppointmentReschedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PatientSchedule}
 */
proto.appointmentApi.PatientSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PatientSchedule;
  return proto.appointmentApi.PatientSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PatientSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PatientSchedule}
 */
proto.appointmentApi.PatientSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceMode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceAddress(value);
      break;
    case 8:
      var value = new proto.appointmentApi.AppointmentReschedule;
      reader.readMessage(value,proto.appointmentApi.AppointmentReschedule.deserializeBinaryFromReader);
      msg.setAppointmentOld(value);
      break;
    case 9:
      var value = new proto.appointmentApi.AppointmentReschedule;
      reader.readMessage(value,proto.appointmentApi.AppointmentReschedule.deserializeBinaryFromReader);
      msg.setAppointmentNew(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PatientSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PatientSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PatientSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceMode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlaceAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAppointmentOld();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.appointmentApi.AppointmentReschedule.serializeBinaryToWriter
    );
  }
  f = message.getAppointmentNew();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.appointmentApi.AppointmentReschedule.serializeBinaryToWriter
    );
  }
};


/**
 * optional string patient_id = 1;
 * @return {string}
 */
proto.appointmentApi.PatientSchedule.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientSchedule} returns this
 */
proto.appointmentApi.PatientSchedule.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patient_name = 2;
 * @return {string}
 */
proto.appointmentApi.PatientSchedule.prototype.getPatientName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientSchedule} returns this
 */
proto.appointmentApi.PatientSchedule.prototype.setPatientName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string service_name = 3;
 * @return {string}
 */
proto.appointmentApi.PatientSchedule.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientSchedule} returns this
 */
proto.appointmentApi.PatientSchedule.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string service_mode = 4;
 * @return {string}
 */
proto.appointmentApi.PatientSchedule.prototype.getServiceMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientSchedule} returns this
 */
proto.appointmentApi.PatientSchedule.prototype.setServiceMode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string place_id = 5;
 * @return {string}
 */
proto.appointmentApi.PatientSchedule.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientSchedule} returns this
 */
proto.appointmentApi.PatientSchedule.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string place_name = 6;
 * @return {string}
 */
proto.appointmentApi.PatientSchedule.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientSchedule} returns this
 */
proto.appointmentApi.PatientSchedule.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string place_address = 7;
 * @return {string}
 */
proto.appointmentApi.PatientSchedule.prototype.getPlaceAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientSchedule} returns this
 */
proto.appointmentApi.PatientSchedule.prototype.setPlaceAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional AppointmentReschedule appointment_old = 8;
 * @return {?proto.appointmentApi.AppointmentReschedule}
 */
proto.appointmentApi.PatientSchedule.prototype.getAppointmentOld = function() {
  return /** @type{?proto.appointmentApi.AppointmentReschedule} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.AppointmentReschedule, 8));
};


/**
 * @param {?proto.appointmentApi.AppointmentReschedule|undefined} value
 * @return {!proto.appointmentApi.PatientSchedule} returns this
*/
proto.appointmentApi.PatientSchedule.prototype.setAppointmentOld = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.PatientSchedule} returns this
 */
proto.appointmentApi.PatientSchedule.prototype.clearAppointmentOld = function() {
  return this.setAppointmentOld(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.PatientSchedule.prototype.hasAppointmentOld = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional AppointmentReschedule appointment_new = 9;
 * @return {?proto.appointmentApi.AppointmentReschedule}
 */
proto.appointmentApi.PatientSchedule.prototype.getAppointmentNew = function() {
  return /** @type{?proto.appointmentApi.AppointmentReschedule} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.AppointmentReschedule, 9));
};


/**
 * @param {?proto.appointmentApi.AppointmentReschedule|undefined} value
 * @return {!proto.appointmentApi.PatientSchedule} returns this
*/
proto.appointmentApi.PatientSchedule.prototype.setAppointmentNew = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.PatientSchedule} returns this
 */
proto.appointmentApi.PatientSchedule.prototype.clearAppointmentNew = function() {
  return this.setAppointmentNew(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.PatientSchedule.prototype.hasAppointmentNew = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PatientReschedule.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PatientReschedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PatientReschedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientReschedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    patientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceMode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    placeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    placeAddress: jspb.Message.getFieldWithDefault(msg, 7, ""),
    appointment: (f = msg.getAppointment()) && proto.appointmentApi.AppointmentReschedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PatientReschedule}
 */
proto.appointmentApi.PatientReschedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PatientReschedule;
  return proto.appointmentApi.PatientReschedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PatientReschedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PatientReschedule}
 */
proto.appointmentApi.PatientReschedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceMode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceAddress(value);
      break;
    case 8:
      var value = new proto.appointmentApi.AppointmentReschedule;
      reader.readMessage(value,proto.appointmentApi.AppointmentReschedule.deserializeBinaryFromReader);
      msg.setAppointment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PatientReschedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PatientReschedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PatientReschedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientReschedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceMode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlaceAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAppointment();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.appointmentApi.AppointmentReschedule.serializeBinaryToWriter
    );
  }
};


/**
 * optional string patient_id = 1;
 * @return {string}
 */
proto.appointmentApi.PatientReschedule.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedule} returns this
 */
proto.appointmentApi.PatientReschedule.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patient_name = 2;
 * @return {string}
 */
proto.appointmentApi.PatientReschedule.prototype.getPatientName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedule} returns this
 */
proto.appointmentApi.PatientReschedule.prototype.setPatientName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string service_name = 3;
 * @return {string}
 */
proto.appointmentApi.PatientReschedule.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedule} returns this
 */
proto.appointmentApi.PatientReschedule.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string service_mode = 4;
 * @return {string}
 */
proto.appointmentApi.PatientReschedule.prototype.getServiceMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedule} returns this
 */
proto.appointmentApi.PatientReschedule.prototype.setServiceMode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string place_id = 5;
 * @return {string}
 */
proto.appointmentApi.PatientReschedule.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedule} returns this
 */
proto.appointmentApi.PatientReschedule.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string place_name = 6;
 * @return {string}
 */
proto.appointmentApi.PatientReschedule.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedule} returns this
 */
proto.appointmentApi.PatientReschedule.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string place_address = 7;
 * @return {string}
 */
proto.appointmentApi.PatientReschedule.prototype.getPlaceAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedule} returns this
 */
proto.appointmentApi.PatientReschedule.prototype.setPlaceAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional AppointmentReschedule appointment = 8;
 * @return {?proto.appointmentApi.AppointmentReschedule}
 */
proto.appointmentApi.PatientReschedule.prototype.getAppointment = function() {
  return /** @type{?proto.appointmentApi.AppointmentReschedule} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.AppointmentReschedule, 8));
};


/**
 * @param {?proto.appointmentApi.AppointmentReschedule|undefined} value
 * @return {!proto.appointmentApi.PatientReschedule} returns this
*/
proto.appointmentApi.PatientReschedule.prototype.setAppointment = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.PatientReschedule} returns this
 */
proto.appointmentApi.PatientReschedule.prototype.clearAppointment = function() {
  return this.setAppointment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.PatientReschedule.prototype.hasAppointment = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AppointmentReschedule.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AppointmentReschedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AppointmentReschedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AppointmentReschedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    appointmentStatus: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AppointmentReschedule}
 */
proto.appointmentApi.AppointmentReschedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AppointmentReschedule;
  return proto.appointmentApi.AppointmentReschedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AppointmentReschedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AppointmentReschedule}
 */
proto.appointmentApi.AppointmentReschedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AppointmentReschedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AppointmentReschedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AppointmentReschedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AppointmentReschedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAppointmentStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointmentApi.AppointmentReschedule.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentReschedule} returns this
 */
proto.appointmentApi.AppointmentReschedule.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date = 2;
 * @return {string}
 */
proto.appointmentApi.AppointmentReschedule.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentReschedule} returns this
 */
proto.appointmentApi.AppointmentReschedule.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string estimated_time = 5;
 * @return {string}
 */
proto.appointmentApi.AppointmentReschedule.prototype.getEstimatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentReschedule} returns this
 */
proto.appointmentApi.AppointmentReschedule.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string appointment_status = 6;
 * @return {string}
 */
proto.appointmentApi.AppointmentReschedule.prototype.getAppointmentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentReschedule} returns this
 */
proto.appointmentApi.AppointmentReschedule.prototype.setAppointmentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PatientActionReschedulingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PatientActionReschedulingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PatientActionReschedulingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientActionReschedulingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    token: jspb.Message.getFieldWithDefault(msg, 3, ""),
    action: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PatientActionReschedulingRequest}
 */
proto.appointmentApi.PatientActionReschedulingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PatientActionReschedulingRequest;
  return proto.appointmentApi.PatientActionReschedulingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PatientActionReschedulingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PatientActionReschedulingRequest}
 */
proto.appointmentApi.PatientActionReschedulingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PatientActionReschedulingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PatientActionReschedulingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PatientActionReschedulingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientActionReschedulingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAction();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.appointmentApi.PatientActionReschedulingRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientActionReschedulingRequest} returns this
 */
proto.appointmentApi.PatientActionReschedulingRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.appointmentApi.PatientActionReschedulingRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientActionReschedulingRequest} returns this
 */
proto.appointmentApi.PatientActionReschedulingRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.appointmentApi.PatientActionReschedulingRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientActionReschedulingRequest} returns this
 */
proto.appointmentApi.PatientActionReschedulingRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool action = 4;
 * @return {boolean}
 */
proto.appointmentApi.PatientActionReschedulingRequest.prototype.getAction = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.PatientActionReschedulingRequest} returns this
 */
proto.appointmentApi.PatientActionReschedulingRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PatientActionReschedulingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PatientActionReschedulingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PatientActionReschedulingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientActionReschedulingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PatientActionReschedulingResponse}
 */
proto.appointmentApi.PatientActionReschedulingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PatientActionReschedulingResponse;
  return proto.appointmentApi.PatientActionReschedulingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PatientActionReschedulingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PatientActionReschedulingResponse}
 */
proto.appointmentApi.PatientActionReschedulingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PatientActionReschedulingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PatientActionReschedulingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PatientActionReschedulingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientActionReschedulingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.PatientActionReschedulingResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.PatientActionReschedulingResponse} returns this
 */
proto.appointmentApi.PatientActionReschedulingResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.PatientActionReschedulingResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientActionReschedulingResponse} returns this
 */
proto.appointmentApi.PatientActionReschedulingResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.PatientActionReschedulingResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientActionReschedulingResponse} returns this
 */
proto.appointmentApi.PatientActionReschedulingResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AddPatientWaitingListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AddPatientWaitingListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AddPatientWaitingListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddPatientWaitingListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    descriptionWaitingList: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AddPatientWaitingListRequest}
 */
proto.appointmentApi.AddPatientWaitingListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AddPatientWaitingListRequest;
  return proto.appointmentApi.AddPatientWaitingListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AddPatientWaitingListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AddPatientWaitingListRequest}
 */
proto.appointmentApi.AddPatientWaitingListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptionWaitingList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AddPatientWaitingListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AddPatientWaitingListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AddPatientWaitingListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddPatientWaitingListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescriptionWaitingList();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointmentApi.AddPatientWaitingListRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddPatientWaitingListRequest} returns this
 */
proto.appointmentApi.AddPatientWaitingListRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description_waiting_list = 2;
 * @return {string}
 */
proto.appointmentApi.AddPatientWaitingListRequest.prototype.getDescriptionWaitingList = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddPatientWaitingListRequest} returns this
 */
proto.appointmentApi.AddPatientWaitingListRequest.prototype.setDescriptionWaitingList = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AddPatientWaitingListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AddPatientWaitingListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AddPatientWaitingListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddPatientWaitingListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AddPatientWaitingListResponse}
 */
proto.appointmentApi.AddPatientWaitingListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AddPatientWaitingListResponse;
  return proto.appointmentApi.AddPatientWaitingListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AddPatientWaitingListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AddPatientWaitingListResponse}
 */
proto.appointmentApi.AddPatientWaitingListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AddPatientWaitingListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AddPatientWaitingListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AddPatientWaitingListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AddPatientWaitingListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.AddPatientWaitingListResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.AddPatientWaitingListResponse} returns this
 */
proto.appointmentApi.AddPatientWaitingListResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.AddPatientWaitingListResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddPatientWaitingListResponse} returns this
 */
proto.appointmentApi.AddPatientWaitingListResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.AddPatientWaitingListResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AddPatientWaitingListResponse} returns this
 */
proto.appointmentApi.AddPatientWaitingListResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.RescheduleAppointmentProfessionalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.RescheduleAppointmentProfessionalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.RescheduleAppointmentProfessionalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.RescheduleAppointmentProfessionalRequest}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.RescheduleAppointmentProfessionalRequest;
  return proto.appointmentApi.RescheduleAppointmentProfessionalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.RescheduleAppointmentProfessionalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.RescheduleAppointmentProfessionalRequest}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.RescheduleAppointmentProfessionalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.RescheduleAppointmentProfessionalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.RescheduleAppointmentProfessionalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.RescheduleAppointmentProfessionalRequest} returns this
 */
proto.appointmentApi.RescheduleAppointmentProfessionalRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initial_date = 2;
 * @return {string}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalRequest.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.RescheduleAppointmentProfessionalRequest} returns this
 */
proto.appointmentApi.RescheduleAppointmentProfessionalRequest.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.RescheduleAppointmentProfessionalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.RescheduleAppointmentProfessionalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    listConflictsList: jspb.Message.toObjectList(msg.getListConflictsList(),
    proto.appointmentApi.ListDaysConflicts.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.RescheduleAppointmentProfessionalResponse}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.RescheduleAppointmentProfessionalResponse;
  return proto.appointmentApi.RescheduleAppointmentProfessionalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.RescheduleAppointmentProfessionalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.RescheduleAppointmentProfessionalResponse}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.ListDaysConflicts;
      reader.readMessage(value,proto.appointmentApi.ListDaysConflicts.deserializeBinaryFromReader);
      msg.addListConflicts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.RescheduleAppointmentProfessionalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.RescheduleAppointmentProfessionalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getListConflictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.ListDaysConflicts.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.RescheduleAppointmentProfessionalResponse} returns this
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.RescheduleAppointmentProfessionalResponse} returns this
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.RescheduleAppointmentProfessionalResponse} returns this
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDaysConflicts list_conflicts = 4;
 * @return {!Array<!proto.appointmentApi.ListDaysConflicts>}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.prototype.getListConflictsList = function() {
  return /** @type{!Array<!proto.appointmentApi.ListDaysConflicts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.ListDaysConflicts, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.ListDaysConflicts>} value
 * @return {!proto.appointmentApi.RescheduleAppointmentProfessionalResponse} returns this
*/
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.prototype.setListConflictsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.ListDaysConflicts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.ListDaysConflicts}
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.prototype.addListConflicts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.ListDaysConflicts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.RescheduleAppointmentProfessionalResponse} returns this
 */
proto.appointmentApi.RescheduleAppointmentProfessionalResponse.prototype.clearListConflictsList = function() {
  return this.setListConflictsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.VerifyReschedulingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.VerifyReschedulingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.VerifyReschedulingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyReschedulingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.VerifyReschedulingRequest}
 */
proto.appointmentApi.VerifyReschedulingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.VerifyReschedulingRequest;
  return proto.appointmentApi.VerifyReschedulingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.VerifyReschedulingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.VerifyReschedulingRequest}
 */
proto.appointmentApi.VerifyReschedulingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.VerifyReschedulingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.VerifyReschedulingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.VerifyReschedulingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyReschedulingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.appointmentApi.VerifyReschedulingRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyReschedulingRequest} returns this
 */
proto.appointmentApi.VerifyReschedulingRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.appointmentApi.VerifyReschedulingRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyReschedulingRequest} returns this
 */
proto.appointmentApi.VerifyReschedulingRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.appointmentApi.VerifyReschedulingRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyReschedulingRequest} returns this
 */
proto.appointmentApi.VerifyReschedulingRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.VerifyReschedulingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.VerifyReschedulingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.VerifyReschedulingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyReschedulingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patient: (f = msg.getPatient()) && proto.appointmentApi.PatientReschedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.VerifyReschedulingResponse}
 */
proto.appointmentApi.VerifyReschedulingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.VerifyReschedulingResponse;
  return proto.appointmentApi.VerifyReschedulingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.VerifyReschedulingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.VerifyReschedulingResponse}
 */
proto.appointmentApi.VerifyReschedulingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.PatientReschedule;
      reader.readMessage(value,proto.appointmentApi.PatientReschedule.deserializeBinaryFromReader);
      msg.setPatient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.VerifyReschedulingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.VerifyReschedulingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.VerifyReschedulingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyReschedulingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatient();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.appointmentApi.PatientReschedule.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.VerifyReschedulingResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.VerifyReschedulingResponse} returns this
 */
proto.appointmentApi.VerifyReschedulingResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.VerifyReschedulingResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyReschedulingResponse} returns this
 */
proto.appointmentApi.VerifyReschedulingResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.VerifyReschedulingResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyReschedulingResponse} returns this
 */
proto.appointmentApi.VerifyReschedulingResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PatientReschedule patient = 4;
 * @return {?proto.appointmentApi.PatientReschedule}
 */
proto.appointmentApi.VerifyReschedulingResponse.prototype.getPatient = function() {
  return /** @type{?proto.appointmentApi.PatientReschedule} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.PatientReschedule, 4));
};


/**
 * @param {?proto.appointmentApi.PatientReschedule|undefined} value
 * @return {!proto.appointmentApi.VerifyReschedulingResponse} returns this
*/
proto.appointmentApi.VerifyReschedulingResponse.prototype.setPatient = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.VerifyReschedulingResponse} returns this
 */
proto.appointmentApi.VerifyReschedulingResponse.prototype.clearPatient = function() {
  return this.setPatient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.VerifyReschedulingResponse.prototype.hasPatient = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PatientReschedulingDecisionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PatientReschedulingDecisionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    token: jspb.Message.getFieldWithDefault(msg, 3, ""),
    action: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    initialDate: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PatientReschedulingDecisionRequest}
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PatientReschedulingDecisionRequest;
  return proto.appointmentApi.PatientReschedulingDecisionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PatientReschedulingDecisionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PatientReschedulingDecisionRequest}
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAction(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PatientReschedulingDecisionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PatientReschedulingDecisionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAction();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedulingDecisionRequest} returns this
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedulingDecisionRequest} returns this
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedulingDecisionRequest} returns this
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool action = 4;
 * @return {boolean}
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.prototype.getAction = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.PatientReschedulingDecisionRequest} returns this
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string initial_date = 5;
 * @return {string}
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedulingDecisionRequest} returns this
 */
proto.appointmentApi.PatientReschedulingDecisionRequest.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PatientReschedulingDecisionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PatientReschedulingDecisionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    listConflictsList: jspb.Message.toObjectList(msg.getListConflictsList(),
    proto.appointmentApi.ListDaysConflicts.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PatientReschedulingDecisionResponse}
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PatientReschedulingDecisionResponse;
  return proto.appointmentApi.PatientReschedulingDecisionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PatientReschedulingDecisionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PatientReschedulingDecisionResponse}
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.ListDaysConflicts;
      reader.readMessage(value,proto.appointmentApi.ListDaysConflicts.deserializeBinaryFromReader);
      msg.addListConflicts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PatientReschedulingDecisionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PatientReschedulingDecisionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getListConflictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.ListDaysConflicts.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.PatientReschedulingDecisionResponse} returns this
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedulingDecisionResponse} returns this
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientReschedulingDecisionResponse} returns this
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDaysConflicts list_conflicts = 4;
 * @return {!Array<!proto.appointmentApi.ListDaysConflicts>}
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.prototype.getListConflictsList = function() {
  return /** @type{!Array<!proto.appointmentApi.ListDaysConflicts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.ListDaysConflicts, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.ListDaysConflicts>} value
 * @return {!proto.appointmentApi.PatientReschedulingDecisionResponse} returns this
*/
proto.appointmentApi.PatientReschedulingDecisionResponse.prototype.setListConflictsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.ListDaysConflicts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.ListDaysConflicts}
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.prototype.addListConflicts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.ListDaysConflicts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.PatientReschedulingDecisionResponse} returns this
 */
proto.appointmentApi.PatientReschedulingDecisionResponse.prototype.clearListConflictsList = function() {
  return this.setListConflictsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PatientAppointmentDecisionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PatientAppointmentDecisionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    action: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PatientAppointmentDecisionRequest}
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PatientAppointmentDecisionRequest;
  return proto.appointmentApi.PatientAppointmentDecisionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PatientAppointmentDecisionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PatientAppointmentDecisionRequest}
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PatientAppointmentDecisionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PatientAppointmentDecisionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAction();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientAppointmentDecisionRequest} returns this
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patientId = 2;
 * @return {string}
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.prototype.getPatientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientAppointmentDecisionRequest} returns this
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.prototype.setPatientid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientAppointmentDecisionRequest} returns this
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool action = 4;
 * @return {boolean}
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.prototype.getAction = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.PatientAppointmentDecisionRequest} returns this
 */
proto.appointmentApi.PatientAppointmentDecisionRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PatientAppointmentDecisionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PatientAppointmentDecisionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PatientAppointmentDecisionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientAppointmentDecisionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PatientAppointmentDecisionResponse}
 */
proto.appointmentApi.PatientAppointmentDecisionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PatientAppointmentDecisionResponse;
  return proto.appointmentApi.PatientAppointmentDecisionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PatientAppointmentDecisionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PatientAppointmentDecisionResponse}
 */
proto.appointmentApi.PatientAppointmentDecisionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PatientAppointmentDecisionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PatientAppointmentDecisionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PatientAppointmentDecisionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientAppointmentDecisionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.PatientAppointmentDecisionResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.PatientAppointmentDecisionResponse} returns this
 */
proto.appointmentApi.PatientAppointmentDecisionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.PatientAppointmentDecisionResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientAppointmentDecisionResponse} returns this
 */
proto.appointmentApi.PatientAppointmentDecisionResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.PatientAppointmentDecisionResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientAppointmentDecisionResponse} returns this
 */
proto.appointmentApi.PatientAppointmentDecisionResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListPatientAppointmentDecisionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListPatientAppointmentDecisionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListPatientAppointmentDecisionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientAppointmentDecisionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListPatientAppointmentDecisionRequest}
 */
proto.appointmentApi.ListPatientAppointmentDecisionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListPatientAppointmentDecisionRequest;
  return proto.appointmentApi.ListPatientAppointmentDecisionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListPatientAppointmentDecisionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListPatientAppointmentDecisionRequest}
 */
proto.appointmentApi.ListPatientAppointmentDecisionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListPatientAppointmentDecisionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListPatientAppointmentDecisionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListPatientAppointmentDecisionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientAppointmentDecisionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentDecisionRequest.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentDecisionRequest} returns this
 */
proto.appointmentApi.ListPatientAppointmentDecisionRequest.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patientId = 2;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentDecisionRequest.prototype.getPatientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentDecisionRequest} returns this
 */
proto.appointmentApi.ListPatientAppointmentDecisionRequest.prototype.setPatientid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListPatientAppointmentDecisionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListPatientAppointmentDecisionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    appointment: (f = msg.getAppointment()) && proto.appointmentApi.ListAppointmentData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListPatientAppointmentDecisionResponse}
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListPatientAppointmentDecisionResponse;
  return proto.appointmentApi.ListPatientAppointmentDecisionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListPatientAppointmentDecisionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListPatientAppointmentDecisionResponse}
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.ListAppointmentData;
      reader.readMessage(value,proto.appointmentApi.ListAppointmentData.deserializeBinaryFromReader);
      msg.setAppointment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListPatientAppointmentDecisionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListPatientAppointmentDecisionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAppointment();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.appointmentApi.ListAppointmentData.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListPatientAppointmentDecisionResponse} returns this
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentDecisionResponse} returns this
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientAppointmentDecisionResponse} returns this
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ListAppointmentData appointment = 4;
 * @return {?proto.appointmentApi.ListAppointmentData}
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.prototype.getAppointment = function() {
  return /** @type{?proto.appointmentApi.ListAppointmentData} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.ListAppointmentData, 4));
};


/**
 * @param {?proto.appointmentApi.ListAppointmentData|undefined} value
 * @return {!proto.appointmentApi.ListPatientAppointmentDecisionResponse} returns this
*/
proto.appointmentApi.ListPatientAppointmentDecisionResponse.prototype.setAppointment = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.ListPatientAppointmentDecisionResponse} returns this
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.prototype.clearAppointment = function() {
  return this.setAppointment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.ListPatientAppointmentDecisionResponse.prototype.hasAppointment = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListAppointmentData.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListAppointmentData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListAppointmentData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListAppointmentData.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    professionalname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    date: jspb.Message.getFieldWithDefault(msg, 5, ""),
    initialhour: jspb.Message.getFieldWithDefault(msg, 6, ""),
    place: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListAppointmentData}
 */
proto.appointmentApi.ListAppointmentData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListAppointmentData;
  return proto.appointmentApi.ListAppointmentData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListAppointmentData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListAppointmentData}
 */
proto.appointmentApi.ListAppointmentData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialhour(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListAppointmentData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListAppointmentData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListAppointmentData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListAppointmentData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfessionalname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInitialhour();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlace();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.appointmentApi.ListAppointmentData.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListAppointmentData} returns this
 */
proto.appointmentApi.ListAppointmentData.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patientId = 2;
 * @return {string}
 */
proto.appointmentApi.ListAppointmentData.prototype.getPatientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListAppointmentData} returns this
 */
proto.appointmentApi.ListAppointmentData.prototype.setPatientid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.appointmentApi.ListAppointmentData.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListAppointmentData} returns this
 */
proto.appointmentApi.ListAppointmentData.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string professionalName = 4;
 * @return {string}
 */
proto.appointmentApi.ListAppointmentData.prototype.getProfessionalname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListAppointmentData} returns this
 */
proto.appointmentApi.ListAppointmentData.prototype.setProfessionalname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date = 5;
 * @return {string}
 */
proto.appointmentApi.ListAppointmentData.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListAppointmentData} returns this
 */
proto.appointmentApi.ListAppointmentData.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string initialHour = 6;
 * @return {string}
 */
proto.appointmentApi.ListAppointmentData.prototype.getInitialhour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListAppointmentData} returns this
 */
proto.appointmentApi.ListAppointmentData.prototype.setInitialhour = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string place = 7;
 * @return {string}
 */
proto.appointmentApi.ListAppointmentData.prototype.getPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListAppointmentData} returns this
 */
proto.appointmentApi.ListAppointmentData.prototype.setPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.CancelAppointmentProfessionalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.CancelAppointmentProfessionalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.CancelAppointmentProfessionalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.CancelAppointmentProfessionalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.CancelAppointmentProfessionalRequest}
 */
proto.appointmentApi.CancelAppointmentProfessionalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.CancelAppointmentProfessionalRequest;
  return proto.appointmentApi.CancelAppointmentProfessionalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.CancelAppointmentProfessionalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.CancelAppointmentProfessionalRequest}
 */
proto.appointmentApi.CancelAppointmentProfessionalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.CancelAppointmentProfessionalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.CancelAppointmentProfessionalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.CancelAppointmentProfessionalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.CancelAppointmentProfessionalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointmentApi.CancelAppointmentProfessionalRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.CancelAppointmentProfessionalRequest} returns this
 */
proto.appointmentApi.CancelAppointmentProfessionalRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.appointmentApi.CancelAppointmentProfessionalRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.CancelAppointmentProfessionalRequest} returns this
 */
proto.appointmentApi.CancelAppointmentProfessionalRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.CancelAppointmentProfessionalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.CancelAppointmentProfessionalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.CancelAppointmentProfessionalResponse}
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.CancelAppointmentProfessionalResponse;
  return proto.appointmentApi.CancelAppointmentProfessionalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.CancelAppointmentProfessionalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.CancelAppointmentProfessionalResponse}
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.CancelAppointmentProfessionalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.CancelAppointmentProfessionalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.CancelAppointmentProfessionalResponse} returns this
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.CancelAppointmentProfessionalResponse} returns this
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.CancelAppointmentProfessionalResponse} returns this
 */
proto.appointmentApi.CancelAppointmentProfessionalResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.FutureMedicalCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.FutureMedicalCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.FutureMedicalCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.FutureMedicalCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.FutureMedicalCareRequest}
 */
proto.appointmentApi.FutureMedicalCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.FutureMedicalCareRequest;
  return proto.appointmentApi.FutureMedicalCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.FutureMedicalCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.FutureMedicalCareRequest}
 */
proto.appointmentApi.FutureMedicalCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.FutureMedicalCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.FutureMedicalCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.FutureMedicalCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.FutureMedicalCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.appointmentApi.FutureMedicalCareRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.FutureMedicalCareRequest} returns this
 */
proto.appointmentApi.FutureMedicalCareRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.FutureMedicalCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.FutureMedicalCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.FutureMedicalCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.FutureMedicalCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.FutureMedicalCareResponse}
 */
proto.appointmentApi.FutureMedicalCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.FutureMedicalCareResponse;
  return proto.appointmentApi.FutureMedicalCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.FutureMedicalCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.FutureMedicalCareResponse}
 */
proto.appointmentApi.FutureMedicalCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.FutureMedicalCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.FutureMedicalCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.FutureMedicalCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.FutureMedicalCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.FutureMedicalCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.FutureMedicalCareResponse} returns this
 */
proto.appointmentApi.FutureMedicalCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.FutureMedicalCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.FutureMedicalCareResponse} returns this
 */
proto.appointmentApi.FutureMedicalCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.FutureMedicalCareResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.FutureMedicalCareResponse} returns this
 */
proto.appointmentApi.FutureMedicalCareResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListFutureMedicalCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListFutureMedicalCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListFutureMedicalCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListFutureMedicalCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialdate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    finaldate: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListFutureMedicalCareRequest}
 */
proto.appointmentApi.ListFutureMedicalCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListFutureMedicalCareRequest;
  return proto.appointmentApi.ListFutureMedicalCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListFutureMedicalCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListFutureMedicalCareRequest}
 */
proto.appointmentApi.ListFutureMedicalCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialdate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinaldate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListFutureMedicalCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListFutureMedicalCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListFutureMedicalCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListFutureMedicalCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialdate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFinaldate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.appointmentApi.ListFutureMedicalCareRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListFutureMedicalCareRequest} returns this
 */
proto.appointmentApi.ListFutureMedicalCareRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initialDate = 2;
 * @return {string}
 */
proto.appointmentApi.ListFutureMedicalCareRequest.prototype.getInitialdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListFutureMedicalCareRequest} returns this
 */
proto.appointmentApi.ListFutureMedicalCareRequest.prototype.setInitialdate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string finalDate = 3;
 * @return {string}
 */
proto.appointmentApi.ListFutureMedicalCareRequest.prototype.getFinaldate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListFutureMedicalCareRequest} returns this
 */
proto.appointmentApi.ListFutureMedicalCareRequest.prototype.setFinaldate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.ListFutureMedicalCareResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListFutureMedicalCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListFutureMedicalCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListFutureMedicalCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListFutureMedicalCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.appointmentApi.DateListAppointment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListFutureMedicalCareResponse}
 */
proto.appointmentApi.ListFutureMedicalCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListFutureMedicalCareResponse;
  return proto.appointmentApi.ListFutureMedicalCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListFutureMedicalCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListFutureMedicalCareResponse}
 */
proto.appointmentApi.ListFutureMedicalCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.DateListAppointment;
      reader.readMessage(value,proto.appointmentApi.DateListAppointment.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListFutureMedicalCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListFutureMedicalCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListFutureMedicalCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListFutureMedicalCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.DateListAppointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.ListFutureMedicalCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListFutureMedicalCareResponse} returns this
 */
proto.appointmentApi.ListFutureMedicalCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.ListFutureMedicalCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListFutureMedicalCareResponse} returns this
 */
proto.appointmentApi.ListFutureMedicalCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.ListFutureMedicalCareResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListFutureMedicalCareResponse} returns this
 */
proto.appointmentApi.ListFutureMedicalCareResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DateListAppointment dates = 4;
 * @return {!Array<!proto.appointmentApi.DateListAppointment>}
 */
proto.appointmentApi.ListFutureMedicalCareResponse.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.appointmentApi.DateListAppointment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.DateListAppointment, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.DateListAppointment>} value
 * @return {!proto.appointmentApi.ListFutureMedicalCareResponse} returns this
*/
proto.appointmentApi.ListFutureMedicalCareResponse.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.DateListAppointment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.DateListAppointment}
 */
proto.appointmentApi.ListFutureMedicalCareResponse.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.DateListAppointment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.ListFutureMedicalCareResponse} returns this
 */
proto.appointmentApi.ListFutureMedicalCareResponse.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.SearchListFutureMedicalCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.SearchListFutureMedicalCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialdate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    finaldate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    wordList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareRequest}
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.SearchListFutureMedicalCareRequest;
  return proto.appointmentApi.SearchListFutureMedicalCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.SearchListFutureMedicalCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareRequest}
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialdate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinaldate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.SearchListFutureMedicalCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.SearchListFutureMedicalCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialdate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFinaldate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWordList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initialDate = 2;
 * @return {string}
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.prototype.getInitialdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.prototype.setInitialdate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string finalDate = 3;
 * @return {string}
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.prototype.getFinaldate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.prototype.setFinaldate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string word = 4;
 * @return {!Array<string>}
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.prototype.getWordList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.prototype.setWordList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.prototype.addWord = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchListFutureMedicalCareRequest.prototype.clearWordList = function() {
  return this.setWordList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.SearchListFutureMedicalCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.SearchListFutureMedicalCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.appointmentApi.DateListAppointment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareResponse}
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.SearchListFutureMedicalCareResponse;
  return proto.appointmentApi.SearchListFutureMedicalCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.SearchListFutureMedicalCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareResponse}
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.DateListAppointment;
      reader.readMessage(value,proto.appointmentApi.DateListAppointment.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.SearchListFutureMedicalCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.SearchListFutureMedicalCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.DateListAppointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareResponse} returns this
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareResponse} returns this
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareResponse} returns this
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DateListAppointment dates = 4;
 * @return {!Array<!proto.appointmentApi.DateListAppointment>}
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.appointmentApi.DateListAppointment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.DateListAppointment, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.DateListAppointment>} value
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareResponse} returns this
*/
proto.appointmentApi.SearchListFutureMedicalCareResponse.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.DateListAppointment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.DateListAppointment}
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.DateListAppointment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.SearchListFutureMedicalCareResponse} returns this
 */
proto.appointmentApi.SearchListFutureMedicalCareResponse.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PreviousMedicalCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PreviousMedicalCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PreviousMedicalCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PreviousMedicalCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PreviousMedicalCareRequest}
 */
proto.appointmentApi.PreviousMedicalCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PreviousMedicalCareRequest;
  return proto.appointmentApi.PreviousMedicalCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PreviousMedicalCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PreviousMedicalCareRequest}
 */
proto.appointmentApi.PreviousMedicalCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PreviousMedicalCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PreviousMedicalCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PreviousMedicalCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PreviousMedicalCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.appointmentApi.PreviousMedicalCareRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PreviousMedicalCareRequest} returns this
 */
proto.appointmentApi.PreviousMedicalCareRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PreviousMedicalCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PreviousMedicalCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PreviousMedicalCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PreviousMedicalCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PreviousMedicalCareResponse}
 */
proto.appointmentApi.PreviousMedicalCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PreviousMedicalCareResponse;
  return proto.appointmentApi.PreviousMedicalCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PreviousMedicalCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PreviousMedicalCareResponse}
 */
proto.appointmentApi.PreviousMedicalCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PreviousMedicalCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PreviousMedicalCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PreviousMedicalCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PreviousMedicalCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.PreviousMedicalCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.PreviousMedicalCareResponse} returns this
 */
proto.appointmentApi.PreviousMedicalCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.PreviousMedicalCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PreviousMedicalCareResponse} returns this
 */
proto.appointmentApi.PreviousMedicalCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.PreviousMedicalCareResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PreviousMedicalCareResponse} returns this
 */
proto.appointmentApi.PreviousMedicalCareResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListPreviousMedicalCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListPreviousMedicalCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListPreviousMedicalCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPreviousMedicalCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialdate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    finaldate: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListPreviousMedicalCareRequest}
 */
proto.appointmentApi.ListPreviousMedicalCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListPreviousMedicalCareRequest;
  return proto.appointmentApi.ListPreviousMedicalCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListPreviousMedicalCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListPreviousMedicalCareRequest}
 */
proto.appointmentApi.ListPreviousMedicalCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialdate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinaldate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListPreviousMedicalCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListPreviousMedicalCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListPreviousMedicalCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPreviousMedicalCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialdate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFinaldate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.appointmentApi.ListPreviousMedicalCareRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPreviousMedicalCareRequest} returns this
 */
proto.appointmentApi.ListPreviousMedicalCareRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initialDate = 2;
 * @return {string}
 */
proto.appointmentApi.ListPreviousMedicalCareRequest.prototype.getInitialdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPreviousMedicalCareRequest} returns this
 */
proto.appointmentApi.ListPreviousMedicalCareRequest.prototype.setInitialdate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string finalDate = 3;
 * @return {string}
 */
proto.appointmentApi.ListPreviousMedicalCareRequest.prototype.getFinaldate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPreviousMedicalCareRequest} returns this
 */
proto.appointmentApi.ListPreviousMedicalCareRequest.prototype.setFinaldate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListPreviousMedicalCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListPreviousMedicalCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.appointmentApi.DateListAppointment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListPreviousMedicalCareResponse}
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListPreviousMedicalCareResponse;
  return proto.appointmentApi.ListPreviousMedicalCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListPreviousMedicalCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListPreviousMedicalCareResponse}
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.DateListAppointment;
      reader.readMessage(value,proto.appointmentApi.DateListAppointment.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListPreviousMedicalCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListPreviousMedicalCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.DateListAppointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListPreviousMedicalCareResponse} returns this
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPreviousMedicalCareResponse} returns this
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPreviousMedicalCareResponse} returns this
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DateListAppointment dates = 4;
 * @return {!Array<!proto.appointmentApi.DateListAppointment>}
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.appointmentApi.DateListAppointment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.DateListAppointment, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.DateListAppointment>} value
 * @return {!proto.appointmentApi.ListPreviousMedicalCareResponse} returns this
*/
proto.appointmentApi.ListPreviousMedicalCareResponse.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.DateListAppointment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.DateListAppointment}
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.DateListAppointment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.ListPreviousMedicalCareResponse} returns this
 */
proto.appointmentApi.ListPreviousMedicalCareResponse.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.SearchListPreviousMedicalCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.SearchListPreviousMedicalCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialdate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    finaldate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    wordList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareRequest}
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.SearchListPreviousMedicalCareRequest;
  return proto.appointmentApi.SearchListPreviousMedicalCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.SearchListPreviousMedicalCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareRequest}
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialdate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinaldate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.SearchListPreviousMedicalCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.SearchListPreviousMedicalCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialdate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFinaldate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWordList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initialDate = 2;
 * @return {string}
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.prototype.getInitialdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.prototype.setInitialdate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string finalDate = 3;
 * @return {string}
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.prototype.getFinaldate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.prototype.setFinaldate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string word = 4;
 * @return {!Array<string>}
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.prototype.getWordList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.prototype.setWordList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.prototype.addWord = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchListPreviousMedicalCareRequest.prototype.clearWordList = function() {
  return this.setWordList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.SearchListPreviousMedicalCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.SearchListPreviousMedicalCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.appointmentApi.DateListAppointment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareResponse}
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.SearchListPreviousMedicalCareResponse;
  return proto.appointmentApi.SearchListPreviousMedicalCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.SearchListPreviousMedicalCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareResponse}
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.DateListAppointment;
      reader.readMessage(value,proto.appointmentApi.DateListAppointment.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.SearchListPreviousMedicalCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.SearchListPreviousMedicalCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.DateListAppointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareResponse} returns this
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareResponse} returns this
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareResponse} returns this
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DateListAppointment dates = 4;
 * @return {!Array<!proto.appointmentApi.DateListAppointment>}
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.appointmentApi.DateListAppointment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.DateListAppointment, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.DateListAppointment>} value
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareResponse} returns this
*/
proto.appointmentApi.SearchListPreviousMedicalCareResponse.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.DateListAppointment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.DateListAppointment}
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.DateListAppointment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.SearchListPreviousMedicalCareResponse} returns this
 */
proto.appointmentApi.SearchListPreviousMedicalCareResponse.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.TodayMedicalCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.TodayMedicalCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.TodayMedicalCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.TodayMedicalCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.TodayMedicalCareRequest}
 */
proto.appointmentApi.TodayMedicalCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.TodayMedicalCareRequest;
  return proto.appointmentApi.TodayMedicalCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.TodayMedicalCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.TodayMedicalCareRequest}
 */
proto.appointmentApi.TodayMedicalCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.TodayMedicalCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.TodayMedicalCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.TodayMedicalCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.TodayMedicalCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.appointmentApi.TodayMedicalCareRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.TodayMedicalCareRequest} returns this
 */
proto.appointmentApi.TodayMedicalCareRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.TodayMedicalCareResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.TodayMedicalCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.TodayMedicalCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.TodayMedicalCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.TodayMedicalCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.appointmentApi.DateList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.TodayMedicalCareResponse}
 */
proto.appointmentApi.TodayMedicalCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.TodayMedicalCareResponse;
  return proto.appointmentApi.TodayMedicalCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.TodayMedicalCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.TodayMedicalCareResponse}
 */
proto.appointmentApi.TodayMedicalCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.DateList;
      reader.readMessage(value,proto.appointmentApi.DateList.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.TodayMedicalCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.TodayMedicalCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.TodayMedicalCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.TodayMedicalCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.DateList.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.TodayMedicalCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.TodayMedicalCareResponse} returns this
 */
proto.appointmentApi.TodayMedicalCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.TodayMedicalCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.TodayMedicalCareResponse} returns this
 */
proto.appointmentApi.TodayMedicalCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.TodayMedicalCareResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.TodayMedicalCareResponse} returns this
 */
proto.appointmentApi.TodayMedicalCareResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DateList dates = 4;
 * @return {!Array<!proto.appointmentApi.DateList>}
 */
proto.appointmentApi.TodayMedicalCareResponse.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.appointmentApi.DateList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.DateList, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.DateList>} value
 * @return {!proto.appointmentApi.TodayMedicalCareResponse} returns this
*/
proto.appointmentApi.TodayMedicalCareResponse.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.DateList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.DateList}
 */
proto.appointmentApi.TodayMedicalCareResponse.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.DateList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.TodayMedicalCareResponse} returns this
 */
proto.appointmentApi.TodayMedicalCareResponse.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.SearchTodayMedicalCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.SearchTodayMedicalCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    wordList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.SearchTodayMedicalCareRequest}
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.SearchTodayMedicalCareRequest;
  return proto.appointmentApi.SearchTodayMedicalCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.SearchTodayMedicalCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.SearchTodayMedicalCareRequest}
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.SearchTodayMedicalCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.SearchTodayMedicalCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWordList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchTodayMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string word = 2;
 * @return {!Array<string>}
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.prototype.getWordList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.appointmentApi.SearchTodayMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.prototype.setWordList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.SearchTodayMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.prototype.addWord = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.SearchTodayMedicalCareRequest} returns this
 */
proto.appointmentApi.SearchTodayMedicalCareRequest.prototype.clearWordList = function() {
  return this.setWordList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.SearchTodayMedicalCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.SearchTodayMedicalCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.appointmentApi.DateList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.SearchTodayMedicalCareResponse}
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.SearchTodayMedicalCareResponse;
  return proto.appointmentApi.SearchTodayMedicalCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.SearchTodayMedicalCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.SearchTodayMedicalCareResponse}
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.DateList;
      reader.readMessage(value,proto.appointmentApi.DateList.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.SearchTodayMedicalCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.SearchTodayMedicalCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.DateList.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.SearchTodayMedicalCareResponse} returns this
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchTodayMedicalCareResponse} returns this
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchTodayMedicalCareResponse} returns this
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DateList dates = 4;
 * @return {!Array<!proto.appointmentApi.DateList>}
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.appointmentApi.DateList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.DateList, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.DateList>} value
 * @return {!proto.appointmentApi.SearchTodayMedicalCareResponse} returns this
*/
proto.appointmentApi.SearchTodayMedicalCareResponse.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.DateList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.DateList}
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.DateList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.SearchTodayMedicalCareResponse} returns this
 */
proto.appointmentApi.SearchTodayMedicalCareResponse.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PatientCheckInRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PatientCheckInRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PatientCheckInRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientCheckInRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appointmentId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PatientCheckInRequest}
 */
proto.appointmentApi.PatientCheckInRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PatientCheckInRequest;
  return proto.appointmentApi.PatientCheckInRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PatientCheckInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PatientCheckInRequest}
 */
proto.appointmentApi.PatientCheckInRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PatientCheckInRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PatientCheckInRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PatientCheckInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientCheckInRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string professional_id = 1;
 * @return {string}
 */
proto.appointmentApi.PatientCheckInRequest.prototype.getProfessionalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientCheckInRequest} returns this
 */
proto.appointmentApi.PatientCheckInRequest.prototype.setProfessionalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string appointment_id = 2;
 * @return {string}
 */
proto.appointmentApi.PatientCheckInRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientCheckInRequest} returns this
 */
proto.appointmentApi.PatientCheckInRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PatientCheckInResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PatientCheckInResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PatientCheckInResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientCheckInResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PatientCheckInResponse}
 */
proto.appointmentApi.PatientCheckInResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PatientCheckInResponse;
  return proto.appointmentApi.PatientCheckInResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PatientCheckInResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PatientCheckInResponse}
 */
proto.appointmentApi.PatientCheckInResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PatientCheckInResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PatientCheckInResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PatientCheckInResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientCheckInResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.PatientCheckInResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.PatientCheckInResponse} returns this
 */
proto.appointmentApi.PatientCheckInResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.PatientCheckInResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientCheckInResponse} returns this
 */
proto.appointmentApi.PatientCheckInResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.PatientCheckInResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientCheckInResponse} returns this
 */
proto.appointmentApi.PatientCheckInResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ConferCheckInRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ConferCheckInRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ConferCheckInRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ConferCheckInRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    codeQrcode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ConferCheckInRequest}
 */
proto.appointmentApi.ConferCheckInRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ConferCheckInRequest;
  return proto.appointmentApi.ConferCheckInRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ConferCheckInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ConferCheckInRequest}
 */
proto.appointmentApi.ConferCheckInRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeQrcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ConferCheckInRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ConferCheckInRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ConferCheckInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ConferCheckInRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCodeQrcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointmentApi.ConferCheckInRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ConferCheckInRequest} returns this
 */
proto.appointmentApi.ConferCheckInRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code_QrCode = 2;
 * @return {string}
 */
proto.appointmentApi.ConferCheckInRequest.prototype.getCodeQrcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ConferCheckInRequest} returns this
 */
proto.appointmentApi.ConferCheckInRequest.prototype.setCodeQrcode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.ConferCheckInResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ConferCheckInResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ConferCheckInResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ConferCheckInResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ConferCheckInResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientList: jspb.Message.toObjectList(msg.getPatientList(),
    proto.appointmentApi.PatientInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ConferCheckInResponse}
 */
proto.appointmentApi.ConferCheckInResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ConferCheckInResponse;
  return proto.appointmentApi.ConferCheckInResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ConferCheckInResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ConferCheckInResponse}
 */
proto.appointmentApi.ConferCheckInResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.PatientInfo;
      reader.readMessage(value,proto.appointmentApi.PatientInfo.deserializeBinaryFromReader);
      msg.addPatient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ConferCheckInResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ConferCheckInResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ConferCheckInResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ConferCheckInResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.PatientInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.ConferCheckInResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ConferCheckInResponse} returns this
 */
proto.appointmentApi.ConferCheckInResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.ConferCheckInResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ConferCheckInResponse} returns this
 */
proto.appointmentApi.ConferCheckInResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.ConferCheckInResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ConferCheckInResponse} returns this
 */
proto.appointmentApi.ConferCheckInResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated PatientInfo patient = 4;
 * @return {!Array<!proto.appointmentApi.PatientInfo>}
 */
proto.appointmentApi.ConferCheckInResponse.prototype.getPatientList = function() {
  return /** @type{!Array<!proto.appointmentApi.PatientInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.PatientInfo, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.PatientInfo>} value
 * @return {!proto.appointmentApi.ConferCheckInResponse} returns this
*/
proto.appointmentApi.ConferCheckInResponse.prototype.setPatientList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.PatientInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.PatientInfo}
 */
proto.appointmentApi.ConferCheckInResponse.prototype.addPatient = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.PatientInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.ConferCheckInResponse} returns this
 */
proto.appointmentApi.ConferCheckInResponse.prototype.clearPatientList = function() {
  return this.setPatientList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PatientInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PatientInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PatientInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    patientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appointmentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    qrcode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cpf: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PatientInfo}
 */
proto.appointmentApi.PatientInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PatientInfo;
  return proto.appointmentApi.PatientInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PatientInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PatientInfo}
 */
proto.appointmentApi.PatientInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQrcode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PatientInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PatientInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PatientInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQrcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCpf();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string patientId = 1;
 * @return {string}
 */
proto.appointmentApi.PatientInfo.prototype.getPatientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientInfo} returns this
 */
proto.appointmentApi.PatientInfo.prototype.setPatientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string appointmentId = 2;
 * @return {string}
 */
proto.appointmentApi.PatientInfo.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientInfo} returns this
 */
proto.appointmentApi.PatientInfo.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string qrCode = 3;
 * @return {string}
 */
proto.appointmentApi.PatientInfo.prototype.getQrcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientInfo} returns this
 */
proto.appointmentApi.PatientInfo.prototype.setQrcode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string picture_url = 4;
 * @return {string}
 */
proto.appointmentApi.PatientInfo.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientInfo} returns this
 */
proto.appointmentApi.PatientInfo.prototype.setPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string firstName = 5;
 * @return {string}
 */
proto.appointmentApi.PatientInfo.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientInfo} returns this
 */
proto.appointmentApi.PatientInfo.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lastName = 6;
 * @return {string}
 */
proto.appointmentApi.PatientInfo.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientInfo} returns this
 */
proto.appointmentApi.PatientInfo.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string cpf = 7;
 * @return {string}
 */
proto.appointmentApi.PatientInfo.prototype.getCpf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientInfo} returns this
 */
proto.appointmentApi.PatientInfo.prototype.setCpf = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ProfessionalCheckInRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ProfessionalCheckInRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ProfessionalCheckInRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ProfessionalCheckInRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    patientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    codeQrcode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ProfessionalCheckInRequest}
 */
proto.appointmentApi.ProfessionalCheckInRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ProfessionalCheckInRequest;
  return proto.appointmentApi.ProfessionalCheckInRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ProfessionalCheckInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ProfessionalCheckInRequest}
 */
proto.appointmentApi.ProfessionalCheckInRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeQrcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ProfessionalCheckInRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ProfessionalCheckInRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ProfessionalCheckInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ProfessionalCheckInRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCodeQrcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string patient_id = 1;
 * @return {string}
 */
proto.appointmentApi.ProfessionalCheckInRequest.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ProfessionalCheckInRequest} returns this
 */
proto.appointmentApi.ProfessionalCheckInRequest.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code_QrCode = 2;
 * @return {string}
 */
proto.appointmentApi.ProfessionalCheckInRequest.prototype.getCodeQrcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ProfessionalCheckInRequest} returns this
 */
proto.appointmentApi.ProfessionalCheckInRequest.prototype.setCodeQrcode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ProfessionalCheckInResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ProfessionalCheckInResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ProfessionalCheckInResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ProfessionalCheckInResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ProfessionalCheckInResponse}
 */
proto.appointmentApi.ProfessionalCheckInResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ProfessionalCheckInResponse;
  return proto.appointmentApi.ProfessionalCheckInResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ProfessionalCheckInResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ProfessionalCheckInResponse}
 */
proto.appointmentApi.ProfessionalCheckInResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ProfessionalCheckInResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ProfessionalCheckInResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ProfessionalCheckInResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ProfessionalCheckInResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.ProfessionalCheckInResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ProfessionalCheckInResponse} returns this
 */
proto.appointmentApi.ProfessionalCheckInResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.ProfessionalCheckInResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ProfessionalCheckInResponse} returns this
 */
proto.appointmentApi.ProfessionalCheckInResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.ProfessionalCheckInResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ProfessionalCheckInResponse} returns this
 */
proto.appointmentApi.ProfessionalCheckInResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.UpdateQueueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.UpdateQueueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.UpdateQueueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.UpdateQueueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    action: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.UpdateQueueRequest}
 */
proto.appointmentApi.UpdateQueueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.UpdateQueueRequest;
  return proto.appointmentApi.UpdateQueueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.UpdateQueueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.UpdateQueueRequest}
 */
proto.appointmentApi.UpdateQueueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.UpdateQueueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.UpdateQueueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.UpdateQueueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.UpdateQueueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.appointmentApi.UpdateQueueRequest.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.UpdateQueueRequest} returns this
 */
proto.appointmentApi.UpdateQueueRequest.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string action = 2;
 * @return {string}
 */
proto.appointmentApi.UpdateQueueRequest.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.UpdateQueueRequest} returns this
 */
proto.appointmentApi.UpdateQueueRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.UpdateQueueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.UpdateQueueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.UpdateQueueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.UpdateQueueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.UpdateQueueResponse}
 */
proto.appointmentApi.UpdateQueueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.UpdateQueueResponse;
  return proto.appointmentApi.UpdateQueueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.UpdateQueueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.UpdateQueueResponse}
 */
proto.appointmentApi.UpdateQueueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.UpdateQueueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.UpdateQueueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.UpdateQueueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.UpdateQueueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.UpdateQueueResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.UpdateQueueResponse} returns this
 */
proto.appointmentApi.UpdateQueueResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.UpdateQueueResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.UpdateQueueResponse} returns this
 */
proto.appointmentApi.UpdateQueueResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.UpdateQueueResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.UpdateQueueResponse} returns this
 */
proto.appointmentApi.UpdateQueueResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.UpdateOrderQueueRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.UpdateOrderQueueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.UpdateOrderQueueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.UpdateOrderQueueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.UpdateOrderQueueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queueList: jspb.Message.toObjectList(msg.getQueueList(),
    proto.appointmentApi.Queue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.UpdateOrderQueueRequest}
 */
proto.appointmentApi.UpdateOrderQueueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.UpdateOrderQueueRequest;
  return proto.appointmentApi.UpdateOrderQueueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.UpdateOrderQueueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.UpdateOrderQueueRequest}
 */
proto.appointmentApi.UpdateOrderQueueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.appointmentApi.Queue;
      reader.readMessage(value,proto.appointmentApi.Queue.deserializeBinaryFromReader);
      msg.addQueue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.UpdateOrderQueueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.UpdateOrderQueueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.UpdateOrderQueueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.UpdateOrderQueueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueueList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.appointmentApi.Queue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Queue queue = 1;
 * @return {!Array<!proto.appointmentApi.Queue>}
 */
proto.appointmentApi.UpdateOrderQueueRequest.prototype.getQueueList = function() {
  return /** @type{!Array<!proto.appointmentApi.Queue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.Queue, 1));
};


/**
 * @param {!Array<!proto.appointmentApi.Queue>} value
 * @return {!proto.appointmentApi.UpdateOrderQueueRequest} returns this
*/
proto.appointmentApi.UpdateOrderQueueRequest.prototype.setQueueList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.appointmentApi.Queue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.Queue}
 */
proto.appointmentApi.UpdateOrderQueueRequest.prototype.addQueue = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.appointmentApi.Queue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.UpdateOrderQueueRequest} returns this
 */
proto.appointmentApi.UpdateOrderQueueRequest.prototype.clearQueueList = function() {
  return this.setQueueList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.Queue.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.Queue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.Queue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.Queue.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.Queue}
 */
proto.appointmentApi.Queue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.Queue;
  return proto.appointmentApi.Queue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.Queue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.Queue}
 */
proto.appointmentApi.Queue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.Queue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.Queue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.Queue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.Queue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.appointmentApi.Queue.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.Queue} returns this
 */
proto.appointmentApi.Queue.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 position = 2;
 * @return {number}
 */
proto.appointmentApi.Queue.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.Queue} returns this
 */
proto.appointmentApi.Queue.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.UpdateOrderQueueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.UpdateOrderQueueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.UpdateOrderQueueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.UpdateOrderQueueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.UpdateOrderQueueResponse}
 */
proto.appointmentApi.UpdateOrderQueueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.UpdateOrderQueueResponse;
  return proto.appointmentApi.UpdateOrderQueueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.UpdateOrderQueueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.UpdateOrderQueueResponse}
 */
proto.appointmentApi.UpdateOrderQueueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.UpdateOrderQueueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.UpdateOrderQueueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.UpdateOrderQueueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.UpdateOrderQueueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.UpdateOrderQueueResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.UpdateOrderQueueResponse} returns this
 */
proto.appointmentApi.UpdateOrderQueueResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.UpdateOrderQueueResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.UpdateOrderQueueResponse} returns this
 */
proto.appointmentApi.UpdateOrderQueueResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.UpdateOrderQueueResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.UpdateOrderQueueResponse} returns this
 */
proto.appointmentApi.UpdateOrderQueueResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.StartPatientCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.StartPatientCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.StartPatientCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.StartPatientCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.StartPatientCareRequest}
 */
proto.appointmentApi.StartPatientCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.StartPatientCareRequest;
  return proto.appointmentApi.StartPatientCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.StartPatientCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.StartPatientCareRequest}
 */
proto.appointmentApi.StartPatientCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.StartPatientCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.StartPatientCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.StartPatientCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.StartPatientCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.appointmentApi.StartPatientCareRequest.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.StartPatientCareRequest} returns this
 */
proto.appointmentApi.StartPatientCareRequest.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.StartPatientCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.StartPatientCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.StartPatientCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.StartPatientCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.StartPatientCareResponse}
 */
proto.appointmentApi.StartPatientCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.StartPatientCareResponse;
  return proto.appointmentApi.StartPatientCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.StartPatientCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.StartPatientCareResponse}
 */
proto.appointmentApi.StartPatientCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.StartPatientCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.StartPatientCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.StartPatientCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.StartPatientCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.StartPatientCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.StartPatientCareResponse} returns this
 */
proto.appointmentApi.StartPatientCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.StartPatientCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.StartPatientCareResponse} returns this
 */
proto.appointmentApi.StartPatientCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.StartPatientCareResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.StartPatientCareResponse} returns this
 */
proto.appointmentApi.StartPatientCareResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.StopPatientCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.StopPatientCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.StopPatientCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.StopPatientCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.StopPatientCareRequest}
 */
proto.appointmentApi.StopPatientCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.StopPatientCareRequest;
  return proto.appointmentApi.StopPatientCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.StopPatientCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.StopPatientCareRequest}
 */
proto.appointmentApi.StopPatientCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.StopPatientCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.StopPatientCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.StopPatientCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.StopPatientCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.appointmentApi.StopPatientCareRequest.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.StopPatientCareRequest} returns this
 */
proto.appointmentApi.StopPatientCareRequest.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.StopPatientCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.StopPatientCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.StopPatientCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.StopPatientCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.StopPatientCareResponse}
 */
proto.appointmentApi.StopPatientCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.StopPatientCareResponse;
  return proto.appointmentApi.StopPatientCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.StopPatientCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.StopPatientCareResponse}
 */
proto.appointmentApi.StopPatientCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.StopPatientCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.StopPatientCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.StopPatientCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.StopPatientCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.StopPatientCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.StopPatientCareResponse} returns this
 */
proto.appointmentApi.StopPatientCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.StopPatientCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.StopPatientCareResponse} returns this
 */
proto.appointmentApi.StopPatientCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.StopPatientCareResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.StopPatientCareResponse} returns this
 */
proto.appointmentApi.StopPatientCareResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.VerifyQueueResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.VerifyQueueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.VerifyQueueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.VerifyQueueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyQueueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.appointmentApi.DateListQueuePreviuos.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.VerifyQueueResponse}
 */
proto.appointmentApi.VerifyQueueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.VerifyQueueResponse;
  return proto.appointmentApi.VerifyQueueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.VerifyQueueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.VerifyQueueResponse}
 */
proto.appointmentApi.VerifyQueueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.DateListQueuePreviuos;
      reader.readMessage(value,proto.appointmentApi.DateListQueuePreviuos.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.VerifyQueueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.VerifyQueueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.VerifyQueueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.VerifyQueueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.DateListQueuePreviuos.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.VerifyQueueResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.VerifyQueueResponse} returns this
 */
proto.appointmentApi.VerifyQueueResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.VerifyQueueResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyQueueResponse} returns this
 */
proto.appointmentApi.VerifyQueueResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.VerifyQueueResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.VerifyQueueResponse} returns this
 */
proto.appointmentApi.VerifyQueueResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DateListQueuePreviuos dates = 4;
 * @return {!Array<!proto.appointmentApi.DateListQueuePreviuos>}
 */
proto.appointmentApi.VerifyQueueResponse.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.appointmentApi.DateListQueuePreviuos>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.DateListQueuePreviuos, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.DateListQueuePreviuos>} value
 * @return {!proto.appointmentApi.VerifyQueueResponse} returns this
*/
proto.appointmentApi.VerifyQueueResponse.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.DateListQueuePreviuos=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.DateListQueuePreviuos}
 */
proto.appointmentApi.VerifyQueueResponse.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.DateListQueuePreviuos, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.VerifyQueueResponse} returns this
 */
proto.appointmentApi.VerifyQueueResponse.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.DateListQueuePreviuos.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.DateListQueuePreviuos.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.DateListQueuePreviuos.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.DateListQueuePreviuos} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.DateListQueuePreviuos.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    appointmentsList: jspb.Message.toObjectList(msg.getAppointmentsList(),
    proto.appointmentApi.AppointmentList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.DateListQueuePreviuos}
 */
proto.appointmentApi.DateListQueuePreviuos.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.DateListQueuePreviuos;
  return proto.appointmentApi.DateListQueuePreviuos.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.DateListQueuePreviuos} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.DateListQueuePreviuos}
 */
proto.appointmentApi.DateListQueuePreviuos.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.appointmentApi.AppointmentList;
      reader.readMessage(value,proto.appointmentApi.AppointmentList.deserializeBinaryFromReader);
      msg.addAppointments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.DateListQueuePreviuos.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.DateListQueuePreviuos.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.DateListQueuePreviuos} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.DateListQueuePreviuos.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAppointmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.appointmentApi.AppointmentList.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.appointmentApi.DateListQueuePreviuos.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.DateListQueuePreviuos} returns this
 */
proto.appointmentApi.DateListQueuePreviuos.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.appointmentApi.DateListQueuePreviuos.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.DateListQueuePreviuos} returns this
 */
proto.appointmentApi.DateListQueuePreviuos.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated AppointmentList appointments = 3;
 * @return {!Array<!proto.appointmentApi.AppointmentList>}
 */
proto.appointmentApi.DateListQueuePreviuos.prototype.getAppointmentsList = function() {
  return /** @type{!Array<!proto.appointmentApi.AppointmentList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.AppointmentList, 3));
};


/**
 * @param {!Array<!proto.appointmentApi.AppointmentList>} value
 * @return {!proto.appointmentApi.DateListQueuePreviuos} returns this
*/
proto.appointmentApi.DateListQueuePreviuos.prototype.setAppointmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.appointmentApi.AppointmentList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.AppointmentList}
 */
proto.appointmentApi.DateListQueuePreviuos.prototype.addAppointments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.appointmentApi.AppointmentList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.DateListQueuePreviuos} returns this
 */
proto.appointmentApi.DateListQueuePreviuos.prototype.clearAppointmentsList = function() {
  return this.setAppointmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListPatientsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListPatientsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListPatientsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListPatientsRequest}
 */
proto.appointmentApi.ListPatientsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListPatientsRequest;
  return proto.appointmentApi.ListPatientsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListPatientsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListPatientsRequest}
 */
proto.appointmentApi.ListPatientsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListPatientsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListPatientsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListPatientsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.ListPatientsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.ListPatientsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.ListPatientsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.ListPatientsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientsList: jspb.Message.toObjectList(msg.getPatientsList(),
    proto.appointmentApi.PatientProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.ListPatientsResponse}
 */
proto.appointmentApi.ListPatientsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.ListPatientsResponse;
  return proto.appointmentApi.ListPatientsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.ListPatientsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.ListPatientsResponse}
 */
proto.appointmentApi.ListPatientsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.PatientProto;
      reader.readMessage(value,proto.appointmentApi.PatientProto.deserializeBinaryFromReader);
      msg.addPatients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.ListPatientsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.ListPatientsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.ListPatientsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.ListPatientsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.PatientProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.ListPatientsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.ListPatientsResponse} returns this
 */
proto.appointmentApi.ListPatientsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.ListPatientsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientsResponse} returns this
 */
proto.appointmentApi.ListPatientsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.ListPatientsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.ListPatientsResponse} returns this
 */
proto.appointmentApi.ListPatientsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated PatientProto patients = 4;
 * @return {!Array<!proto.appointmentApi.PatientProto>}
 */
proto.appointmentApi.ListPatientsResponse.prototype.getPatientsList = function() {
  return /** @type{!Array<!proto.appointmentApi.PatientProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.PatientProto, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.PatientProto>} value
 * @return {!proto.appointmentApi.ListPatientsResponse} returns this
*/
proto.appointmentApi.ListPatientsResponse.prototype.setPatientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.PatientProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.PatientProto}
 */
proto.appointmentApi.ListPatientsResponse.prototype.addPatients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.PatientProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.ListPatientsResponse} returns this
 */
proto.appointmentApi.ListPatientsResponse.prototype.clearPatientsList = function() {
  return this.setPatientsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.PatientProto.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.PatientProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.PatientProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    patientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pictureurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    appointment: (f = msg.getAppointment()) && proto.appointmentApi.AppointmentPatientProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.PatientProto}
 */
proto.appointmentApi.PatientProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.PatientProto;
  return proto.appointmentApi.PatientProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.PatientProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.PatientProto}
 */
proto.appointmentApi.PatientProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureurl(value);
      break;
    case 5:
      var value = new proto.appointmentApi.AppointmentPatientProto;
      reader.readMessage(value,proto.appointmentApi.AppointmentPatientProto.deserializeBinaryFromReader);
      msg.setAppointment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.PatientProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.PatientProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.PatientProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.PatientProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPictureurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAppointment();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.appointmentApi.AppointmentPatientProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string patientId = 1;
 * @return {string}
 */
proto.appointmentApi.PatientProto.prototype.getPatientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientProto} returns this
 */
proto.appointmentApi.PatientProto.prototype.setPatientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.appointmentApi.PatientProto.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientProto} returns this
 */
proto.appointmentApi.PatientProto.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.appointmentApi.PatientProto.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientProto} returns this
 */
proto.appointmentApi.PatientProto.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string pictureUrl = 4;
 * @return {string}
 */
proto.appointmentApi.PatientProto.prototype.getPictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.PatientProto} returns this
 */
proto.appointmentApi.PatientProto.prototype.setPictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AppointmentPatientProto appointment = 5;
 * @return {?proto.appointmentApi.AppointmentPatientProto}
 */
proto.appointmentApi.PatientProto.prototype.getAppointment = function() {
  return /** @type{?proto.appointmentApi.AppointmentPatientProto} */ (
    jspb.Message.getWrapperField(this, proto.appointmentApi.AppointmentPatientProto, 5));
};


/**
 * @param {?proto.appointmentApi.AppointmentPatientProto|undefined} value
 * @return {!proto.appointmentApi.PatientProto} returns this
*/
proto.appointmentApi.PatientProto.prototype.setAppointment = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointmentApi.PatientProto} returns this
 */
proto.appointmentApi.PatientProto.prototype.clearAppointment = function() {
  return this.setAppointment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointmentApi.PatientProto.prototype.hasAppointment = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.AppointmentPatientProto.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.AppointmentPatientProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.AppointmentPatientProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AppointmentPatientProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appointmentstatusid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    appointmentstatus: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.AppointmentPatientProto}
 */
proto.appointmentApi.AppointmentPatientProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.AppointmentPatientProto;
  return proto.appointmentApi.AppointmentPatientProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.AppointmentPatientProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.AppointmentPatientProto}
 */
proto.appointmentApi.AppointmentPatientProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAppointmentstatusid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.AppointmentPatientProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.AppointmentPatientProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.AppointmentPatientProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.AppointmentPatientProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppointmentstatusid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAppointmentstatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.appointmentApi.AppointmentPatientProto.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentPatientProto} returns this
 */
proto.appointmentApi.AppointmentPatientProto.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 appointmentStatusId = 2;
 * @return {number}
 */
proto.appointmentApi.AppointmentPatientProto.prototype.getAppointmentstatusid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointmentApi.AppointmentPatientProto} returns this
 */
proto.appointmentApi.AppointmentPatientProto.prototype.setAppointmentstatusid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string appointmentStatus = 3;
 * @return {string}
 */
proto.appointmentApi.AppointmentPatientProto.prototype.getAppointmentstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.AppointmentPatientProto} returns this
 */
proto.appointmentApi.AppointmentPatientProto.prototype.setAppointmentstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.SearchListPatientsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.SearchListPatientsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.SearchListPatientsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchListPatientsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.SearchListPatientsRequest}
 */
proto.appointmentApi.SearchListPatientsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.SearchListPatientsRequest;
  return proto.appointmentApi.SearchListPatientsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.SearchListPatientsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.SearchListPatientsRequest}
 */
proto.appointmentApi.SearchListPatientsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.SearchListPatientsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.SearchListPatientsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.SearchListPatientsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchListPatientsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.appointmentApi.SearchListPatientsRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListPatientsRequest} returns this
 */
proto.appointmentApi.SearchListPatientsRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointmentApi.SearchListPatientsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointmentApi.SearchListPatientsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointmentApi.SearchListPatientsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointmentApi.SearchListPatientsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchListPatientsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patientsList: jspb.Message.toObjectList(msg.getPatientsList(),
    proto.appointmentApi.PatientProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointmentApi.SearchListPatientsResponse}
 */
proto.appointmentApi.SearchListPatientsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointmentApi.SearchListPatientsResponse;
  return proto.appointmentApi.SearchListPatientsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointmentApi.SearchListPatientsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointmentApi.SearchListPatientsResponse}
 */
proto.appointmentApi.SearchListPatientsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.appointmentApi.PatientProto;
      reader.readMessage(value,proto.appointmentApi.PatientProto.deserializeBinaryFromReader);
      msg.addPatients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointmentApi.SearchListPatientsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointmentApi.SearchListPatientsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointmentApi.SearchListPatientsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointmentApi.SearchListPatientsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.appointmentApi.PatientProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.appointmentApi.SearchListPatientsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointmentApi.SearchListPatientsResponse} returns this
 */
proto.appointmentApi.SearchListPatientsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.appointmentApi.SearchListPatientsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListPatientsResponse} returns this
 */
proto.appointmentApi.SearchListPatientsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.appointmentApi.SearchListPatientsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointmentApi.SearchListPatientsResponse} returns this
 */
proto.appointmentApi.SearchListPatientsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated PatientProto patients = 4;
 * @return {!Array<!proto.appointmentApi.PatientProto>}
 */
proto.appointmentApi.SearchListPatientsResponse.prototype.getPatientsList = function() {
  return /** @type{!Array<!proto.appointmentApi.PatientProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointmentApi.PatientProto, 4));
};


/**
 * @param {!Array<!proto.appointmentApi.PatientProto>} value
 * @return {!proto.appointmentApi.SearchListPatientsResponse} returns this
*/
proto.appointmentApi.SearchListPatientsResponse.prototype.setPatientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.appointmentApi.PatientProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointmentApi.PatientProto}
 */
proto.appointmentApi.SearchListPatientsResponse.prototype.addPatients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.appointmentApi.PatientProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointmentApi.SearchListPatientsResponse} returns this
 */
proto.appointmentApi.SearchListPatientsResponse.prototype.clearPatientsList = function() {
  return this.setPatientsList([]);
};


goog.object.extend(exports, proto.appointmentApi);
