import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import validationLogin from './validationsLogin';
import validationsRegister from './validationsRegister';
// import useStore from '../store';

import PublicLayout from '../modules/app/views/PublicLayout.vue';
import AppLayout from '../modules/app/views/AppLayout.vue';


// import Account from '../modules/account/views/Account.vue';
import Profile from '../modules/profile/views/Profile.vue';

const isAuthenticated = (to, from, next) => {
  const token = window.localStorage.token;
  if(token) {
    next()
    return
  } else {
    next({ name: 'Account' })
  }
}
// const isContinued = async (to, from, next) => {
//   const store = useStore();
//   const response = await store.continue();
// }

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    beforeEnter: validationLogin.AccountMain,
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'Dashboard',
        beforeEnter: isAuthenticated,
        component: () => import('../modules/dashboard/Dashboard.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/commitments/futures',
        name: 'futures',
        component: () => import('../modules/commitments/Futures.vue'),
        beforeEnter: isAuthenticated,
        meta: {
          auth: true
        },
      },
      {
        path: '/commitments/previous',
        name: 'previous',
        component: () => import('../modules/commitments/Previous.vue'),
        beforeEnter: isAuthenticated,
        meta: {
          auth: true
        },
      },
      {
        path: '/my-health',
        name: 'MyHealth',
        component: () => import('../modules/myhealth/MyHealth.vue'),
        beforeEnter: isAuthenticated,
        meta: {
          auth: true
        },
      },
      {
        path: '/favorites',
        name: 'Favorites',
        component: () => import('../modules/favorites/Favorites.vue'),
        beforeEnter: isAuthenticated,
        meta: {
          auth: true
        },
      },
      {
        path: '/wallet',
        name: 'Wallet',
        component: () => import('../modules/wallet/Wallet.vue'),
        beforeEnter: isAuthenticated,
        meta: {
          auth: true
        },
      },
      {
        path: '/check-in',
        name: 'CheckIn',
        component: () => import('../modules/check-in/CheckIn.vue'),
        beforeEnter: isAuthenticated,
        meta: {
          auth: true
        },
      },
      {
        path: '/connections/dependents',
        name: 'Dependents',
        component: () => import('../modules/Connections/Dependents/Dependents.vue'),
        beforeEnter: isAuthenticated,
        meta: {
          auth: true
        },
      },
      {
        path: '/connections/linked',
        name: 'Linked',
        component: () => import('../modules/Connections/Linked/Linked.vue'),
        beforeEnter: isAuthenticated,
        meta: {
          auth: true
        },
      },
      {
        path: '/connections/permissions',
        name: 'Permissions',
        component: () => import('../modules/Connections/Permissions/Permissions.vue'),
        beforeEnter: isAuthenticated,
        meta: {
          auth: true
        },
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        beforeEnter: isAuthenticated,
        meta: {
          auth: true
        },
        children: [
          {
            path: 'info',
            name: 'Profile.Info',
            beforeEnter: isAuthenticated,
            component: () => import('../modules/profile/views/Info.vue')
          },
        ],
        redirect: {
          name: 'Profile.Info'
        }
      },
      {
        path: '/team',
        component: () => import('../modules/team/index.vue'),
        beforeEnter: isAuthenticated,
        meta: {
          auth: true
        },
      },
      {
          path: '/team/member',
          component: () => import('../modules/team/member.vue'),
          beforeEnter: isAuthenticated,
      },
      {
          path: '/settings',
          component: () => import('../modules/settings/index.vue'),
          beforeEnter: isAuthenticated,
          meta: {
            auth: true
          },
      },
      {
          path: '/help',
          component: () => import('../modules/help/index.vue'),
          beforeEnter: isAuthenticated,
          meta: {
            auth: true
          },
      },
      {
          path: '/my-profile',
          component: () => import('../modules/myprofile/index.vue'),
          beforeEnter: isAuthenticated,
          meta: {
            auth: true
          },
      },
      {
        path: '/search/:firstoption?&:secondoption?',
        name: 'SearchAppointment',
        beforeEnter: isAuthenticated,
        // component: PublicLayout
        // component: () => import('../modules/account/views/Continue.vue')
        component: () => import('../modules/app/views/SearchAppointment/ListingSearchProfessionals.vue')
      },
      {
        path: '/profile/:professionalName?&:professionalId?&:closestPlaceId?',
        name: 'ProfessionalProfile',
        beforeEnter: isAuthenticated,
        // component: PublicLayout
        // component: () => import('../modules/account/views/Continue.vue')
        component: () => import('../modules/myprofile/ProfessionalProfile.vue')
      },
      // {
      //   path: '/checkout/',
      //   name: 'CheckoutAppointment',
      //   beforeEnter: isAuthenticated,
      //   // component: PublicLayout
      //   // component: () => import('../modules/account/views/Continue.vue')
      //   component: () => import('../modules/app/views/Checkout/CheckoutAppointment.vue')
      // },
      {
        path: '/checkout/:selectedServiceTypeId?&:localId?&:localName?&:localAddress?&:localNeighborhood?&:localCity?&:localState?&:serviceName?&:serviceId?&:serviceDescription?&:modalityName?&:date?&:hour?&:selectedServiceTypeValue',
        name: 'CheckoutAppointment',
        beforeEnter: isAuthenticated,
        // component: PublicLayout
        // component: () => import('../modules/account/views/Continue.vue')
        component: () => import('../modules/app/views/Checkout/CheckoutAppointment.vue')
      },
    ]
  },
  {
    path: '/appointment/:appointmentid&:patientid&:email',
    name: 'ConfirmAppointment',
    // component: PublicLayout
    // component: () => import('../modules/account/views/Continue.vue')
    component: () => import('../modules/commitments/ConfirmAppointment/ConfirmAppointment.vue')
  },
  {
    path: '/register/continue/:email&:token',
    name: 'Continue',
    // component: PublicLayout
    // component: () => import('../modules/account/views/Continue.vue')
    component: () => import('../modules/account/views/RegisterContinue/RegisterContinue.vue')
  },
  {
    path: '/pdf-view',
    name: 'PdfView',
    component: () => import('../modules/account/views/pdfView.vue')
  },

  {
    path: '/account',
    name: 'Account',
    component: PublicLayout,
    children: [
      {
        path: 'login',
        name: 'Account.Login',
        beforeEnter: validationLogin.AccountLogin,
        component: () => import('../modules/account/views/Login/Login.vue')
      },
      {
        path: 'register',
        name: 'Account.Register',
        beforeEnter: validationsRegister.AccountRegister,
        component: () => import('../modules/account/views/Register/Register.vue')
      },
      {
        path: '/account/email/confirmation/:email&:token',
        name: 'Account.EmailConfirmation',
        // beforeEnter: isAuthenticated,
        component: () => import('../modules/account/views/EmailConfirmation.vue')
      },
      {
        path: '/account/email/confirmation',
        name: 'Account.EmailConfirm',
        beforeEnter: validationLogin.AccountEmailConfirm,
        // component: () => import('../modules/account/views/EmailConfirm/EmailConfirm-old.vue')
        component: () => import('../modules/account/views/EmailConfirm/EmailConfirm.vue')
      },
      {
        path: '/account/phone/confirmation',
        name: 'Account.PhoneConfirmation',
        beforeEnter: validationsRegister.AccountPhoneConfirmation,
        component: () => import('../modules/account/views/PhoneConfirmation/PhoneConfirmation.vue')
      },
      {
        path: '/account/pending/confirmation',
        name: 'AccountPendingConfirmation',
        beforeEnter: validationLogin.AccountEmailConfirm,
        component: () => import('../modules/account/views/PendingConfirmation/PendingConfirmation.vue')
      },
      {
        path: 'recover',
        name: 'Account.Recover',
        component: () => import('../modules/account/views/Recover/Recover.vue')
      },
      {
        path: '/account/recover/searchAccount',
        name: 'Account.SearchAccount',
        component: () => import('../modules/account/views/SearchAccount/SearchAccount.vue')
      },
      {
        path: '/account/recover/change_password',
        name: 'Account.ChangePassword',
        component: () => import('../modules/account/views/ChangePassword/ChangePassword.vue')
      },
      {
        path: 'infos',
        name: 'Account.Infos',
        beforeEnter: validationsRegister.AccountInfos,
        component: () => import('../modules/account/views/Infos/Infos.vue')
      },
      {
        path: 'address',
        name: 'Account.Address',
        beforeEnter: validationsRegister.AccountAddress,
        component: () => import('../modules/account/views/Address/Address.vue')
      },
      {
        path: 'records',
        name: 'Account.Records',
        // beforeEnter: isAuthenticated,
        component: () => import('../modules/account/views/Records.vue')
      },
      {
        path: 'upload',
        name: 'Account.UploadDocument',
        beforeEnter: validationsRegister.AccountUpload,
        component: () => import('../modules/account/views/UploadDocument/UploadDocument.vue')
      },
      {
        path: 'success',
        name: 'Account.Success',
        // beforeEnter: isAuthenticated,
        component: () => import('../modules/account/views/Success.vue')
      },
    ],
    redirect: {
      name: 'Account.Login'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// router.beforeEach((to, from, next) => {
//    console.log(to, from);
//    next();
// });

export default router
