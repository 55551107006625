<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 25" fill="none">
        <path d="M13.5832 21.05C15.3867 18.793 19.5 13.3227 19.5 10.25C19.5 6.52344 16.4766 3.5 12.75 3.5C9.02344 3.5 6 6.52344 6 10.25C6 13.3227 10.1133 18.793 11.9168 21.05C12.3492 21.5879 13.1508 21.5879 13.5832 21.05ZM12.75 12.5C11.509 12.5 10.5 11.491 10.5 10.25C10.5 9.00898 11.509 8 12.75 8C13.991 8 15 9.00898 15 10.25C15 11.491 13.991 12.5 12.75 12.5Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>