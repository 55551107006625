<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path d="M10.7137 2.1665C9.06553 2.1665 7.45437 2.65525 6.08396 3.57092C4.71355 4.4866 3.64544 5.78809 3.01471 7.31081C2.38398 8.83353 2.21895 10.5091 2.5405 12.1256C2.86204 13.7421 3.65571 15.227 4.82115 16.3924C5.98659 17.5578 7.47145 18.3515 9.08796 18.673C10.7045 18.9946 12.38 18.8296 13.9027 18.1988C15.4255 17.5681 16.7269 16.5 17.6426 15.1296C18.5583 13.7592 19.047 12.148 19.047 10.4998C19.047 9.40549 18.8315 8.32186 18.4127 7.31081C17.9939 6.29976 17.3801 5.3811 16.6063 4.60728C15.8324 3.83346 14.9138 3.21963 13.9027 2.80084C12.8917 2.38205 11.8081 2.1665 10.7137 2.1665ZM10.7137 17.1665C9.39517 17.1665 8.10623 16.7755 7.00991 16.043C5.91358 15.3104 5.0591 14.2692 4.55451 13.0511C4.04993 11.8329 3.9179 10.4924 4.17514 9.19924C4.43237 7.90603 5.06731 6.71814 5.99966 5.78579C6.93201 4.85344 8.1199 4.2185 9.41311 3.96127C10.7063 3.70403 12.0468 3.83606 13.2649 4.34064C14.4831 4.84522 15.5243 5.69971 16.2568 6.79604C16.9894 7.89236 17.3804 9.1813 17.3804 10.4998C17.3804 12.2679 16.678 13.9636 15.4278 15.2139C14.1775 16.4641 12.4818 17.1665 10.7137 17.1665ZM12.4637 8.52484L11.547 9.05817V6.33317C11.547 6.11216 11.4592 5.9002 11.303 5.74392C11.1467 5.58764 10.9347 5.49984 10.7137 5.49984C10.4927 5.49984 10.2807 5.58764 10.1245 5.74392C9.96817 5.9002 9.88037 6.11216 9.88037 6.33317V10.4998C9.88037 10.4998 9.88037 10.4998 9.88037 10.5498C9.87646 10.6053 9.87646 10.661 9.88037 10.7165C9.89328 10.7677 9.90999 10.8178 9.93038 10.8665C9.93038 10.8665 9.93038 10.8665 9.93038 10.9165C9.97018 10.9756 10.0148 11.0314 10.0637 11.0832C10.131 11.1499 10.2101 11.2036 10.297 11.2415H10.3637C10.4723 11.2973 10.5917 11.3286 10.7137 11.3332C10.8108 11.3304 10.9067 11.3107 10.997 11.2748H11.072H11.1304L13.297 10.0248C13.4893 9.91433 13.6298 9.73197 13.6877 9.51786C13.7455 9.30376 13.7159 9.07545 13.6054 8.88317C13.4949 8.69089 13.3125 8.55038 13.0984 8.49256C12.8843 8.43473 12.656 8.46433 12.4637 8.57484V8.52484Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if(props.fill){
                return props.fill
            }
            
            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>