<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M18.0499 9.04143L10.5499 2.37476C10.3978 2.24116 10.2023 2.16748 9.9999 2.16748C9.79747 2.16748 9.60197 2.24116 9.4499 2.37476L1.9499 9.04143C1.82243 9.15419 1.73254 9.30327 1.69231 9.46864C1.65209 9.634 1.66346 9.80771 1.7249 9.96643C1.78533 10.123 1.89165 10.2578 2.02994 10.3529C2.16822 10.4481 2.33203 10.4993 2.4999 10.4998H3.33323V17.9998C3.33323 18.2208 3.42103 18.4327 3.57731 18.589C3.73359 18.7453 3.94555 18.8331 4.16656 18.8331H15.8332C16.0542 18.8331 16.2662 18.7453 16.4225 18.589C16.5788 18.4327 16.6666 18.2208 16.6666 17.9998V10.4998H17.4999C17.6678 10.4993 17.8316 10.4481 17.9699 10.3529C18.1081 10.2578 18.2145 10.123 18.2749 9.96643C18.3363 9.80771 18.3477 9.634 18.3075 9.46864C18.2673 9.30327 18.1774 9.15419 18.0499 9.04143ZM10.8332 17.1664H9.16656V14.6664C9.16656 14.4454 9.25436 14.2334 9.41064 14.0772C9.56692 13.9209 9.77888 13.8331 9.9999 13.8331C10.2209 13.8331 10.4329 13.9209 10.5892 14.0772C10.7454 14.2334 10.8332 14.4454 10.8332 14.6664V17.1664ZM14.9999 17.1664H12.4999V14.6664C12.4999 14.0034 12.2365 13.3675 11.7677 12.8987C11.2988 12.4298 10.6629 12.1664 9.9999 12.1664C9.33685 12.1664 8.70097 12.4298 8.23213 12.8987C7.76329 13.3675 7.4999 14.0034 7.4999 14.6664V17.1664H4.9999V10.4998H14.9999V17.1664ZM4.69156 8.83309L9.9999 4.11643L15.3082 8.83309H4.69156Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>