import patientAccountPb from "../modules/core/grpc/generated/patientProfile_pb";
import patientProfileService from "../modules/core/services/patientProfileService"

export const GetInfo = async () => {
    try {
        const req = new patientAccountPb.EmptyRequest();

        const res = await patientProfileService.getInfo(req);
        console.log(res);        

        return res;
    } catch (error) {
        return error;
    }
}